export interface ExerciseAction {
    type: string;
    exercise:boolean;
}

export const SET_EXERCISE_STATUS = "EXERCISE/SET";

export const SetExercise = (
    exercise:boolean
) => {
    return ({
        type: SET_EXERCISE_STATUS,
        exercise:exercise
    })
};