import { ModalNS as NS } from "CommonNamespace";
import { ModalAction, SET_MODAL, DEL_MODAL, ATTENDANCE_MODAL } from "../action";

const initalState: NS.ModalState = {
    modals: [],
    showAttendance: false,
};

const modal = (state = initalState, action: ModalAction) => {
    switch (action.type) {
        case SET_MODAL:
            if (state.modals.find(raw => raw.key === action.msg) === undefined) {
                state.modals.push({
                    msg: action.msg ? action.msg : "",
                    position: action.pos ? action.pos : "top",
                    key: action.key ? action.key : Date.now().toString(),
                    duration: action.duration !== undefined ? action.duration : 6,
                    isAlert: action.isAlert ? true : false,
                    textRed: action.textRed ? true : false,
                });
            }
            return {
                ...state,
                modals: state.modals,
            };
        case DEL_MODAL:
            let fIdx = state.modals.findIndex(raw => raw.key === action.key);
            if (fIdx !== -1) {
                state.modals.splice(fIdx, 1);
            }
            return {
                ...state,
                modals: [...state.modals],
            };
        case ATTENDANCE_MODAL:
            return {
                ...state,
                showAttendance: action.show ? true : false,
            };
        default:
            return state;
    }
};

export default modal;
