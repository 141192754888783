import { ShoesType, RevealBoxType } from "@/dataset";
import { FilterType } from "@/types";

export const SortInven = (sort: string, list: (ShoesType | RevealBoxType)[]) => {
    let result: any[] = list;
    switch (sort) {
        case FilterType.NEW:
            result = list.sort((a, b) => b.id - a.id);
            break;
        case FilterType.BATTERY:
            result = list.sort((a, b) => ("battery" in a && "battery" in b ? b.battery - a.battery : 0));
            break;
        case FilterType.RARITY:
            result = list.sort((a, b) => ("rarityId" in a && "rarityId" in b ? b.rarityId - a.rarityId : 0));
            break;
        case FilterType.GRADE:
            result = list.sort((a, b) => ("grade" in a && "grade" in b ? b.grade - a.grade : 0));
            break;
        case FilterType.NAME:
            result = list.sort((a, b) => ("hash" in a && "hash" in b ? parseInt(b.hash) - parseInt(a.hash) : 0));
            break;
        case FilterType.SHAREPERCENTAGE:
            result = list.sort((a, b) =>
                "meta_profitMiner" in a && "meta_profitMiner" in b ? b.meta_profitMiner - a.meta_profitMiner : 0
            );
            break;
    }

    return result;
};
