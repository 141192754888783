import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function useQueryString() {
    const location = useLocation();

    const search = (searchText: string) => {
        const data = new URLSearchParams(location.search).get(searchText);
        if (data) {
            return data;
        }
    };

    return search;
}

export default useQueryString;
