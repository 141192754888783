import React from "react";
import styled from "styled-components";
import { useCallback } from "react";
import gsap, { Cubic } from "gsap";
//
import { zIndex } from "@/utils/css";

const Wrap = styled.div`
    position: fixed;
    bottom: 90px;
    right: 10px;
    filter: drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.4));
    z-index: ${zIndex.layout};
`;

const TopBtn = () => {
    const onClick = useCallback(() => {
        const obj = { wY: window.scrollY };
        gsap.to(obj, {
            duration: 0.35,
            wY: 0,
            ease: Cubic.easeInOut,
            onUpdate: () => {
                window.scrollTo(0, obj.wY);
            },
        });
    }, []);

    return (
        <Wrap>
            <button onClick={onClick}>
                <img src="/img/common/ic_button_top.svg" alt="" />
            </button>
        </Wrap>
    );
};

export default React.memo(TopBtn);
