/* eslint-disable */
//auto gen
import {
    BuyEnergyDrinkResponse,
    StartEvt,
    BuyRevealBoxResponse,
    GetShopInfoResponse,
    ShopItem,
    BuyShopItemResponse,
    GetItemMetaResponse,
    OpenItemBoxResponse,
    ItemProbability,
} from "../dataset/"
import { client, SockClientOpt, OnRecvData } from "./index";
import { useEffect, useState, useLayoutEffect } from "react";

const hash = require('object-hash');
type StoreType = {
  [_: string]: BuyShopItemResponse
}
type StoreFunc = (data: BuyShopItemResponse) => void

let store: StoreType = {};
let subscribeEvent: { [_: string]: StoreFunc[] } = {}
export const request = function(token:string ,shopId:number ,count:number ,opt?:SockClientOpt) : Promise<BuyShopItemResponse>{
  return new Promise((resolve, reject) => {
    client.emit("item:BuyShopItem", token,shopId,count, async function ack(data:any){
      resolve(await OnRecvData(data));

      let key = opt?.useKey ? hash([opt.useKey]) : hash([token,shopId,count]);
      if(opt?.useStore){
        store[key] = data;
      }

      if (subscribeEvent[key]) {
        for (let evt of subscribeEvent[key]) {
          evt(data);
        }
      }
    });
  })
}


function subscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  subscribeEvent[key].push(func)
}
function unsubscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  let idx = subscribeEvent[key].findIndex(e => e == func);
  if (idx >= 0) subscribeEvent[key].splice(idx, 1)
}
setTimeout(()=>{
  client.on("item:BuyShopItem", (data)=>{
    for(let key of Object.keys(subscribeEvent)){
      for(let evt of subscribeEvent[key]){
        evt(data);
      }
    }
  });
}, 100);

export default function (token:string ,shopId:number ,count:number ,opt?:SockClientOpt) : [BuyShopItemResponse | null, ()=>Promise<BuyShopItemResponse>] {
  
  let key = opt?.useKey ? hash([opt.useKey]) : hash([token,shopId,count]);
  const [Data, setData] = useState<BuyShopItemResponse | null>(opt?.useStore ? (store[key]) : null);

  useLayoutEffect(() => {
    const handleChange = (data: BuyShopItemResponse)=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[token]);

  useEffect(() => {
    const handleChange = (data: BuyShopItemResponse)=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[token]);

  return [Data, () => request(token,shopId,count,opt)]

}
