/* eslint-disable */
//auto gen
import {
    RentalListResponse,
    SelectShoesResponse,
    GetSelectedShoesResponse,
    ReplaceBatteryResponse,
    RentShoesReponse,
    RentalHistoryResponse,
    UpgradeShoesResponse,
    GetUpgradeInfoResponse,
    GetUpgradeHistoryResponse,
    SynthesisResponse,
    GetSynthesisHistoryResponse,
    GetSynthesisResponse,
    GetRentalShoesByIdResponse,
} from "../dataset/"
import { client, SockClientOpt, OnRecvData } from "./index";
import { useEffect, useState, useLayoutEffect } from "react";

const hash = require('object-hash');
type StoreType = {
  [_: string]: RentShoesReponse
}
type StoreFunc = (data: RentShoesReponse) => void

let store: StoreType = {};
let subscribeEvent: { [_: string]: StoreFunc[] } = {}
export const request = function(token:string ,shoesId:number ,rentalType:number ,opt?:SockClientOpt) : Promise<RentShoesReponse>{
  return new Promise((resolve, reject) => {
    client.emit("shoes:RentShoes", token,shoesId,rentalType, async function ack(data:any){
      resolve(await OnRecvData(data));

      let key = opt?.useKey ? hash([opt.useKey]) : hash([token,shoesId,rentalType]);
      if(opt?.useStore){
        store[key] = data;
      }

      if (subscribeEvent[key]) {
        for (let evt of subscribeEvent[key]) {
          evt(data);
        }
      }
    });
  })
}


function subscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  subscribeEvent[key].push(func)
}
function unsubscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  let idx = subscribeEvent[key].findIndex(e => e == func);
  if (idx >= 0) subscribeEvent[key].splice(idx, 1)
}
setTimeout(()=>{
  client.on("shoes:RentShoes", (data)=>{
    for(let key of Object.keys(subscribeEvent)){
      for(let evt of subscribeEvent[key]){
        evt(data);
      }
    }
  });
}, 100);

export default function (token:string ,shoesId:number ,rentalType:number ,opt?:SockClientOpt) : [RentShoesReponse | null, ()=>Promise<RentShoesReponse>] {
  
  let key = opt?.useKey ? hash([opt.useKey]) : hash([token,shoesId,rentalType]);
  const [Data, setData] = useState<RentShoesReponse | null>(opt?.useStore ? (store[key]) : null);

  useLayoutEffect(() => {
    const handleChange = (data: RentShoesReponse)=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[token]);

  useEffect(() => {
    const handleChange = (data: RentShoesReponse)=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[token]);

  return [Data, () => request(token,shoesId,rentalType,opt)]

}
