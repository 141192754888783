import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
//
import {translate as t} from "@/i18n";
import { UserGenderTypeStr } from "@/dataset";

const Wrap = styled.div`
    margin-bottom: 40px;
    ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        li {
            width: 50%;
            input:checked + label {
                dl {
                    dt {
                        img:last-child {
                            opacity: 1;
                        }
                    }
                }
            }

            input {
                display: none;
            }

            dl {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                dt {
                    position: relative;
                    margin-right: 16px;
                    img {
                        &:last-child {
                            position: absolute;
                            left: 0;
                            top: 0;
                            opacity: 0;
                        }
                    }
                }

                dd {
                    ${p => p.theme.typo.button2};
                    color: ${p => p.theme.color.textPrimary};
                }
            }
        }
    }
`;

interface Props {
    value: string;
    onChange: (e: number) => void;
}

const EditorSex = ({ value, onChange }: Props) => {
    const [sex, setSex] = useState<string>(value);
    const change = useCallback($e => {
        setSex($e.target.value);
        if ($e.target.value === "woman") {
            onChange(UserGenderTypeStr.woman);
        } else if ($e.target.value === "man") {
            onChange(UserGenderTypeStr.man);
        }
    }, []);
    return (
        <Wrap>
            <ul>
                <li>
                    <input
                        onChange={change}
                        id={"woman"}
                        value={"woman"}
                        name={"sex"}
                        type="radio"
                        checked={sex === "woman"}
                    />
                    <label htmlFor="woman">
                        <dl>
                            <dt>
                                <img src="/img/common/ic_sex_woman.svg" alt="" />
                                <img src="/img/common/ic_sex_woman_on.svg" alt="" />
                            </dt>
                            <dd>{t("profile:sex:woman")}</dd>
                        </dl>
                    </label>
                </li>
                <li>
                    <input
                        onChange={change}
                        id={"man"}
                        value={"man"}
                        name={"sex"}
                        type="radio"
                        checked={sex === "man"}
                    />
                    <label htmlFor="man">
                        <dl>
                            <dt>
                                <img src="/img/common/ic_sex_man.svg" alt="" />
                                <img src="/img/common/ic_sex_man_on.svg" alt="" />
                            </dt>
                            <dd>{t("profile:sex:man")}</dd>
                        </dl>
                    </label>
                </li>
            </ul>
        </Wrap>
    );
};

export default EditorSex;
