import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";

const Wrap = styled.div<{ hasTheme?: string }>`
    position: sticky;
    z-index: 1000;
    left: 0;
    top: 0;

    ul {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${p => (p.hasTheme == "WHITE" ? "#fff" : p.theme.color.bgDarken)};

        li {
            cursor: pointer;
            width: 100%;
            label {
                background-color: transparent;
                border: none;
                width: 100%;
                height: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                //border-bottom: 1px solid #181818;
                > div {
                    ${p => p.theme.typo.body1};
                    color: ${p => p.theme.color.textTertiary};
                }
                > span {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    background-color: #181818;
                    clip-path: inset(0 50% 0 50%);
                    transition: clip-path 0.2s ease-out;
                }
            }
            input {
                display: none;
            }
        }
    }
`;

const Li = styled.li<{hasTheme?: string}>`
    &.active{
        label{
            > div {
                color: ${p => (p.hasTheme == "WHITE" ? "#000" : p.theme.color.textPrimary)};
            }

            > span {
                height: 2px;
                background-color: ${p => (p.hasTheme == "WHITE" ? "#000" : p.theme.color.textPrimary)};
                clip-path: inset(0% 0% 0% 0%);
            }
        }
    }
`

interface ListType {
    title: string;
    tabType: string;
    count?: number;
}

interface Props {
    list: ListType[];
    onTab: ($marketType: string) => void;
    defaultTab?: number;
    rootStyle?: React.CSSProperties;
    theme?: "BLACK" | "WHITE";
}

const TabMenu = ({ list, onTab, defaultTab, rootStyle, theme }: Props) => {
    const [checkValue, setValue] = useState<string>(list[0].tabType);

    useEffect(() => {
        if (defaultTab !== undefined) {
            setValue(list[defaultTab].tabType);
        }
    }, [defaultTab]);

    useEffect(() => {
        onTab(checkValue);
    }, [checkValue]);

    const change = useCallback(
        $e => {
            setValue($e);
        },
        [checkValue]
    );

    return (
        <Wrap hasTheme={theme} style={rootStyle}>
            <ul>
                {list.map(($item, $index) => (
                    <Li hasTheme={theme} 
                        className={checkValue == $item.tabType ? "active" : ""}
                        key={$item.tabType}
                        onClick={p=>change($item.tabType)}>
                        <label>
                            <div>
                                {$item.title} {$item.count ? $item.count : ""}
                            </div>
                            <span></span>
                        </label>
                    </Li>
                ))}
            </ul>
        </Wrap>
    );
};

export default TabMenu;
