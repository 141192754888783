import React, { useEffect, useState } from "react";

type CallbackFunc = (data: boolean) => void;
let subscribeEvent: Array<CallbackFunc> = [];
let isBackground = false;

window.setBackground = function (flag: boolean) {
    if (isBackground != flag) {
        isBackground = flag;
        for (let evt of subscribeEvent) {
            evt(flag);
        }
    }
};

function subscribe(func: CallbackFunc) {
    subscribeEvent = subscribeEvent || [];
    subscribeEvent.push(func);
}
function unsubscribe(func: CallbackFunc) {
    subscribeEvent = subscribeEvent || [];
    let idx = subscribeEvent.findIndex(e => e == func);
    if (idx >= 0) subscribeEvent.splice(idx, 1);
}

function useEnterBackground() {
    const [Data, setData] = useState<boolean>(false);

    useEffect(() => {
        const handleChange = (flag: boolean) => setData(flag);

        subscribe(handleChange);
        return () => {
            unsubscribe(handleChange);
        };
    }, []);

    return [Data, setData];
}

export default useEnterBackground;
