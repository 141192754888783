import { Moment } from "moment";
import moment from "@/utils/moment";
//
import { getTwoDigits } from "./index";

export const getUTCDate = (time?: any) => {
    return moment(time);
};

export const getDayRange = (day: number): { start: Moment; end: Moment } | undefined => {
    if (day > 0) {
        let now = getUTCDate();
        let to = getUTCDate().add(day, "days");
        return { start: now, end: to };
    }
    return;
};

export const getWeekRange = (week: number): { first: Date; last: Date } => {
    return {
        first: getUTCDate()
            .startOf("week")
            .subtract(7 * week, "days")
            .toDate(),
        last: getUTCDate()
            .endOf("week")
            .subtract(7 * week, "days")
            .toDate(),
    };
};

export const getMonth = (addVal: number): Date => {
    var curr = new Date();
    curr.setMonth(curr.getMonth() + addVal);

    return curr;
};

export const getYear = (addVal: number): number => {
    var curr = new Date().getFullYear();
    return curr + addVal;
};

export const getTimeFormat = (seconds: number): string => {
    if (seconds / 3600 > 1) {
        let hour = Math.floor(seconds / 3600);
        let min = Math.floor((seconds - hour * 3600) / 60);
        let sec = Math.floor((seconds - (hour * 3600 + min * 60)) / 60);
        return getTwoDigits(hour) + ":" + getTwoDigits(min) + ":" + getTwoDigits(sec);
    } else {
        let min = Math.floor(seconds / 60);
        let sec = seconds % 60;
        return getTwoDigits(min) + ":" + getTwoDigits(sec);
    }
};

export const mysqlDateToDate = (date: string) => {
    return new Date(date.replace(/[-]/g, "/").replace("T", " "));
};

export const getFullDateString = (d: string | Date) => {
    return getUTCDate(d).format("YYYY.MM.DD ddd HH:mm");
};

export const getDuration = (d: string) => {
    let due = getUTCDate(d);
    if (due.isValid()) {
        let diff = getUTCDate().diff(due, "days");
        return diff;
    }
    return 0;
};
