import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
//
import { translate as t } from "@/i18n";
import { pxtovw375, zIndex } from "@/utils/css";
import { ShoesType } from "@/dataset/shoes";
import ShoesItem from "@/components/Common/ShoesItem";

export const CloseBtn = styled.div`
    position: absolute;
    top: 0;
    right: -${pxtovw375(10)};
    padding: ${pxtovw375(10)};
    img {
        width: ${pxtovw375(24)};
        height: ${pxtovw375(24)};
    }
`;

export const TitleBx = styled.div`
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    padding: ${pxtovw375(30)} 0;
    z-index: ${zIndex.sticky};
    background: #181818;
`;

export const Title = styled.div`
    font-weight: 600;
    font-size: 22px;
    color: #fff;
`;

export const TitleBxToolWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: ${pxtovw375(20)} 0 0 0;
`;

export const TitleBxCounter = styled.div`
    font-size: ${pxtovw375(12)};
    font-weight: 400;
    line-height: 20px;
`;

export const ItemListWrap = styled.div<{ btmSpacing: boolean }>`
    min-height: ${p => (p.btmSpacing ? "80vh" : "auto")};
`;

export const ItemListContainer = styled.div<{ btmSpacing: boolean }>`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: ${pxtovw375(12)};
    row-gap: ${pxtovw375(20)};

    padding-bottom: ${p => (p.btmSpacing ? pxtovw375(75) : 0)};
`;

export const NoNFTScreen = styled.div`
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const NoNFTTxt = styled.div`
    color: ${p => p.theme.color.textTertiary};
    ${p => p.theme.typoVW.body7};
    padding-bottom: ${pxtovw375(24)};
`;

interface ShoesListProps {
    list: ShoesType[];
    selectMaxCount: number;
    isRentalPage?: boolean; // rental page
    btmBtn?: JSX.Element;
    onChangeIds?: (ids: number | number[]) => void; // 아이디를 추적받아야하는가?
    defaultIds?: number | number[];
    isUsedList?: boolean;
}

const ShoesList: React.FC<ShoesListProps> = ({
    list,
    isRentalPage,
    btmBtn,
    onChangeIds,
    defaultIds,
    selectMaxCount,
    isUsedList,
}) => {
    const [rentalIdx, setRentalIdx] = useState<number>(-1);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);

    useEffect(() => {
        if (typeof defaultIds == "number") {
            setRentalIdx(defaultIds);
        } else if (defaultIds) {
            setSelectedIds([...defaultIds]);
        }
    }, [defaultIds]);

    useEffect(() => {
        if (onChangeIds) {
            if (isRentalPage) {
                onChangeIds(rentalIdx);
            } else {
                onChangeIds(selectedIds);
            }
        }
    }, [rentalIdx, selectedIds]);

    const onClickSelectedIdx = (id: number) => {
        if (isRentalPage) {
            var _idx = rentalIdx;
            if (rentalIdx === id) {
                _idx = -1;
            } else {
                _idx = id;
            }
            setRentalIdx(_idx);
        } else {
            const existIdx = selectedIds.findIndex(selectedId => selectedId === id);
            //selected 상태일때
            if (existIdx !== -1) {
                selectedIds.splice(existIdx, 1);
            } else if (selectMaxCount != 0 && selectedIds.length >= selectMaxCount) {
                selectedIds[selectedIds.length - 1] = id;
            } else {
                selectedIds.push(id);
            }
            setSelectedIds([...selectedIds]);
        }
    };

    const getSelectedIdx = useCallback(
        (id: number) => {
            if (selectMaxCount === 0) {
                // if not limt, all check img.
                return undefined;
            }
            const target = selectedIds.findIndex($id => $id === id);
            return target;
        },
        [isRentalPage, selectedIds, selectMaxCount]
    );

    const getSelected = useCallback(
        (id: number) => {
            const target = selectedIds.findIndex($id => $id === id);
            return target;
        },
        [isRentalPage, selectedIds, selectMaxCount]
    );

    const onClickShoesItem = useCallback(
        (shoesId: number, battery: number) => {
            if (shoesId == undefined) {
                return false;
            }
            // if (battery <= 0 && !isUsedList) {
            //     // battery is zero
            //     return false;
            // }
            if (onChangeIds) {
                onClickSelectedIdx(shoesId);
            }
        },
        [rentalIdx, selectedIds]
    );

    return (
        <ItemListWrap btmSpacing={btmBtn !== undefined}>
            <ItemListContainer btmSpacing={btmBtn !== undefined}>
                {list.length > 0 ? (
                    list.map($item => {
                        const index = isRentalPage ? undefined : getSelectedIdx($item.id);
                        const selected = isRentalPage ? $item.id === rentalIdx : getSelected($item.id) !== -1;
                        return (
                            <ShoesItem
                                isRentalPage={isRentalPage}
                                key={$item.id}
                                selectedIndex={index}
                                selected={selected}
                                url={$item.url}
                                onClick={onChangeIds ? () => onClickShoesItem($item.id, $item.battery) : undefined}
                                info={$item}
                            />
                        );
                    })
                ) : (
                    <NoNFTScreen>
                        <NoNFTTxt>{t("inventory:nonft:desc")}</NoNFTTxt>
                    </NoNFTScreen>
                )}
                {btmBtn}
            </ItemListContainer>
        </ItemListWrap>
    );
};

export default ShoesList;
