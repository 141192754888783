import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
//
import { pxtovw375 } from "@/utils/css";

const Wrap = styled.div`
    position: relative;
    z-index: 1000;
    left: 0;
    top: 0;
`;

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    gap: 8px;
`;

const Btn = styled.div<{ selected: boolean }>`
    border-radius: 100px;
    padding: 6px 16px;
    border: 1px solid #4a4a4a;

    ${p => p.theme.typo.body2};
    white-space: nowrap;
    -webkit-text-stroke-width: ${p => (p.selected ? "0.6px" : "0px")};
    //font-weight: ${p => (p.selected ? 600 : 400)};
    transition: background 0.2s, color 0.2s;
    ${p => (p.selected ? `color : #000; background : #fff` : `background : transparent`)};

    cursor: pointer;
    &:active {
        border: 1px solid #2d2d2d;
        color: ${p => (p.selected ? `#000` : p.theme.color.textTertiary)};
    }
`;

interface ListType {
    title: string;
    tabType: string;
}

interface Props {
    list: ListType[];
    onTab: ($marketType: string) => void;
    rootStyle?: React.CSSProperties;
}

const RadioMenu = ({ list, onTab, rootStyle }: Props) => {
    const [checkValue, setValue] = useState<string>(list[0].tabType);
    const change = useCallback(
        tabType => {
            setValue(tabType);
        },
        [checkValue]
    );

    useEffect(() => {
        onTab(checkValue);
    }, [checkValue]);

    return (
        <Wrap style={rootStyle}>
            <Container>
                {list.map((data, idx) => (
                    <Btn
                        selected={checkValue === data.tabType}
                        onClick={() => change(data.tabType)}
                        key={"radiobtn" + idx}
                    >
                        {data.title}
                    </Btn>
                ))}
            </Container>
        </Wrap>
    );
};

export default RadioMenu;
