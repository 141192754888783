import { useState, useRef } from "react";
import styled from "styled-components";

import { pxtovw375, zIndex } from "@/utils/css";
import { translate as t } from "@/i18n";
import OutsideAlerter from "@/hooks/useOutsideAlerter";
import { FilterType, StoreFilterType, TimeFilter, WalletFilterType } from "@/types";
import BtnWrapper from "./BtnWrapper";

export const TitleBxDropdown = styled.div`
    background-color: ${p => p.theme.color.iconsBg};
    border-radius: 24px;
    font-weight: 400;
    font-size: 12px;
    padding: 6px 8px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid #3a3a3a;
`;

export const DropdownArrowWrap = styled.div`
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DropdownArrow = styled.img<{ isOpen: boolean }>`
    width: 6px;
    height: 6px;
    margin-left: 5px;
    transform: ${p => (p.isOpen ? "rotate(180deg)" : "rotate(0)")};
`;

export const DropdownContent = styled.div`
    position: absolute;
    width: auto;
    right: -${pxtovw375(2)};
    padding: ${pxtovw375(8)} ${pxtovw375(16)};
    margin: ${pxtovw375(20)} 0 0 0;
    background: white;
    z-index: ${zIndex.badge};
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #8b8b8b;
    &:first-child {
        border: 1px solid #8b8b8b;
    }
`;

export const DropdownOption = styled.div<{ selected: boolean }>`
    position: relative;
    font-size: 14px;
    width: fit-content;
    padding: ${pxtovw375(8)} 0;
    color: ${p => (p.selected ? "#000" : "#555")};
    font-weight: ${p => (p.selected ? 800 : 400)};
    display: flex;
    align-items: center;
    white-space: nowrap;
    div {
        display: ${p => (p.selected ? "block" : "none")};
        position: absolute;
        bottom: 8px;
        width: 100%;
        padding: 0 0 0 ${pxtovw375(4)};
        height: 1px;
        background: #000;
        /* img {
            display: ${p => (p.selected ? "block" : "none")};
            height: ${pxtovw375(10)};
            padding: 0 0 0 2px;
        } */
    }
`;

export type DropdownType = FilterType | TimeFilter | WalletFilterType | StoreFilterType;

interface DropdonwProps {
    options: { title: string; filterType: DropdownType }[];
    onClickOption: (option: DropdownType) => void;
    selected: string;
    translate?: boolean;
}
const DropDown = ({ options, onClickOption, selected, translate }: DropdonwProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const translated = translate === false ? false : true;
    const selectedOption = options.find(raw => raw.filterType === selected);

    const onClick = (type: DropdownType) => {
        onClickOption(type);
        setOpen(false);
    };

    const onDropdown = () => {
        setOpen(!open);
    };

    const outsideClick = () => {
        setOpen(false);
    };

    return (
        <OutsideAlerter
            enabled={open}
            outsideClick={() => outsideClick()}
            style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}
        >
            <BtnWrapper onClick={onDropdown}>
                <TitleBxDropdown>
                    {selectedOption ? (translated ? t(selectedOption.title) : selectedOption.title) : ""}
                    <DropdownArrowWrap>
                        <DropdownArrow isOpen={open} src="/img/common/ic_arrow_white_fill_bottom.svg" />
                    </DropdownArrowWrap>
                    {open && (
                        <DropdownContent>
                            {options.map((option, idx) => {
                                var _option = translated ? t(option.title) : option.title;
                                return (
                                    <BtnWrapper
                                        key={idx}
                                        zIndexNum={zIndex.sticky}
                                        onClick={() => onClick(option.filterType)}
                                    >
                                        <DropdownOption selected={selected === option.filterType}>
                                            {_option}
                                            <div />
                                        </DropdownOption>
                                    </BtnWrapper>
                                );
                            })}
                        </DropdownContent>
                    )}
                </TitleBxDropdown>
            </BtnWrapper>
        </OutsideAlerter>
    );
};

export default DropDown;
