import { memo, useEffect, useCallback, useState, useMemo, useLayoutEffect } from "react";
//
import { requestTodayAttendance } from "@/api";
import { AttendanceType } from "@/dataset";
import { translate as t } from "@/i18n";
import { getAccessToken } from "@/utils/credential";
import sleep from "@/utils/sleep";
import OutsideAlerter from "@/hooks/useOutsideAlerter";
import AttendanceItem from "./AttendanceItem";
import * as S from "./styled";

interface AttendancePopupProps {
    show: boolean;
    onClose: () => void;
    data: AttendanceType;
    attendIdx: number;
    canAttend: boolean;
}

class AttendanceData {
    static executed = 0;
    static calendarStartIdx = 0;
    static calendar: boolean[] = [];
    static init() {
        AttendanceData.executed = 0;
        var today = new Date();
        const nowDate = new Date(today.getFullYear(), today.getMonth());
        var firstDay = (AttendanceData.calendarStartIdx = nowDate.getDay());
        var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
        let date = 1;
        for (var i = 1; i <= 6; i++) {
            for (var j = 1; j <= 7; j++) {
                if (i == 1 && j < firstDay) {
                    AttendanceData.calendar.push(false);
                } else if (date > lastDayOfMonth + 1) {
                    AttendanceData.calendar.push(false);
                } else {
                    AttendanceData.calendar.push(true);
                    date++;
                }
            }
            if (date >= lastDayOfMonth + 1) {
                break;
            }
        }
    }
}
const AttendancePopup = ({ show, data, attendIdx, canAttend, onClose }: AttendancePopupProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenAnim, setIsOpenAnim] = useState<boolean>(false);
    const [todayAttend, setTodayAttend] = useState<boolean>(false);

    useLayoutEffect(() => {
        AttendanceData.executed = 1;
        setTimeout(() => {
            AttendanceData.executed = 0;
        }, 1000);
    }, []);

    useEffect(() => {
        if (show) {
            AttendanceData.init();
            setIsOpen(true);
            setTimeout(() => {
                setIsOpenAnim(true);
            }, 500);
        } else {
            setIsOpen(false);
            setIsOpenAnim(false);
        }
    }, [show]);

    const onClick = useCallback(async () => {
        if (AttendanceData.executed == 1) return;
        AttendanceData.executed = 1;
        if (canAttend && !todayAttend) {
            let res = await requestTodayAttendance(getAccessToken());
            if (res.status == 1 && res.result) {
                setTodayAttend(true);
            }
        } else {
            if (todayAttend) {
                await sleep(0.3);
            }
            onClose();
        }
        AttendanceData.executed = 2;
    }, [todayAttend]);

    const getStatus = useCallback(
        idx => {
            if (attendIdx > idx) {
                return "COMPLETED";
            } else if (attendIdx == idx && canAttend) {
                if (todayAttend) {
                    return "TODAYATTEND";
                } else {
                    return "TODAY";
                }
            } else {
                return "NONE";
            }
        },
        [todayAttend]
    );

    const attendData = useMemo(() => {
        let desc = canAttend && !todayAttend ? t("attendance:desc:attend") : t("attendance:desc:exit");
        return {
            desc,
        };
    }, [todayAttend]);

    return (
        <S.Wrap style={isOpen ? {} : { display: "none" }} onClick={onClick}>
            <OutsideAlerter enabled={isOpen} outsideClick={onClick} style={{ perspective: "1000px" }}>
                <S.Inner className={isOpenAnim ? "open" : ""}>
                    <S.Title className={isOpenAnim ? "open" : ""}>{t("attendance:title")}</S.Title>
                    <S.Board className={isOpenAnim ? "open" : ""}>
                        {AttendanceData.calendar.map((day, idx) => {
                            const item = data.reward[idx - AttendanceData.calendarStartIdx];
                            return (
                                <AttendanceItem
                                    amount={item ? item.amount : -1}
                                    itemId={item ? item.itemId : -1}
                                    key={"AttendanceData" + idx}
                                    status={getStatus(item ? item.idx : -1)}
                                    idx={item ? item.idx : -1}
                                    blank={!day || !item}
                                />
                            );
                        })}
                    </S.Board>
                    <S.Desc>{attendData.desc}</S.Desc>
                </S.Inner>
            </OutsideAlerter>
        </S.Wrap>
    );
};

export default AttendancePopup;
