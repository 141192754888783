import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
//
import { translate as t } from "@/i18n";

const Wrap = styled.div`
    position: relative;
    margin-bottom: 40px;
    select {
        width: 100%;
        background-color: transparent;
        border: 1px solid #3a3a3a;
        border-radius: 8px;
        padding: 14px 16px;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        ${p => p.theme.typo.h6};
        color: ${p => p.theme.color.textPrimary};
    }
`;

const ImgArrow = styled.img`
    position: absolute;
    right: 21px;
    top: 21px;
`;

interface Props {
    value: number;
    onChange: (e: number) => void;
}

const EditorFootSize = ({ value, onChange }: Props) => {
    const [val, setVal] = useState<number>(0);

    useEffect(() => {
        setVal(value);
    }, [value]);

    const onChangeValue = useCallback(
        e => {
            console.log(e.target.value);
            setVal(parseInt(e.target.value));
            onChange(parseInt(e.target.value));
        },
        [val]
    );
    return (
        <Wrap>
            <select value={val} onChange={e => onChangeValue(e)}>
                <option value="210">210mm {t("mysac:setting:less")}</option>
                <option value="215">215mm</option>
                <option value="220">220mm</option>
                <option value="225">225mm</option>
                <option value="230">230mm</option>
                <option value="235">235mm</option>
                <option value="240">240mm</option>
                <option value="245">245mm</option>
                <option value="250">250mm</option>
                <option value="255">255mm</option>
                <option value="260">260mm</option>
                <option value="265">265mm</option>
                <option value="270">270mm</option>
                <option value="275">275mm</option>
                <option value="280">280mm {t("mysac:setting:more")}</option>
            </select>

            <ImgArrow src="/img/common/ic_arrow_white_bottom.svg" alt="" />
        </Wrap>
    );
};

export default EditorFootSize;
