import React, { useEffect, useState } from "react";
import styled from "styled-components";
//
import sleep from "@/utils/sleep";
import BtnWrapper from "./BtnWrapper";

const Wrap = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5002;
    transition: all 0.2s;
    opacity: 0;
`;

const Inner = styled.div`
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ConfirmPopup = styled.div`
    width: 270px;
    background: rgba(30, 30, 30, 0.75);
    backdrop-filter: blur(22px);
    border-radius: 14px;
    dl {
        text-align: center;
        padding: 16px;
        dt {
            ${p => p.theme.typo.body};
            color: ${p => p.theme.color.textPrimary};
            margin-bottom: 6px;
        }

        dd {
            white-space: break-spaces;
            ${p => p.theme.typo.body2};
            color: ${p => p.theme.color.textPrimary};
        }
        .desc {
            ${p => p.theme.typo.body3};
            margin-top: 2px;
        }
    }

    ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-top: 1px solid rgba(84, 84, 88, 0.65);
        li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            height: 44px;
            &.clicked {
                background: rgba(30, 30, 30, 0.95);
            }
            &:active {
                background: rgba(30, 30, 30, 0.95);
            }
            &:first-child {
                border-right: 1px solid rgba(84, 84, 88, 0.65);
                border-bottom-left-radius: 14px;
            }
            &:last-child {
                border-bottom-right-radius: 14px;
            }
        }
    }

    transition: all 0.5s;
    transform: translateY(-5px);
    &.shown {
        transform: translateY(0);
    }
`;

const Btn = styled.button`
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #0a84ff;
    width: 100%;
`;

type Props = {
    title: string;
    subText: string;
    descText?: string;
    cancelText: string;
    confirmText: string;
    isOpen: boolean;
    onCancel?: () => void;
    onConfirm?: () => Promise<void>;
    isOutsideClick?: boolean;
};

const PopupConfirm = ({
    title,
    subText,
    descText,
    cancelText,
    confirmText,
    isOpen,
    onCancel,
    onConfirm,
    isOutsideClick,
}: Props) => {
    const [isConfirmEnd, setIsConfirmEnd] = useState<boolean>(false);
    const [display, setDisplay] = useState("none");
    const [opacity, setOpacity] = useState(0);

    const onClick = async (isConfirm: boolean) => {
        if (isConfirm) {
            if (onConfirm && !isConfirmEnd) {
                setIsConfirmEnd(true);
                await onConfirm();
                await sleep(0.5);
                setIsConfirmEnd(false);
            }
        } else {
            if (onCancel) onCancel();
        }
    };

    useEffect(
        function () {
            if (isOpen == true) {
                setTimeout(() => setOpacity(1));
                setDisplay("block");
            } else {
                setOpacity(0);
                setTimeout(() => setDisplay("none"), 200);
            }
        },
        [display, opacity, isOpen]
    );

    return (
        <Wrap style={{ display, opacity }}>
            <Inner> {/*onClick={isOutsideClick ? onCancel : () => {}}> 버그있음 , gklee */} 
                <ConfirmPopup className={opacity == 0 ? "none" : "shown"}>
                    <dl>
                        <dt>{title}</dt>
                        <dd>{subText}</dd>
                        {descText ? <dd className="desc">{descText}</dd> : <></>}
                    </dl>

                    <ul>
                        <BtnWrapper onClick={() => onClick(false)}>
                            <li>
                                <Btn>{cancelText}</Btn>
                            </li>
                        </BtnWrapper>
                        <BtnWrapper onClick={() => onClick(true)}>
                            <li className={isConfirmEnd == true ? "clicked" : ""}>
                                <Btn>{confirmText}</Btn>
                            </li>
                        </BtnWrapper>
                    </ul>
                </ConfirmPopup>
            </Inner>
        </Wrap>
    );
};

export default React.memo(PopupConfirm);
