import React, { useState } from "react";
import Splash from "./components/Splash";
interface ContainerProps {
    children: any;
}

const Container: React.FC<ContainerProps> = ({ children }) => {
    const isApp = useState<boolean>(true);
    return <>{isApp ? <>{children}</> : <Splash></Splash>}</>;
};

export default Container;
