import { RevealBoxType, ShoesType, ShopItem } from "@/dataset";
import { FilterType } from "@/types";
import { translate as t } from "@/i18n";
export class MarketDatas {
    static RentalShoesList: ShoesType[] = [];
    static RentalIdx: number = 0;
    static RentalSort: FilterType = FilterType.NEW;
    static RentalCount: number = 0;
    static threshold = 0;
    static lastScrollY = 0;
    static ticking = false;
    static ShopInfo: ShopItem[];

    static init() {
        MarketDatas.RentalShoesList = [];
        MarketDatas.RentalIdx = 0;
        MarketDatas.RentalSort = FilterType.NEW;
        MarketDatas.RentalCount = 0;
        MarketDatas.threshold = 0;
        MarketDatas.lastScrollY = 0;
        MarketDatas.ticking = false;
    }

    static setRentalShoesList(list: ShoesType[]) {
        for (var s of list) {
            const exist = MarketDatas.RentalShoesList.findIndex(r => r.id == s.id);
            if (exist != -1) {
                MarketDatas.RentalShoesList[exist] = s;
            } else {
                MarketDatas.RentalShoesList.push(s);
            }
        }
        MarketDatas.RentalIdx = MarketDatas.RentalShoesList.length;
    }

    static setShopInfo(list: ShopItem[]) {
        MarketDatas.ShopInfo = list;
    }
}
