import { DefaultResponse } from "./index";
export interface User {
    userId: number;
    phoneNumber: string;
    nickname: string;
    weight: number;
    height: number;
    gender: 0 | 1 | 2;
    walletAddress: string;
    level: number;
    exp: number;
    nextLevelExp: number;
    energy: number;
    maxEnergy: number;
    satToken: number;
    satTokenStr: string;
    profileImg: string;
    tier: string;
    shoeSize: number;
    addedAt: string;
    whitelist: boolean;
    fcmToken: string;
    isRentalNotify: boolean;
    bio:string;
    follower:number;
    following:number;
    feedCnt:number;
}

export const UserGenderType = { 0: "none", 1: "man", 2: "woman" };
export const UserGenderTypeStr = { none: 0, man: 1, woman: 2 };
export type UserDataKeys = string;

export interface RemoveProfileImgResponse extends DefaultResponse {}
export interface DuplicateNickname extends DefaultResponse {}
export interface RemoveAccountResponse extends DefaultResponse {}
export interface HandshakeResponse extends DefaultResponse, User {}
export interface FirstHandshakeResponse extends DefaultResponse {}
export interface HandshakeFCMResponse extends DefaultResponse {}
export interface GetProfileImgesResponse extends DefaultResponse {
    data: string[];
}

export interface UserLevel {
    nextLevelExp: number;
    prevLevelExp: number;
    level: number;
    energy: number;
}
