import { DefaultResponse } from "./index";

export interface BuyEnergyDrinkResponse extends DefaultResponse {
    userEnergy: number;
    maxEnergy: number;
}

export interface ShopItem {
    shopId: number;
    type: number; // 0 : revealbox, 1: protection(downgrade)
    price: number;
    enable: boolean;
    imgURL: string;
    marketImgURL: string;
    title: string;
}

export interface GetShopInfoResponse extends DefaultResponse {
    items: ShopItem[];
}

export interface BuyRevealBoxResponse extends DefaultResponse {
    revealId: number;
}

export interface BuyShopItemResponse extends DefaultResponse {
    id: number;
}

export interface ItemType {
    discriminator: "itemtype";
    id: number;
    itemId: number; // TB_DATA_ITEM->itemId
    title: string;
    amount: number;
    type: number;
    available: boolean;
    marketImgURL: string;
    imgURL: string;
}
export function instanceOfItem(object: any): object is ItemType {
    return object.discriminator === "itemtype";
}

export interface ItemProbability {
    itemType: number;
    itemId: number;
    probability: number;
    amount: number;
    meta_itemId: number;
    imgURL: string;
    title: string;
}

export interface GetItemMetaResponse extends DefaultResponse {
    itemProbability: ItemProbability[];
}

export const ItemDefaultValue: ItemType = {
    amount: 0,
    discriminator: "itemtype",
    available: false,
    id: 0,
    itemId: 0,
    marketImgURL: "",
    imgURL: "",
    title: "",
    type: 0,
};

export interface ItemResult {
    itemId: number;
    title: string;
    imgURL: string;
    amount: number;
}

export interface OpenItemBoxResponse extends DefaultResponse {
    result: ItemResult[];
    leftAmount: number;
}
