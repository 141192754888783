import styled from "styled-components";
import { pxtovw375, BottomTabHeight, zIndex } from "@/utils/css";

export const Section = styled.div`
    padding-bottom: 50px;
`;

export const Wrapper = styled.div`
    padding: 116px ${pxtovw375(16)} ${BottomTabHeight};
    box-sizing: border-box;
`;

export const TopSticky = styled.div`
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 ${pxtovw375(16)};
    z-index: 10000;
    position: sticky;
    top: 116px;
    overflow: hidden;
    background-color: transparent;
`;

export const TabBx = styled.div`
    padding: 14px 0;
    background-color: #000000;
    width: 100%;
    transition: transform 0.25s;
`;

export const Title = styled.h2`
    margin-top: 12px;
    margin-bottom: 8px;
    ${p => p.theme.typo.h4};
    color: ${p => p.theme.color.textPrimary};
    width: 100%;
    padding: 0 ${pxtovw375(16)};
`;

export const Inner = styled.div`
    width: 100%;
    position: relative;
    z-index: ${zIndex.normal};
    background-color: #000;
`;

export const SubTab = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    padding-bottom: 10px;
`;

export const SubCounter = styled.div`
    font-size: ${pxtovw375(12)};
    font-weight: 400;
    line-height: 20px;
    color: ${p => p.theme.color.textSecondary};
    display: flex;
    p {
        margin-left: ${pxtovw375(4)};
    }
`;

export const ShoesContainer = styled.div`
    margin-top: ${pxtovw375(16)};
`;

export const BlaknContainer = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    ${p => p.theme.typoVW.body7};
    color: ${p => p.theme.color.textTertiary};
`;

export const ItemListContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: ${pxtovw375(11)};
    row-gap: ${pxtovw375(40)};
`;

export const BatteryItemContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: ${pxtovw375(11)};
    column-gap: 4%;
    margin-top: ${pxtovw375(15)};
`;

export const BtnFilter = styled.button`
    background: #2d2d2d;
    border: 1px solid #3a3a3a;
    border-radius: 100px;
    padding: 0px 8px;
    span {
        ${p => p.theme.typo.body3};
        color: ${p => p.theme.color.textPrimary};
        margin-right: 6px;
    }
`;

export const Message = styled.dl`
    text-align: center;
    margin-bottom: ${pxtovw375(40)};
    dt {
        ${p => p.theme.typoVW.h5};
        color: ${p => p.theme.color.textPrimary};
        margin-bottom: ${pxtovw375(16)};
    }

    dd {
        ${p => p.theme.typoVW.body1};
        color: ${p => p.theme.color.textPrimary};
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
`;

export const ShopContainer = styled.div`
    margin-top: ${pxtovw375(16)};
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: ${pxtovw375(12)};
    row-gap: ${pxtovw375(20)};

    padding-bottom: 0;
`;