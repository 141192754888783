import jwt, { JwtPayload } from "jsonwebtoken";
import { USER_TOKEN, USER_ID } from "@/api";
const EXPIRES_THRESHOLD_SEC = 60; // 지정한 초만큼 실제 만료 시간보다 먼저 재발급할 수 있도록 함

export const getAccessToken = (): string => {
    const token = localStorage.getItem(USER_TOKEN);
    if (token !== null) {
        return token;
    }
    return "";
};
export const hasCredential = (): boolean => {
    return Boolean(getAccessToken());
};
export const setAccessToken = (v: string) => localStorage.setItem(USER_TOKEN, v);
export const removeTokens = () => {
    localStorage.removeItem(USER_TOKEN);
};

export const isExpiredToken = (token: string): boolean => {
    let expiresIn = 0;
    const tokenDecode = jwt.decode(token);

    if (tokenDecode) {
        const exp = (tokenDecode as JwtPayload).exp;
        if (typeof exp === "undefined") {
            return false;
        }

        expiresIn = (exp - EXPIRES_THRESHOLD_SEC) * 1000;
    }

    return Date.now() >= expiresIn;
};

export const getUserId = (): number => {
    const userId = localStorage.getItem(USER_ID);
    if (userId != null) {
        return parseInt(userId);
    }
    return 0;
};

export const setUserId = (userId: number) => {
    localStorage.setItem(USER_ID, userId.toString());
};
