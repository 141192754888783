import React from "react"
import styled from "styled-components";

import { pxtovw375 } from "@/utils/css";
import { translate as t } from "@/i18n";

const Wrap = styled.div`
    width: 100%;
    height: ${pxtovw375(80)};
    margin-bottom: ${pxtovw375(16)};
`;

const Inner = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${pxtovw375(12)};
    padding: ${pxtovw375(16)} 0;
    width: 100%;
    height: 100%;
`;

const EnergyGageBx = styled.div`
    width: 100%;
    height: 100%;
`;

const EnergyGage = styled.div<{ gage: number }>`
    position: relative;
    width: 100%;
    height: 100%;
    background: #2d2d2d;
    border-radius: 8px;
    margin-bottom: 3px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
        display: inline-block;
        width: 100%;
        height: 100%;
        transition: width 0.25s;
        background: linear-gradient(270deg, transparent ${p => 100 - p.gage}%, #f3adff 0%, #85afff 100%);
        border-radius: 8px;
    }
`;

const EnergyInfoBx = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
        width: ${pxtovw375(13)};
        height: ${pxtovw375(20)};
        margin-right: ${pxtovw375(11)};
    }
`;

const EnergyInfo = styled.dl<{ energy: number }>`
    display: flex;
    gap: ${pxtovw375(4)};
    height: 100%;
    dt {
        ${p => p.theme.typoVW.h8};
        line-height: ${pxtovw375(24)};
        color: #fff;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    dd {
        ${p => p.theme.typoVW.h4};
        line-height: ${pxtovw375(24)};
        color: ${p => p.theme.color.textPrimary};
        ${p => (p.energy <= 5 ? "color : #E74C3C" : "")};
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
`;

const EnergyMarkingWrap = styled.div`
    position: absolute;
    width: 100%;
    bottom: -${pxtovw375(8)};
    display: flex;
    justify-content: space-between;
`;
const EnergyMarkingBx = styled.div<{ isLeft: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${p => (p.isLeft ? "flex-start" : "flex-end")};
    gap: 3px;
`;

const EnergyMarking = styled.div`
    width: 1px;
    height: 4px;
    background: #555555;
`;

interface Props {
    energy: number;
    min: number;
    max: number;
}

const EnergyBar = ({ energy, min, max }: Props) => {
    const gage = Math.floor((energy / (max - min)) * 100);
    return (
        <Wrap>
            <Inner>
                <EnergyGageBx>
                    <EnergyGage gage={gage}>
                        <span></span>
                    </EnergyGage>
                </EnergyGageBx>
                <EnergyInfoBx>
                    <img src="/img/common/ic_electric.svg" alt="ic_electric" />
                    <EnergyInfo energy={energy}>
                        <dd>{energy}</dd>
                        <dt>{t("common:energy")}</dt>
                    </EnergyInfo>
                </EnergyInfoBx>
                <EnergyMarkingWrap>
                    <EnergyMarkingBx isLeft>
                        <EnergyMarking />
                        <p>{min}</p>
                    </EnergyMarkingBx>
                    <EnergyMarkingBx isLeft={false}>
                        <EnergyMarking />
                        <p>{max}</p>
                    </EnergyMarkingBx>
                </EnergyMarkingWrap>
            </Inner>
        </Wrap>
    );
};

export default React.memo(EnergyBar);
