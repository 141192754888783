import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const Wrap = styled.div<{ isHide: boolean }>`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: ${p => (p.isHide ? "none" : "flex")};
    justify-content: center;
    align-items: center;
    z-index: 999999;
    left: 0;
    top: 0;
`;

const loadingAni = keyframes`
  0% {
    stroke-dashoffset: 150.79653930664062;
    transform: rotate(0);
  }
  50% {
    stroke-dashoffset: 0;
    transform: rotate(180deg);
  }
  100% {
    stroke-dashoffset: -150.79653930664062;
    transform: rotate(360deg);
  }
`;

const LoadingBx = styled.div`
    .preloader-2 {
        margin: 120px auto 0;
        height: 40px;
    }

    div {
        color: #fff;
        margin: 5px 0;
        text-transform: uppercase;
        text-align: center;
        font-family: 'Arial', sans-serif;
        font-size: 10px;
        letter-spacing: 2px;
        opacity: 0.5;
    }

    .preloader-2 .line {
        width: 1px;
        height: 15px;
        background: #fff;
        margin: 0 2px;
        display: inline-block;
        animation: opacity-2 1000ms infinite ease-in-out;
    }

    .preloader-2 .line-1 { animation-delay: 800ms; }
    .preloader-2 .line-2 { animation-delay: 600ms; }
    .preloader-2 .line-3 { animation-delay: 400ms; }
    .preloader-2 .line-4 { animation-delay: 200ms; }
    .preloader-2 .line-6 { animation-delay: 200ms; }
    .preloader-2 .line-7 { animation-delay: 400ms; }
    .preloader-2 .line-8 { animation-delay: 600ms; }
    .preloader-2 .line-9 { animation-delay: 800ms; }

    @keyframes opacity-2 { 
        0% { 
            opacity: 1;
            height: 15px;
        }
        50% { 
            opacity: 0;
            height: 9px;
        }
        100% { 
            opacity: 1;
            height: 15px;
        }  
    }
`;

interface Props {
    time?: number;
}
var LoadingTimeout: NodeJS.Timeout | null = null;
const Loading = ({ time }: Props) => {
    const [isHide, setHide] = useState<boolean>(false);
    useEffect(() => {
        LoadingTimeout = setTimeout(
            () => {
                setHide(true);
            },
            time ? time * 1000 : 3000
        );
        return () => {
            if (LoadingTimeout) {
                clearTimeout(LoadingTimeout);
            }
        };
    }, []);
    return (
        <Wrap isHide={isHide}>
            <LoadingBx>
                <div className="preloader-2">
                    <span className="line line-1"></span>
                    <span className="line line-2"></span>
                    <span className="line line-3"></span>
                    <span className="line line-4"></span>
                    <span className="line line-5"></span>
                    <span className="line line-6"></span>
                    <span className="line line-7"></span>
                    <span className="line line-8"></span>
                    <span className="line line-9"></span>
                    <div>LOADING...</div>
                </div>
            </LoadingBx>
        </Wrap>
    );
};

export default Loading;
