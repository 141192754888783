import React, { Suspense, useEffect, useMemo } from "react";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import { ThemeProvider } from "styled-components";
import { AnimateSharedLayout } from "framer-motion";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
//
import Container from "./Container";
import Routes from "./routes";
import { fetcher } from "./utils/axios";
import theme from "./theme";
import ModalProvider from "./modal";
import GlobalStyles from "./components/GlobalStyles";
import AppWrapper from "./components/AppWrapper";
import EventManager from "@/components/EventManager";
import store, { persistor } from "@/common/store";
import Loading from "@/components/Common/Loading";
import CheckNetworkStatus from "./components/CheckNetworkStatus";
import DebugLogging, { debugLog } from "./components/DebugLogging";
import "@/common/registNativeJS";
import useEnterBackground from "./hooks/useEnterBackground";
import { UPDATE_HTML, APP_STORE_LINK, GOOGLE_PLAY_STORE_LINK } from "@/utils/constant";
import { client, useSocketIsConnect, requestVersion, requestHandshakeFCM } from "@/api";
import { getAccessToken } from "./utils/credential";

const App: React.FC = () => {
    const [isBackground] = useEnterBackground();
    const [connection] = useSocketIsConnect();
    useEffect(() => {
        const OnlineStoreBuild = process.env.REACT_APP_ONLINESTORE_BUILD;
        if (OnlineStoreBuild) return;

        setTimeout(() => {
            var storeLink = "";
            try {
                const navigator = window.navigator;
                const userAgent = navigator.userAgent;
                const normalizedUserAgent = userAgent.toLowerCase();

                const isIos =
                    /ip(ad|hone|od)/.test(normalizedUserAgent) ||
                    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
                const isAndroid = /android/.test(normalizedUserAgent);
                console.log("ios : ", isIos);
                console.log("isAndroid : ", isAndroid);
                storeLink = isIos ? APP_STORE_LINK : GOOGLE_PLAY_STORE_LINK;
                debugLog("Store Link : " + storeLink);
                if (!isIos && !isAndroid) {
                    return;
                }
            } catch (err) {
                debugLog("get store link err" + err);
            }
            try {
                if (process.env.NODE_ENV === "development") return;

                if (window.native === undefined || window.native.startExercise === undefined) {
                    // 엄청 옛날
                    debugLog("window.native is undefined : " + window.native);
                    document.write(UPDATE_HTML(storeLink));
                } else if (!window.native.instance || window.native.instance.target == null) {
                    // 조금 옛날
                    debugLog(
                        "window.native.instance is undefined : " +
                            (window.native.instance ? JSON.stringify(window.native.instance) : "undefined")
                    );
                    document.write(UPDATE_HTML(storeLink));
                } else if (window.native.instance.target && window.native.instance.buildVersion) {
                    // 현재
                    debugLog(
                        "now version : " + window.native.instance.buildVersion + " | " + window.native.instance.target
                    );
                    requestVersion(window.native.instance.buildVersion, window.native.instance.target).then(res => {
                        if (res.status == -1) {
                            document.write(UPDATE_HTML(storeLink));
                            throw res.msg;
                        }
                    });
                }
            } catch (err) {
                debugLog("version check err : " + err);
            }
        }, 1000 * 15);
    }, []);

    useEffect(() => {
        if (!isBackground && !connection.isConnected) {
            client.disconnect();
            setTimeout(function () {
                client.connect();
            }, 1000 * 1);
        }
    }, [isBackground, connection]);

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <SWRConfig value={{ fetcher }}>
                        <BrowserRouter>
                            <Suspense fallback={<Loading />}>
                                <ModalProvider>
                                    <AnimateSharedLayout type="crossfade">
                                        <Container>
                                            <AppWrapper />
                                            <Routes />
                                            <EventManager />
                                            <GlobalStyles />
                                        </Container>
                                        <CheckNetworkStatus />
                                        {/* <DebugLogging /> */}
                                    </AnimateSharedLayout>
                                </ModalProvider>
                            </Suspense>
                        </BrowserRouter>
                    </SWRConfig>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    );
};

export default App;
