/* eslint-disable */
//auto gen
import {
    InitRunningResponse,
    UpdateRunningTimeResponse,
    RunningShoes,
    ConnectPartyResponse,
    CheckRunningResponse,
    FinishRunningResponse,
    SyncRunningResponse,
    UpdateRandomSpotResponse,
    Geolocation,
    GetFinishedRunningResponse,
    GetRunningPartyUserResponse,
    StartEvt,
    GetRandomSpotResponse,
    Spot,
    DEFAULT_FIN_DATA,
} from "../dataset/"
import { client, SockClientOpt, OnRecvData } from "./index";
import { useEffect, useState, useLayoutEffect } from "react";

const hash = require('object-hash');
type StoreType = {
  [_: string]: GetRandomSpotResponse
}
type StoreFunc = (data: GetRandomSpotResponse) => void

let store: StoreType = {};
let subscribeEvent: { [_: string]: StoreFunc[] } = {}
export const request = function(rId:number ,coord:Geolocation ,opt?:SockClientOpt) : Promise<GetRandomSpotResponse>{
  return new Promise((resolve, reject) => {
    client.emit("running:GetRandomSpot", rId,coord, async function ack(data:any){
      resolve(await OnRecvData(data));

      let key = opt?.useKey ? hash([opt.useKey]) : hash([rId,coord]);
      if(opt?.useStore){
        store[key] = data;
      }

      if (subscribeEvent[key]) {
        for (let evt of subscribeEvent[key]) {
          evt(data);
        }
      }
    });
  })
}


function subscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  subscribeEvent[key].push(func)
}
function unsubscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  let idx = subscribeEvent[key].findIndex(e => e == func);
  if (idx >= 0) subscribeEvent[key].splice(idx, 1)
}
setTimeout(()=>{
  client.on("running:GetRandomSpot", (data)=>{
    for(let key of Object.keys(subscribeEvent)){
      for(let evt of subscribeEvent[key]){
        evt(data);
      }
    }
  });
}, 100);

export default function (rId:number ,coord:Geolocation ,opt?:SockClientOpt) : [GetRandomSpotResponse | null, ()=>Promise<GetRandomSpotResponse>] {
  
  let key = opt?.useKey ? hash([opt.useKey]) : hash([rId,coord]);
  const [Data, setData] = useState<GetRandomSpotResponse | null>(opt?.useStore ? (store[key]) : null);

  useLayoutEffect(() => {
    const handleChange = (data: GetRandomSpotResponse)=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[rId]);

  useEffect(() => {
    const handleChange = (data: GetRandomSpotResponse)=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[rId]);

  return [Data, () => request(rId,coord,opt)]

}
