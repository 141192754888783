import { ProfileNS as NS } from "PageNamespace";
import { translate as t } from "@/i18n";
import EditorSex from "@/components/Profile/EditorSex";
import EditorStature from "@/components/Profile/EditorStature";
import EditorWeight from "@/components/Profile/EditorWeight";
import EditorFootSize from "@/components/Profile/EditorFootSize";
import { ButtonType } from "@/types";

const PopupData: NS.PopupData[] = [
    {
        title: t("mysac:gender"),
        editor: EditorSex,
        value: null,
        onClick: async () => {},
        buttonType: ButtonType.CONFIRM,
    },
    {
        title: t("mysac:height"),
        editor: EditorStature,
        value: null,
        onClick: async () => {},
        buttonType: ButtonType.CONFIRM,
    },
    {
        title: t("mysac:weight"),
        editor: EditorWeight,
        value: null,
        onClick: async () => {},
        buttonType: ButtonType.CONFIRM,
    },
    {
        title: t("mysac:shoesize"),
        editor: EditorFootSize,
        value: null,
        onClick: async () => {},
        buttonType: ButtonType.CONFIRM,
    },
];

const IS_NOTICE_KEY = {
    inapp: "IS_NOTICE_INAPP",
    sound: "IS_NOTICE_SOUND",
    rental: "IS_NOTICE_RENTAL",
};
export class ProfileDatas {
    static isNoticeInapp: boolean = true;
    static isNoticeSound: boolean = true;
    static isNoticeRental: boolean = true;

    static async init() {
        const data = await GetNoticeData();
        ProfileDatas.isNoticeInapp = data.inapp;
        ProfileDatas.isNoticeSound = data.sound;
        ProfileDatas.isNoticeRental = data.rental;
        // 인앱 알람 체크
        if (ProfileDatas.isNoticeInapp) {
            try {
                let res = await window.native.checkNoticePerm();
                if (res && parseInt(res.granted) == 0) {
                    ProfileDatas.isNoticeInapp = false;
                    localStorage.setItem(IS_NOTICE_KEY.inapp, "0");
                }
            } catch (err) {}
        }
    }
}

export const SetNoticeData = (data: { inapp: boolean; sound: boolean; rental: boolean }) => {
    localStorage.setItem(IS_NOTICE_KEY.inapp, data.inapp ? "1" : "0");
    localStorage.setItem(IS_NOTICE_KEY.sound, data.sound ? "1" : "0");
    localStorage.setItem(IS_NOTICE_KEY.rental, data.rental ? "1" : "0");
    if (data.inapp) {
        try {
            window.native.noticePerm();
        } catch (err) {
            console.log("noticeperm error", err);
        }
    }
};

export const GetNoticeData = async () => {
    let result = {
        inapp: false,
        sound: false,
        rental: false,
    };
    let inapp = localStorage.getItem(IS_NOTICE_KEY.inapp);
    if (inapp) result.inapp = parseInt(inapp) ? true : false;
    else {
        try {
            let res = await window.native.checkNoticePerm();
            if (res && parseInt(res.granted) == 1) {
                ProfileDatas.isNoticeInapp = true;
                localStorage.setItem(IS_NOTICE_KEY.inapp, "1");
            }
        } catch (err) {}
    }

    let sound = localStorage.getItem(IS_NOTICE_KEY.sound);
    if (sound) result.sound = parseInt(sound) ? true : false;

    let rental = localStorage.getItem(IS_NOTICE_KEY.rental);
    if (rental) result.rental = parseInt(rental) ? true : false;

    return result;
};

export default {
    PopupData,
};
