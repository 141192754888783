import { DefaultResponse } from "./index";

export type LeaderboardOrderType = "distance" | "runtime" | "earnsat";
export enum LeaderboardTab {
    DISTANCE = "distance",
    RUNTIME = "runtime",
    EARNSAT = "earnsat",
}
export interface LeaderboardData {
    rank: number;
    userId: number;
    nickname: string;
    level: number;
    tier: string;
    profileImg: string;
    target: number;
}
export interface GetLeaderboardResponse extends DefaultResponse {
    datas: LeaderboardData[];
    mine: LeaderboardData | null;
}
