import { DefaultResponse, DefaultInvenType, ShoesType } from "./index";

export interface RevealBoxType extends DefaultInvenType {
    discriminator: "revealtype";
    userId: number;
    title: string; // sac box
    itemType: number; // 1 : shoes, 2: ??
    addedAt: string;
    used: boolean;
    hash: string; // #3423
    imgURL: string;
    marketImgURL: string;
    price?: number;
}

export function instanceOfReveal(object: any): object is RevealBoxType {
    return object.discriminator === "revealtype";
}

export interface RevealOpenItem {
    discriminator: string;
    id: number;
    rarity: string;
    grade: string;
    name: string;
    hash: string;
    imgURL: string;
}
