import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
//
import { translate as t } from "@/i18n";
import { useSocketDispatchModal, useSocketGetAttendance } from "@/api";
import { getAccessToken } from "@/utils/credential";
import { RootState } from "@/common/reducer";
import { DelModal, SetModal, AttendanceModal } from "@/common/action";
import Alert from "@/components/Common/Alert";
import AttendancePopup from "./Attendance";

const Wrap = styled.div``;

class EventData {
    static attendIdx = -1;
}

const EventManager = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const modalSelector = useSelector((state: RootState) => state.Modal);

    const [modalResponse, _requestDispatchModal] = useSocketDispatchModal(getAccessToken(), { useStore: true });
    const [attendResponse, _] = useSocketGetAttendance(getAccessToken(), { useStore: true });

    useEffect(() => {
        if (modalResponse) {
            const m = modalResponse.modal;
            dispatch(SetModal(t(m.msg, m.params), m.position, m.duration));
        }
    }, [modalResponse]);

    useEffect(() => {
        /* Check Attendance */
        if (
            location.pathname.indexOf("home") != -1 &&
            attendResponse &&
            attendResponse.data 
        ) {
            if (EventData.attendIdx != attendResponse.attendIdx) {
                dispatch(AttendanceModal(true));
                EventData.attendIdx = attendResponse.attendIdx;
            }
        }
    }, [location.pathname, attendResponse]);

    const close = useCallback((key: string) => {
        dispatch(DelModal(key));
    }, []);

    const onCloseAttendance = useCallback(() => {
        dispatch(AttendanceModal(false));
    }, []);

    return (
        <Wrap>
            {modalSelector.modals.map((m, idx) => {
                return (
                    <Alert
                        key={m.key}
                        isOpen
                        close={() => close(m.key)}
                        message={m.msg}
                        position={"top"} //m.position
                        bottomMargin={160 * (idx + 1)}
                        duration={m.duration}
                        isAlert={m.isAlert}
                        textRed={m.textRed}
                    />
                );
            })}
            {modalSelector.showAttendance && attendResponse && (
                <AttendancePopup
                    show={modalSelector.showAttendance}
                    onClose={onCloseAttendance}
                    data={attendResponse.data}
                    attendIdx={attendResponse.attendIdx}
                    canAttend={attendResponse.canAttend}
                />
            )}
        </Wrap>
    );
};

export default EventManager;
