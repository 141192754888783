import { ModalNS as NS } from "CommonNamespace";

export interface ModalAction {
    type: string;
    msg?: string;
    pos?: NS.AlertPositionType;
    duration?: number;
    isAlert?: boolean;
    textRed?: boolean;
    key?: string;
    show?: boolean;
}

export const SET_MODAL = "MODAL/SET";
export const DEL_MODAL = "MODAL/DEL";
export const ATTENDANCE_MODAL = "MODAL/ATTENDANCE";

export const SetModal = (
    msg: string,
    pos: NS.AlertPositionType,
    duration?: number,
    isAlert?: boolean,
    textRed?: boolean
) => ({
    type: SET_MODAL,
    msg,
    pos,
    duration,
    isAlert,
    textRed,
});

export const DelModal = (key: string) => ({ type: DEL_MODAL, key });

export const AttendanceModal = (show: boolean) => ({ type: ATTENDANCE_MODAL, show });
