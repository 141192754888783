import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
//
import { translate as t } from "@/i18n";
const Wrap = styled.div`
    position: relative;
    margin-bottom: 40px;
    select {
        width: 100%;
        background-color: transparent;
        border: 1px solid #3a3a3a;
        border-radius: 8px;
        padding: 14px 16px;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        ${ p => p.theme.typo.h6 };
        color: ${ p => p.theme.color.textPrimary };
    }
`;

const ImgArrow = styled.img`
    position: absolute;
    right: 21px;
    top: 21px;
`;

interface Props {
    value: number;
    onChange: (e: number) => void;
}

const EditorWeight = ({ value, onChange }: Props) => {
    const [val, setVal] = useState<number>(0);

    useEffect(() => {
        setVal(value);
    }, [value]);

    const onChangeValue = useCallback(
        e => {
            console.log(e.target.value);
            setVal(parseInt(e.target.value));
            onChange(parseInt(e.target.value));
        },
        [val]
    );
    return (
        <Wrap>
            <select value={value} onChange={e => onChangeValue(e)}>
                <option value="30">30kg {t("mysac:setting:less")}</option>
                <option value="31">31kg</option>
                <option value="32">32kg</option>
                <option value="33">33kg</option>
                <option value="34">34kg</option>
                <option value="35">35kg</option>
                <option value="36">36kg</option>
                <option value="37">37kg</option>
                <option value="38">38kg</option>
                <option value="39">39kg</option>

                <option value="40">40kg</option>
                <option value="41">41kg</option>
                <option value="42">42kg</option>
                <option value="43">43kg</option>
                <option value="44">44kg</option>
                <option value="45">45kg</option>
                <option value="46">46kg</option>
                <option value="47">47kg</option>
                <option value="48">48kg</option>
                <option value="49">49kg</option>

                <option value="50">50kg</option>
                <option value="51">51kg</option>
                <option value="52">52kg</option>
                <option value="53">53kg</option>
                <option value="54">54kg</option>
                <option value="55">55kg</option>
                <option value="56">56kg</option>
                <option value="57">57kg</option>
                <option value="58">58kg</option>
                <option value="59">59kg</option>

                <option value="60">60kg</option>
                <option value="61">61kg</option>
                <option value="62">62kg</option>
                <option value="63">63kg</option>
                <option value="64">64kg</option>
                <option value="65">65kg</option>
                <option value="66">66kg</option>
                <option value="67">67kg</option>
                <option value="68">68kg</option>
                <option value="69">69kg</option>

                <option value="70">70kg</option>
                <option value="71">71kg</option>
                <option value="72">72kg</option>
                <option value="73">73kg</option>
                <option value="74">74kg</option>
                <option value="75">75kg</option>
                <option value="76">76kg</option>
                <option value="77">77kg</option>
                <option value="78">78kg</option>
                <option value="79">79kg</option>

                <option value="80">80kg</option>
                <option value="81">81kg</option>
                <option value="82">82kg</option>
                <option value="83">83kg</option>
                <option value="84">84kg</option>
                <option value="85">85kg</option>
                <option value="86">86kg</option>
                <option value="87">87kg</option>
                <option value="88">88kg</option>
                <option value="89">89kg</option>

                <option value="90">90kg</option>
                <option value="91">91kg</option>
                <option value="92">92kg</option>
                <option value="93">93kg</option>
                <option value="94">94kg</option>
                <option value="95">95kg</option>
                <option value="96">96kg</option>
                <option value="97">97kg</option>
                <option value="98">98kg</option>
                <option value="99">99kg</option>

                <option value="100">100kg</option>
                <option value="101">101kg</option>
                <option value="102">102kg</option>
                <option value="103">103kg</option>
                <option value="104">104kg</option>
                <option value="105">105kg</option>
                <option value="106">106kg</option>
                <option value="107">107kg</option>
                <option value="108">108kg</option>
                <option value="109">109kg</option>

                <option value="110">110kg</option>
                <option value="111">111kg</option>
                <option value="112">112kg</option>
                <option value="113">113kg</option>
                <option value="114">114kg</option>
                <option value="115">115kg</option>
                <option value="116">116kg</option>
                <option value="117">117kg</option>
                <option value="118">118kg</option>
                <option value="119">119kg</option>

                <option value="120">120kg</option>
                <option value="121">121kg</option>
                <option value="122">122kg</option>
                <option value="123">123kg</option>
                <option value="124">124kg</option>
                <option value="125">125kg</option>
                <option value="126">126kg</option>
                <option value="127">127kg</option>
                <option value="128">128kg</option>
                <option value="129">129kg</option>

                <option value="130">130kg {t("mysac:setting:more")}</option>
            </select>

            <ImgArrow src="/img/common/ic_arrow_white_bottom.svg" alt="" />
        </Wrap>
    );
};

export default EditorWeight;
