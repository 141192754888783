import styled from "styled-components";

import { pxtovw375, zIndex } from "@/utils/css";
import { BadgeType, InvenShoesType } from "@/types";

export const Wrap = styled.div<{ shown: boolean }>`
    position: relative;
    width: ${pxtovw375(165)};
    transition: all 0.4s ease-out;
    height: ${p => (p.shown ? "initial" : "400px")};
    transform: ${p => (p.shown ? "translateY(0) scale(1)" : "translateY(10px) scale(1.02)")};
    opacity: ${p => (p.shown ? 1 : 0)};
`;
export const WrapBlack = styled.div`
    display: none;
    position: absolute;
    z-index: ${zIndex.normal};
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    border-radius: 8px;
`;

export const ShoesImage = styled.div<{ state?: string }>`
    position: relative;
    width: ${pxtovw375(166)};
    height: ${pxtovw375(166)};
    display: flex;
    justify-content: center;
    align-items: center;
    // background: #212121;
    border-radius: 8px;
    margin-bottom: ${pxtovw375(16)};
    overflow: hidden;
    border: 1px solid #212121;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        background-color: #000;
        border-radius: 8px;
    }
`;

export const ShoesImageBack = styled.div<{ isLow?: boolean }>`
    display: block;
    width: 98%;
    height: 98%;
    position: absolute;
    background: ${p => (p.isLow ? "rgba(0, 0, 0, 0.5)" : "rgba(0,0,0,0)")};
`;

export const ShoesInfo = styled.div<{ state?: string }>`
    opacity: ${p => (p.state === InvenShoesType.RENTAL_REGISTRATION ? 0.5 : 1)};
`;

export const Badge = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: ${pxtovw375(4)};
    margin-bottom: ${pxtovw375(8)};
    span {
        font-size: ${pxtovw375(14)};
        font-weight: 600;
        color: white;
    }
    div {
        width: ${pxtovw375(1)};
        height: ${pxtovw375(11)};
        background: #4a4a4a;
    }
`;

export const InfoBx = styled.ul<{ isLow?: boolean }>`
    li {
        display: flex;
        gap: ${pxtovw375(4)};
        ${p => p.theme.typoVW.h7}
        color: ${p => p.theme.color.disabledTextBold};
        &:last-child {
            margin-top: 2px;
            ${p => p.theme.typoVW.body6}
            color: ${p => p.theme.color.disabledText};
        }
        p {
            color: ${p => (p.isLow ? "#E74C3C" : p.theme.color.disabledText)};
        }
    }
`;

export const ShoesState = styled.div<{ state: string }>`
    position: absolute;
    left: ${pxtovw375(6)};
    top: ${pxtovw375(6)};
    ${p => p.theme.typo.body4};
    color: ${p => p.theme.color.textPrimary};
    padding: ${pxtovw375(3)} ${pxtovw375(6)};
    border-radius: ${pxtovw375(100)};

    font-weight: 600;
    font-size: ${pxtovw375(12)};
    display: flex;
    align-items: center;
`;

export const ShoesStateText = styled.div`
    background: linear-gradient(103.81deg, #9d52ff -13.38%, #5657ff 110.29%, #5849ff 110.29%);
    min-width: ${pxtovw375(39)};
    height: ${pxtovw375(22)};
    border-radius: ${pxtovw375(100)};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 ${pxtovw375(5)};
`;

export const ShoesStateIcon = styled.div`
    width: ${pxtovw375(15)};
    height: ${pxtovw375(15)};
    background-color: #fff;
    border-radius: 50%;
    color: #9151ff;
    text-align: center;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${pxtovw375(4)};
`;

export const LinkButton = styled.div<{ isOp?: string }>``;

export const CheckIconWrapper = styled.div`
    display: none;
    position: absolute;
    z-index: ${zIndex.boxes};
    right: ${pxtovw375(10)};
    top: ${pxtovw375(10)};
`;
