import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
//
import { translate as t } from "@/i18n";

const Wrap = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    width: 14%;
    height: 76px;
    gap: 8px;
    border-radius: 10px;
    overflow: hidden;

    /* background-image: radial-gradient(circle at 100% 100%, transparent 0px, #e2bbf4 0px, #e2bbf4 1px, transparent 1px),
        linear-gradient(to right, #e2bbf4, #a7c9ee),
        radial-gradient(circle at 0% 100%, transparent 0px, #a7c9ee 0px, #a7c9ee 1px, transparent 1px),
        linear-gradient(to bottom, #a7c9ee, #d0679f),
        radial-gradient(circle at 0% 0%, transparent 0px, #d0679f 0px, #d0679f 1px, transparent 1px),
        linear-gradient(to left, #d0679f, #f3adff),
        radial-gradient(circle at 100% 0%, transparent 0px, #f3adff 0px, #f3adff 1px, transparent 1px),
        linear-gradient(to top, #f3adff, #e2bbf4); */
    /* background-size: 1px 1px, calc(100% - 2px) 1px, 1px 1px, 1px calc(100% - 2px); */
    /* background-position: top left, top center, top right, center right, bottom right, bottom center, bottom left, center left; */
    background-repeat: no-repeat;

    .crossed {
        position: absolute;
        top: 2px;
        bottom: 2px;
        left: 2px;
        right: 2px;
        background: linear-gradient(
                to top left,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) calc(50% - 1px),
                rgba(255, 255, 255, 0.8) 50%,
                rgba(0, 0, 0, 0) calc(50% + 1px),
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(
                to top right,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) calc(50% - 1px),
                rgba(255, 255, 255, 0.8) 50%,
                rgba(0, 0, 0, 0) calc(50% + 1px),
                rgba(0, 0, 0, 0) 100%
            );
    }
`;

const Title = styled.div`
    ${p => p.theme.typo.body6};
    background: #ffffff;
    background: linear-gradient(to bottom, #ffffff 66%, #f787dd 99%, #f237c7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const ItemImage = styled.div`
    width: 20px;
    max-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img`
    height: 100%;
`;

const checkAni = keyframes`
    0% {
        width: 200%;
    }
    100% {
        width: 40%;
    }
`;
const fadeInAni = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const Completed = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1px;
    transition: all 1s;
    opacity: 0;
    img {
        width: 200%;
        height: 100%;
        margin-top: 10px;
    }
    &.complete {
        animation: ${fadeInAni} 0.5s 0s linear normal;
        img {
            animation: ${checkAni} 0.5s 0s linear normal;
        }
        opacity: 1;
        img {
            width: 40%;
        }
    }
    &.completed {
        opacity: 1;
        img {
            width: 40%;
        }
    }
`;

const Desc = styled.div`
    ${p => p.theme.typo.body3};
    position: absolute;
    bottom: 0px;
`;

interface AttendanceItemProps {
    itemId: number;
    amount: number;
    status: "COMPLETED" | "TODAY" | "TODAYATTEND" | "NONE";
    idx: number;
    blank: boolean;
}

const AttendanceItem = ({ itemId, amount, status, idx, blank }: AttendanceItemProps) => {
    if (blank) {
        return (
            <Wrap>
                <Completed className={status == "TODAYATTEND" ? "complete" : "completed"}></Completed>
            </Wrap>
        );
    }
    return (
        <Wrap>
            <Title>{t("attendance:reward:day", idx)}</Title>
            <ItemImage>
                <Image src={`/img/reward/reward_${itemId}.png`} />
            </ItemImage>
            {status == "COMPLETED" || status == "TODAYATTEND" ? (
                <Completed className={status == "TODAYATTEND" ? "complete" : "completed"}>
                    <img src="/img/common/ic_check.svg" />
                </Completed>
            ) : (
                <></>
            )}
            <Desc>
                X {amount}
                {/* {t("reward:" + itemId)} */}
            </Desc>
        </Wrap>
    );
};

export default AttendanceItem;
