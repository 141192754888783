import { OnlineStoreNS as NS } from "CommonNamespace";
import { OSAction, DEL_WISHLIST, SET_WISHLIST, SET_PAYMENTLIST, MODIFY_WISHLIST } from "../action";

const initalState: NS.OnlinestoreState = {
    wishList: [],
    paymentList: [],
};

const onlinestore = (state = initalState, action: OSAction): NS.OnlinestoreState => {
    switch (action.type) {
        case MODIFY_WISHLIST:
            state.wishList[action.idx].count = action.count;
            return {
                ...state,
                wishList: [...state.wishList],
            };
        case SET_WISHLIST:
            state.wishList.push({ ...action.item, count: action.count, options: action.options });
            return {
                ...state,
                wishList: [...state.wishList],
            };
        case DEL_WISHLIST:
            const delTargetIdx = state.wishList.findIndex(r => r.itemId == action.itemId);
            if (delTargetIdx != -1) {
                state.wishList.splice(delTargetIdx, 1);
            }
            return {
                ...state,
                wishList: [...state.wishList],
            };
        case SET_PAYMENTLIST:
            return {
                ...state,
                paymentList: action.items,
            };
        default:
            return state;
    }
};

export default onlinestore;
