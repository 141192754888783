import { debugLog } from "../components/DebugLogging";
var posCB = null;
navigator.geolocation.getCurrentPosition = function (
    errorCb,
    cb,
    opt,
    x,
    y,
    alt,
    horizontalAccuracy,
    direction,
    directionAccuracy,
    s,
    at
) {
    posCB &&
        posCB({
            lng: this.x,
            lat: this.y,
            alt: this.alt,
            ha: this.horizontalAccuracy,
            d: this.direction,
            da: this.directionAccuracy,
            s: this.s,
            at: this.at,
        });
    if (cb && cb != null && cb != "null") posCB = cb;
    if (x) this.x = x;
    if (y) this.y = y;
    if (alt) this.alt = alt;
    if (horizontalAccuracy) this.horizontalAccuracy = horizontalAccuracy;
    if (direction) this.direction = direction;
    if (directionAccuracy) this.directionAccuracy = directionAccuracy;
    if (s !== undefined) this.s = s;
    if (at != undefined) this.at = at;
};

const initInstance = () => {
    if (window.native && window.native.instance == undefined) {
        window.native.instance = {
            event: null,
            buildVersion: 0,
            target: null,
            geolocations: [],
        };
    }
};
initInstance();

window.addBackgroundEventListener = e => {
    initInstance();
    window.native.instance.event = e;
    if (window.native.geolocations && window.native.geolocations.length > 0) {
        window.native.geolocations = [];
        e(window.native.geolocations);
    } else {
        window.native.geolocations = [];
    }
};

window.addLocationWhileBackground = geolocations => {
    // debugLog("addLocationWhileBackground :: " + geolocations.length);
    if (geolocations) {
        if (
            (window.native.instance.geolocations == undefined ||
                window.native.instance.geolocations.length != geolocations) &&
            window.native.instance.event
        ) {
            window.native.instance.geolocations = geolocations;
            window.native.instance.event(geolocations);
        }
    }
};

window.initializeVersion = (version, target, isLowPowerMode) => {
    initInstance();
    debugLog("INIT VERSION : " + version + "::" + target + "::" + isLowPowerMode);
    window.native.instance.buildVersion = version;
    window.native.instance.target = target;
    window.native.instance.isLowPowerMode = isLowPowerMode;
};

const CBInstance = {
    success: undefined,
    failed: undefined,
};
window.setPermissionGranted = granted => {
    initInstance();
    debugLog("GRANTED : " + granted);
    window.native.instance.granted = granted;
    if (granted && CBInstance.success) {
        CBInstance.success();
        CBInstance.success = undefined;
    } else if (!granted && CBInstance.failed) {
        CBInstance.failed();
        CBInstance.failed = undefined;
    }
};

window.addPermissionEventListener = (success, failed) => {
    CBInstance.success = success;
    CBInstance.failed = failed;
};

window.debugLog = str => {
    debugLog(str);
};

if (process.env.NODE_ENV == "development") {
    window.native.instance.buildVersion = 28;
    window.native.instance.target = "android";
    window.native.instance.granted = true;
    window.native.instance.isLowPowerMode = false;
}
