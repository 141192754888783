import { css, FlattenSimpleInterpolation } from "styled-components";
import { pxtovw375 } from "./utils/css";

const mobile = (styles: string, ...args: any[]) => {
    let style = styles;

    if (styles.length > 1) {
        style = "";

        for (let i = 0; i < styles.length; i++) {
            style += styles[i];

            if (args[i]) {
                style += args[i];
            }
        }
    }

    return css`
        @media only screen and (max-width: 720px) {
            ${style}
        }
    `;
};

class Typo {
    constructor(
        public fontSize: string = "14px",
        public lineHeight: string = "20px",
        public fontWeight: string = "normal"
    ) {}

    toCSS(): FlattenSimpleInterpolation {
        return css`
            font-size: ${this.fontSize};
            font-weight: ${this.fontWeight};
            line-height: ${this.lineHeight};
        `;
    }
}

const theme = {
    mobile,
    dimens: {
        tabHeight: 67,
    },
    zIndex: {
        appHeader: 100,
        dimmer: 5,
    },
    color: {
        // error: '#ff4627',
        // black: '#17171B',
        // white: '#FFFFFF',
        // gray: '#E5E7EB',
        // coral030: '#EB5757',
        // coral040: '#FF583B',
        // coral050: '#FF6557',
        // coral060: '#F3817B',
        // coral070: '#F9A4A0',
        // coral080: '#FFD3D6',
        // navy050: '#007EFF',
        // navy060: '#008DFF',
        // red050: '#F26175',
        // yellow050: '#FFD324',
        // yellow060: '#FFF028',
        // gray005: '#171B1C',
        // gray010: '#1E2427',
        // gray020: '#2E363A',
        // gray030: '#41474C',
        // gray040: '#5A6166',
        // gray050: '#999FA4',
        // gray060: '#C5C8CE',
        // gray070: '#F7F8F9',
        // gray080: '#FDFDFD',

        // 2022. 06.23 sac 프로젝트 추가( 아덴 )
        brandSecondary01: "#A4E9FE",
        brandSecondary02: "#F3ADFF",
        brandSecondary03: "#F5D371",
        bgDarken: "#000000",
        bgLight: "#212121",
        bgMoreLight: "#3A3A3A",
        textPrimary: "#FFFFFF",
        primary: "#f3adff",
        textSecondary: "#D9D9D9",
        textTertiary: "#9D9D9D",
        textAlert: "#E74C3C",
        iconsBg: "#2D2D2D",
        iconsStroke: "#3A3A3A",
        icons: "#555555",
        disabledText: "#8B8B8B",
        disabledTextBold: "#9D9D9D",
        textDisabled: "#4A4A4A",
        bgPopupDark: "#181818",
        borderLightGray: "#212121",
        gray2: "#212121",
        gray5: "#4A4A4A",
        gray6: "#555555",
        gray7: "#8B8B8B",
        gray8: "#9D9D9D",
        gray9: "#d9d9d9",
        gray10: "#BEBEBE",
    },

    typo: {
        h1: new Typo("40px", "48px", "600"),
        h2: new Typo("32px", "38px", "600"),
        h3: new Typo("28px", "33px", "600"),
        h4: new Typo("24px", "28px", "600"),
        h5: new Typo("20px", "24px", "600"),
        h6: new Typo("16px", "19px", "600"),
        h7: new Typo("14px", "16px", "600"),
        h8: new Typo("12px", "14px", "600"),
        h9: new Typo("16px", "20px", "600"),

        lead: new Typo("24px", "30px", "800"),
        body: new Typo("17px", "22px", "600"),
        body1: new Typo("16px", "24px", "400"),
        body2: new Typo("14px", "20px", "400"),
        body3: new Typo("12px", "20px", "400"),
        body4: new Typo("10px", "18px", "400"),
        button1: new Typo("14px", "16px", "800"),
        button2: new Typo("16px", "19px", "800"),

        lead2: new Typo("22px", "30px", "800"),
        lead3: new Typo("16px", "24px", "800"),
        lead4: new Typo("18px", "26px", "800"),

        leadsmall: new Typo("16px", "20px", "800"),
    },

    typoVW: {
        h1: new Typo(pxtovw375(40), pxtovw375(48), "600"),
        h2: new Typo(pxtovw375(32), pxtovw375(38), "600"),
        h3: new Typo(pxtovw375(28), pxtovw375(33), "600"),
        h4: new Typo(pxtovw375(24), pxtovw375(28), "600"),
        h5: new Typo(pxtovw375(20), pxtovw375(24), "600"),
        h6: new Typo(pxtovw375(16), pxtovw375(19), "600"),
        h7: new Typo(pxtovw375(14), pxtovw375(16), "600"),
        h8: new Typo(pxtovw375(12), pxtovw375(14), "600"),
        h9: new Typo(pxtovw375(16), pxtovw375(20), "600"),
        h10: new Typo(pxtovw375(18), pxtovw375(20), "600"),

        lead: new Typo(pxtovw375(14), pxtovw375(22), "800"),
        lead2: new Typo(pxtovw375(22), pxtovw375(24), "800"),
        lead3: new Typo(pxtovw375(16), pxtovw375(18), "800"),
        lead4: new Typo(pxtovw375(18), pxtovw375(22), "800"),
        leadsmall: new Typo(pxtovw375(12), pxtovw375(14), "800"),
        body0: new Typo(pxtovw375(22), pxtovw375(30), "400"),
        body1: new Typo(pxtovw375(16), pxtovw375(24), "400"),
        body2: new Typo(pxtovw375(14), pxtovw375(20), "400"),
        body3: new Typo(pxtovw375(12), pxtovw375(20), "400"),
        body4: new Typo(pxtovw375(10), pxtovw375(18), "400"),
        body5: new Typo(pxtovw375(10), pxtovw375(18), "400"),
        body6: new Typo(pxtovw375(14), pxtovw375(16), "400"),
        body7: new Typo(pxtovw375(18), pxtovw375(20), "400"),
        body8: new Typo(pxtovw375(12), pxtovw375(14), "400"),
        bodybig: new Typo(pxtovw375(24), pxtovw375(28), "400"),
        button1: new Typo(pxtovw375(14), pxtovw375(16), "800"),
        button2: new Typo(pxtovw375(16), pxtovw375(19), "800"),
        smallbutton: new Typo(pxtovw375(12), pxtovw375(14), "800"),
    },
};

export default theme;
