import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAccessToken, hasCredential, isExpiredToken, removeTokens, setAccessToken } from "./credential";

const API_HOST = process.env.REACT_APP_REST_API_HOST;

export class APIError extends Error {
    constructor(public code: number, public message: string, public payload: any) {
        super(message);
    }
}

type UnauthorizedHandler = () => void;
let unauthorizedHandler: UnauthorizedHandler | undefined;

export const setUnauthorizedHandler = (handler: UnauthorizedHandler) => {
    unauthorizedHandler = handler;
};

export const axiosInstance = axios.create({
    baseURL: API_HOST,
});

// Convert Content-Type if data is FormData
axiosInstance.interceptors.request.use(config => {
    if (config.data instanceof FormData) {
        config.headers["Content-Type"] = "multipart/form-data";
    }

    return config;
});

// Authorization Request Handler
axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    if (hasCredential()) {
        let accessToken = getAccessToken();
        // if (isExpiredToken(accessToken!)) {
        //     try {
        //         const refreshResponse = await axios.post<{
        //             payload: { accessToken: string; refreshToken: string };
        //         }>(API_HOST + "/user/refreshToken", { accessToken });

        //         if (refreshResponse?.data?.payload) {
        //             accessToken = refreshResponse.data.payload.accessToken;
        //             setAccessToken(accessToken);
        //         }
        //     } catch (e: any) {
        //         if (e.response?.status === 401) {
        //             unauthorizedHandler && unauthorizedHandler();
        //         } else {
        //             throw e;
        //         }
        //     }
        // }

        config.headers["Authorization"] = "Bearer " + accessToken;
    }

    return config;
});

// axiosInstance.interceptors.response.use(
//     (response: AxiosResponse) => {
//         const data = response.data;

//         if (!data.status) {
//             throw new APIError(data.code, data.message, data.payload);
//         }

//         return data.payload;
//     },
//     e => {
//         if (e.response) {
//             const statusCode = e.response.status;
//             const resultCode = e.response.data?.code;

//             if (statusCode === 401) {
//                 unauthorizedHandler && unauthorizedHandler();
//             }

//             if (resultCode) {
//                 const { message, payload } = e.response.data;
//                 throw new APIError(resultCode, message, payload);
//             }
//         }

//         throw e;
//     }
// );

export const requestConfig = (params?: any, config?: any): AxiosRequestConfig => {
    return {
        params,
        ...config,
    };
};

export const createFormData = (obj: object): FormData => {
    const formData = new FormData();

    for (let [key, value] of Object.entries(obj)) {
        formData.append(key, value);
    }

    return formData;
};

export const fetcher = <T>(url: string): Promise<void | T> => axiosInstance.get<T>(url).then(({ data }) => data);

export const UploadFile = async (url: string, file: any) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("token", getAccessToken());
    let res = await axiosInstance.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });

    if (res.data) {
        return { status: 1, data: res.data };
    }
    return { status: -1 };
};
