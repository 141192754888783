import React, { createContext, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import { produce } from "immer";
import { centerFixed } from "./utils/css";

const ModalContainer = styled(Modal)`
    max-width: 480px;
    ${centerFixed};
    margin: auto;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    > * {
        outline: none;
    }

    .MuiBackdrop-root {
        max-width: 480px;
        ${centerFixed};
    }
`;

const FADE_TIME = 300;

type ModalId = number;

export interface ModalFunctions {
    openModal: Function;
    openBlockedModal: Function;
    updateModal: Function;
    closeModal: Function;
}

const initialSetters: ModalFunctions = {
    openModal: new Function(),
    openBlockedModal: new Function(),
    updateModal: new Function(),
    closeModal: new Function(),
};

export const ModalContext = createContext(initialSetters);

interface ModalObject {
    id: ModalId;
    open: boolean;
    component: React.ComponentType<ModalComponentProps>;
    props: object;
    disableBackdropClick: boolean;
}

export interface ModalComponentProps {
    modalId: number;
    closeSelf?: Function;
}

const ModalProvider: React.FC = ({ children }) => {
    const $LastModalId = useRef<ModalId>(0);
    const [modals, setModals] = useState<ModalObject[]>([]);

    const setters: ModalFunctions = useMemo(() => {
        const openModal = (
            component: React.ComponentType<ModalComponentProps>,
            props: object = {},
            disableBackdropClick: boolean
        ) => {
            if (!component) {
                return;
            }

            $LastModalId.current++;

            const target: ModalObject = {
                id: $LastModalId.current,
                open: true,
                component,
                props,
                disableBackdropClick,
            };

            setModals(modals => [...modals.filter(x => x.open), target]);

            return $LastModalId.current;
        };

        const openBlockedModal = (component: React.ComponentType<ModalComponentProps>, props: object = {}) => {
            return openModal(component, props, true);
        };

        const updateModal = (modalId: ModalId, props: object, disableBackdropClick: boolean) => {
            setModals(
                produce(modals => {
                    if (modals.length === 0) {
                        return;
                    }

                    const idx: number = modals.findIndex(x => x.id === modalId);
                    if (idx === -1) {
                        return;
                    }

                    modals[idx].props = {
                        ...modals[idx].props,
                        ...props,
                        disableBackdropClick,
                    };
                })
            );

            return true;
        };

        const closeModal = (modalId: ModalId) => {
            setModals(
                produce(modals => {
                    if (modals.length === 0) {
                        return;
                    }
                    if (!modalId) {
                        modalId = modals.slice(-1)[0].id;
                    }

                    const idx: number = modals.findIndex(x => x.id === modalId);
                    if (idx === -1) {
                        return;
                    }

                    modals[idx] = { ...modals[idx], open: false };
                })
            );
        };

        return {
            openModal,
            openBlockedModal,
            updateModal,
            closeModal,
        };
    }, []);

    return (
        <ModalContext.Provider value={setters}>
            {children}
            {modals.map((x, i) => {
                const Component: React.ComponentType<ModalComponentProps> = x.component;

                return (
                    <ModalContainer
                        key={i}
                        open={x.open}
                        onClose={(event, reason) => {
                            if (!x.disableBackdropClick && reason === "backdropClick") {
                                return setters.closeModal(x.id);
                            }
                        }}
                        BackdropComponent={Backdrop}
                        BackdropProps={{ timeout: FADE_TIME }}
                        closeAfterTransition
                    >
                        <Fade in={x.open}>
                            <div>
                                {Component && (
                                    <Component modalId={x.id} {...x.props} closeSelf={() => setters.closeModal(x.id)} />
                                )}
                            </div>
                        </Fade>
                    </ModalContainer>
                );
            })}
        </ModalContext.Provider>
    );
};

export default ModalProvider;
