import styled from "styled-components";
import { useEffect, useState } from "react";

import { ModalNS as NS } from "CommonNamespace";
import { pxtovw375 } from "@/utils/css";

const Wrap = styled.div<{ position: NS.AlertPositionType; bottomMargin?: number }>`
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    position: fixed;
    display: flex;
    justify-content: center;
    left: 0;
    ${p =>
        p.position === "top"
            ? `top: ${window.safe.top+20}px`
            : p.position === "bottom"
            ? "bottom : 0"
            : p.position === "middle"
            ? "bottom : " + p.bottomMargin + "px"
            : ""};
    padding: 10px 16px 0;
    z-index: 10001;

    transition: all 0.25s;
    transition-property: transform, opacity;
    &.open_top {
        transform: translateY(0);
        opacity: 1;
    }
    &.close_top {
        transform: translateY(-100%);
        opacity: 0;
    }
    &.open_middle {
        transform: translateY(0);
        opacity: 1;
    }
    &.close_middle {
        transform: translateY(-200%);
        opacity: 0;
    }
    &.open_bottom {
        transform: translateY(0);
        opacity: 1;
    }
    &.close_bottom {
        transform: translateY(100%);
        opacity: 0;
    }
`;

const MessageTag = styled.div`
    width: 700px;
    max-width: 100%;
    height: 49px;

    color: white;
    white-space: break-spaces;
    text-align:center;
    
    position: relative;
    overflow: hidden;
    backdrop-filter: blur(2px);
    box-shadow: 0 0 20px rgba(0,0,0, 0.3);

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        height: 300px;

        background-image: conic-gradient(from 0turn, transparent 10%, #C70000, #C70000);
        animation: fallback-spin 6s linear infinite;
    }
    @keyframes fallback-spin {
        to {
            transform: scale(500%) rotate(1turn);
        }
    }
`;

const Inset = styled.div<{ isAlert?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    ${p => p.theme.typo.h7};
    color: ${p => (p.isAlert ? p.theme.color.textAlert : p.theme.color.bgDarken)};
    /* border-radius: 2px; */
    white-space: break-spaces;

    position: absolute;
    inset: 2px;
    overflow: hidden;
    background-color: rgba(255,255,255,1);
    color: black;
`

const AlertImg = styled.img`
    width: ${pxtovw375(12)};
    height: ${pxtovw375(12)};

    margin-right: ${pxtovw375(4)};
`;

// const anim = {
//     top: {
//         open: { opacity: 1, y: 0, transition: { duration: 0.25, ease: "easeOut" } },
//         close: { opacity: 0, y: "-100%", transition: { duration: 0.25, ease: "easeOut" } },
//     },
//     bottom: {
//         open: { opacity: 1, y: 0, transition: { duration: 0.25, ease: "easeOut" } },
//         close: { opacity: 0, y: "120%", transition: { duration: 0.25, ease: "easeOut" } },
//     },
//     middle: {
//         open: { opacity: 1, y: 0, transition: { duration: 0.25, ease: "easeOut" } },
//         close: { opacity: 0, y: "-200%", transition: { duration: 0.25, ease: "easeOut" } },
//     },
// };
const TopAlert = ({ isOpen, message, close, position, bottomMargin, duration, isAlert, textRed }: NS.AlertProps) => {
    const [isOpenAnim, setIsOpenAnim] = useState<boolean>(false);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setIsOpenAnim(true);
            }, 250);
        } else {
            setIsOpenAnim(false);
        }

        if (isOpen && duration !== 0) {
            setTimeout(
                () => {
                    setIsOpenAnim(false);
                    setTimeout(() => {
                        close();
                    }, 300);
                },
                duration ? duration * 1000 : 6000
            );
        }
    }, [isOpen]);

    return (
        <Wrap
            position={position}
            className={(isOpenAnim ? "open" : "close") + "_" + position}
            bottomMargin={bottomMargin}
        >
            <MessageTag>
                <Inset isAlert={textRed}>
                    {isAlert === true && <AlertImg src="/img/common/ic_alert_red.svg" />}
                    {message}
                </Inset>
            </MessageTag>
        </Wrap>
    );
};

export default TopAlert;
