/* eslint-disable */
//auto gen
import { DetailDataServerType, DetailPurchase, ListItemInfoServerType, PurchaseItem, PurchaserInfo, SimplePurchase } from "../dataset/"
import { client, SockClientOpt, OnRecvData } from "./index";
import { useEffect, useState, useLayoutEffect } from "react";

const hash = require('object-hash');
type StoreType = {
  [_: string]: { preparePurchaseId: string; }
}
type StoreFunc = (data: { preparePurchaseId: string; }) => void

let store: StoreType = {};
let subscribeEvent: { [_: string]: StoreFunc[] } = {}
export const request = function(token:string ,usePoint:number ,items:PurchaseItem[] ,purchaser_name:string ,purchaser_tel:string ,purchaser_email:string ,shipping_name:string ,shipping_address1:string ,shipping_address2:string ,shipping_phone:string ,shipping_memo:string ,billing_method:number ,opt?:SockClientOpt) : Promise<{ preparePurchaseId: string; }>{
  return new Promise((resolve, reject) => {
    client.emit("merchant:PreparePurchase", token,usePoint,items,purchaser_name,purchaser_tel,purchaser_email,shipping_name,shipping_address1,shipping_address2,shipping_phone,shipping_memo,billing_method, async function ack(data:any){
      resolve(await OnRecvData(data));

      let key = opt?.useKey ? hash([opt.useKey]) : hash([token,usePoint,items,purchaser_name,purchaser_tel,purchaser_email,shipping_name,shipping_address1,shipping_address2,shipping_phone,shipping_memo,billing_method]);
      if(opt?.useStore){
        store[key] = data;
      }

      if (subscribeEvent[key]) {
        for (let evt of subscribeEvent[key]) {
          evt(data);
        }
      }
    });
  })
}


function subscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  subscribeEvent[key].push(func)
}
function unsubscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  let idx = subscribeEvent[key].findIndex(e => e == func);
  if (idx >= 0) subscribeEvent[key].splice(idx, 1)
}
setTimeout(()=>{
  client.on("merchant:PreparePurchase", (data)=>{
    for(let key of Object.keys(subscribeEvent)){
      for(let evt of subscribeEvent[key]){
        evt(data);
      }
    }
  });
}, 100);

export default function (token:string ,usePoint:number ,items:PurchaseItem[] ,purchaser_name:string ,purchaser_tel:string ,purchaser_email:string ,shipping_name:string ,shipping_address1:string ,shipping_address2:string ,shipping_phone:string ,shipping_memo:string ,billing_method:number ,opt?:SockClientOpt) : [{ preparePurchaseId: string; } | null, ()=>Promise<{ preparePurchaseId: string; }>] {
  
  let key = opt?.useKey ? hash([opt.useKey]) : hash([token,usePoint,items,purchaser_name,purchaser_tel,purchaser_email,shipping_name,shipping_address1,shipping_address2,shipping_phone,shipping_memo,billing_method]);
  const [Data, setData] = useState<{ preparePurchaseId: string; } | null>(opt?.useStore ? (store[key]) : null);

  useLayoutEffect(() => {
    const handleChange = (data: { preparePurchaseId: string; })=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[token]);

  useEffect(() => {
    const handleChange = (data: { preparePurchaseId: string; })=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[token]);

  return [Data, () => request(token,usePoint,items,purchaser_name,purchaser_tel,purchaser_email,shipping_name,shipping_address1,shipping_address2,shipping_phone,shipping_memo,billing_method,opt)]

}
