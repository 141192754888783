import * as moment from "moment-timezone";
import _moment, { Moment } from "moment";
import { debuglog } from "util";

// if (moment && moment.tz) {
//     const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
//     debuglog("TIME ZONE! : " + timeZone);
//     moment.tz.setDefault(timeZone);
// }

export default _moment;
