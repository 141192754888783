import en from "./en.json";
import ko from "./ko.json";
import jp from "./jp.json";

let text_table: any = {
    en,
    ko,
    jp,
};

enum Language {
    En = "en",
    Jp = "jp",
    Ko = "ko",
}

let lang = "en";
export const translate = function (key: string, ...args: any[]) {
    let text = text_table[lang][key];
    if (text == null) {
        return `{${key}}`;
    }
    for (let ix in args) {
        let a = args[ix];
        text = text.replace(new RegExp("\\$" + ix, "g"), a);
    }
    return text;
};

export const setLanguage = function (ll: Language) {
    lang = ll.toString();
};

setLanguage(Language.Ko);
