import { DefaultResponse, User } from "./index";
import { ShoesType } from "./shoes";

export interface NowRunningType {
    userId: number;
    rId: number;
    elapsedTime: number;
    satToken: number;
    addedAt: string;
    earnExp: number;
    todayEarnExp: number;
    todaySpotEarnExp: number;
    exerciseType: number;
}

export interface Geolocation {
    lat: number;
    lng: number;
    alt: number;
    at: number;
    accu: number;
}

export interface RunningShoes {
    rId: number;
    shoesId: number;
    isRental: boolean;
    battery: number;
    equipIdx: number; // 1~4
    beforeBattery: number;
    tokenPer: number;
    ownerId: number;
    profitMiner: number;
    imgURL: string;
}

export interface InitRunningResponse extends DefaultResponse {
    runningShoes: RunningShoes[];
    rId: number;
}

export interface CheckRunningResponse extends DefaultResponse {
    runningShoes: RunningShoes[];
    rId: number;
    elapsedTime: number;
    prevCoords: Geolocation[];
    nowShoes: RunningShoes;
    satToken: number;
}

export interface FinishRunningResponse extends DefaultResponse {
    rId: number;
}

export interface UpdateRunningTimeResponse extends DefaultResponse {
    satToken: number;
    energy: number;
    nowShoes: RunningShoes;
}

export interface ConnectPartyResponse extends DefaultResponse {
    user?: User;
}

export interface SyncRunningResponse extends DefaultResponse {}

export interface GetRunningPartyUserResponse extends DefaultResponse {
    party: Party[];
    partyLog: EParty[];
}

export interface EParty {
    isJoin: boolean;
    at: number;
    userId: number;
}

export interface Party {
    rId: number;
    userId: number;
    profileImg: string;
    geo: Geolocation;
}

export interface Spot {
    id: number;
    lat: number;
    lng: number;
    spotType: number;
    entered?: boolean;
}

export interface GetRandomSpotResponse extends DefaultResponse {
    data: Spot[];
    canSpawn: boolean;
}

export interface UpdateRandomSpotResponse extends DefaultResponse {}

export interface PartyUser extends User {
    rId: number;
    rank?: number;
    earnSatToken?: number;
}

export interface FinishedDataType {
    addedAt: string;
    satToken: number;
    party: PartyUser[];
    isPartyPlay: boolean;
    userEnergy: number;
    userMaxEnergy: number;
    usedEnergy: number;
    earnExp: number;
    nowExp: number;
    nextLevelExp: number;
    prevLevelExp: number;
    enteredSpot: number;
    level: number;
    runningShoes: ShoesType[];
    myRank: number;
    elapsedTime: number;
    distance: number;
    minStr: string;
    km: number;
    steps: string;
    kph: number;
    kcal: number;
    prevCoords: Geolocation[];
    msg?: string;
    spot: Spot[];
    earnBox: number;
}

export const DEFAULT_FIN_DATA: FinishedDataType = {
    addedAt: "-",
    satToken: 0.0,
    party: [],
    isPartyPlay: false,
    userEnergy: 0,
    userMaxEnergy: 0,
    usedEnergy: 0,
    earnExp: 0,
    nowExp: 0,
    prevLevelExp: 0,
    nextLevelExp: 0,
    enteredSpot: 0,
    level: 1,
    runningShoes: [],
    myRank: 1,
    distance: 0,
    elapsedTime: 0,
    prevCoords: [],
    minStr: "00:00",
    km: 0,
    steps: "0",
    kph: 0,
    kcal: 0,
    msg: "",
    spot: [],
    earnBox: 0,
};

export interface GetFinishedRunningResponse extends DefaultResponse, FinishedDataType {}
