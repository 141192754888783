import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
//
import { translate as t } from "@/i18n";

const Wrap = styled.div`
    position: relative;
    margin-bottom: 40px;
    select {
        width: 100%;
        background-color: transparent;
        border: 1px solid #3a3a3a;
        border-radius: 8px;
        padding: 14px 16px;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        ${p => p.theme.typo.h6};
        color: ${p => p.theme.color.textPrimary};
    }
`;

const ImgArrow = styled.img`
    position: absolute;
    right: 21px;
    top: 21px;
`;

interface Props {
    value: number;
    onChange: (e: number) => void;
}

const EditorStature = ({ value, onChange }: Props) => {
    const [val, setVal] = useState<number>(0);

    useEffect(() => {
        setVal(value);
    }, [value]);

    const onChangeValue = useCallback(
        e => {
            setVal(parseInt(e.target.value));
            onChange(parseInt(e.target.value));
        },
        [val]
    );
    return (
        <Wrap>
            <select value={value} onChange={e => onChangeValue(e)}>
                <option value="130">130cm {t("mysac:setting:less")}</option>
                <option value="131">131cm</option>
                <option value="132">132cm</option>
                <option value="133">133cm</option>
                <option value="134">134cm</option>
                <option value="135">135cm</option>
                <option value="136">136cm</option>
                <option value="137">137cm</option>
                <option value="138">138cm</option>
                <option value="139">139cm</option>

                <option value="140">140cm</option>
                <option value="141">141cm</option>
                <option value="142">142cm</option>
                <option value="143">143cm</option>
                <option value="144">144cm</option>
                <option value="145">145cm</option>
                <option value="146">146cm</option>
                <option value="147">147cm</option>
                <option value="148">148cm</option>
                <option value="149">149cm</option>

                <option value="150">150cm</option>
                <option value="151">151cm</option>
                <option value="152">152cm</option>
                <option value="153">153cm</option>
                <option value="154">154cm</option>
                <option value="155">155cm</option>
                <option value="156">156cm</option>
                <option value="157">157cm</option>
                <option value="158">158cm</option>
                <option value="159">159cm</option>

                <option value="160">160cm</option>
                <option value="161">161cm</option>
                <option value="162">162cm</option>
                <option value="163">163cm</option>
                <option value="164">164cm</option>
                <option value="165">165cm</option>
                <option value="166">166cm</option>
                <option value="167">167cm</option>
                <option value="168">168cm</option>
                <option value="169">169cm</option>

                <option value="170">170cm</option>
                <option value="171">171cm</option>
                <option value="172">172cm</option>
                <option value="173">173cm</option>
                <option value="174">174cm</option>
                <option value="175">175cm</option>
                <option value="176">176cm</option>
                <option value="177">177cm</option>
                <option value="178">178cm</option>
                <option value="179">179cm</option>

                <option value="180">180cm</option>
                <option value="181">181cm</option>
                <option value="182">182cm</option>
                <option value="183">183cm</option>
                <option value="184">184cm</option>
                <option value="185">185cm</option>
                <option value="186">186cm</option>
                <option value="187">187cm</option>
                <option value="188">188cm</option>
                <option value="189">189cm</option>

                <option value="190">190cm</option>
                <option value="191">191cm</option>
                <option value="192">192cm</option>
                <option value="193">193cm</option>
                <option value="194">194cm</option>
                <option value="195">195cm</option>
                <option value="196">196cm</option>
                <option value="197">197cm</option>
                <option value="198">198cm</option>
                <option value="199">199cm</option>

                <option value="20">200cm {t("mysac:setting:more")}</option>
            </select>

            <ImgArrow src="/img/common/ic_arrow_white_bottom.svg" alt="" />
        </Wrap>
    );
};

export default EditorStature;
