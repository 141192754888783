import { combineReducers, CombinedState, Reducer } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//
import { ModalNS, OnlineStoreNS } from "CommonNamespace";
import { ModalAction, OSAction } from "../action";

import Modal from "./modal";
import Exercise from "./exercise";
import OnlineStore from "./onlinestore";

const rootReducer: Reducer<
    CombinedState<{
        Modal: ModalNS.ModalState;
        Exercise: ModalNS.ExerciseState;
        OnlineStore: OnlineStoreNS.OnlinestoreState;
    }>,
    ModalAction | OSAction
> = combineReducers({
    Modal: Modal,
    Exercise:Exercise,
    OnlineStore: OnlineStore,
});

const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: ["OnlineStore"],
};

export default persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
