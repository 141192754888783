export enum Tier {
    MYSTIC = "mystic",
    LEGENDARY = "legendary",
    EPIC = "epic",
    RARE = "rare",
    NORMAL = "normal",
}

export enum BadgeType {
    SMALL = "small",
    BIG = "big",
}

export enum Kind {
    WALKER = "walker",
    RUNNER = "runner",
    CYCLE = "cycle",
    ALLROUNDER = "allrounder",
}

export enum ButtonType {
    CANCEL = "cancel",
    CONFIRM = "confirm",
    DIMMED = "dimmed",
    GRAY = "gray",
}

export enum GageType {
    BATTERY = "battery",
    SAT = "sat",
}

export enum FilterType {
    ALL = "all",
    SEND = "send",
    RECEIPT = "receipt",
    NEW = "new",
    BATTERY = "battery",
    SAT = "sat",
    RARITY = "rarity",
    GRADE = "grade",
    NAME = "name",
    SHAREPERCENTAGE = "percentage",
    WEEK = "week",
    MONTH = "month",
    TOTAL = "total",
}

export enum StoreFilterType {
    RECOMMEND = "recommend",
    NEW = "new",
    SALES_VOLUME = "SALES_VOLUME",
    LOW = "low",
    HIGH = "high",
}

export enum MarketType {
    RENTAL = "rental",
    ENERGYDRINK = "energydrink",
    SHOP = "shop",
}

export enum ControlType {
    STOP = "stop",
    PAUSE = "pause",
    REPLAY = "replay",
    CLOSE = "close",
    SHOES = "shoes",
}

export enum InvenShoesType {
    NORMAL = "normal", // 렌탈 등록 가능
    RENTAL_REGISTRATION = "rentalRegistration", // 렌탈 가능
    RENTED = "rented", // 렌탈 중.
}

export enum QrType {
    MY = "my",
    SCAN = "scan",
}

export enum InvenTab {
    ALL = "all",
    SHOES = "shoes",
    REVEAL = "reveal",
}

export enum UpgradeType {
    COMPOSITE = "composite",
    LEVELUP = "levelup",
}

export enum TimeFilter {
    Week = "week",
    Month = "month",
    Year = "Year",
}

export enum NotificationType {
    ALL = "all",
    RENTAL = "rental",
    WALLET = "wallet",
    NOTICE = "notice",
}

export enum NotificationStatusType {
    NONE = "none",
    PROCESSING = "processing",
    COMPLETED = "completed",
    ERROR = "error",
}

export enum UpgradeGageType {
    SUCCESS_RATE = "successRate",
    DEGRADE_PROBABILITY = "degradeProbability",
}

export enum WalletFilter {
    SAT = "sat",
    SHOES = "shoes",
    REVEAL = "reveal",
}

export enum WalletFilterType {
    ALL = "all",
    DEPOSIT = "deposit",
    WITHDRAW = "withdraw",
    PAYMENT = "payment",
    FAILURE = "failure",
}

export enum QuestType {
    ALL = "questtype_all",
    WALKING = "questtype_walking",
    RUNNING = "questtype_running",
    CYCLE = "questtype_cycle",
    HIKING = "questtype_hiking",
}

export enum OnlineStoreType {
    ALL = "onlinestore_all",
    SPORTS = "onlinestore_sports",
    SETUP = "onlinestore_setup",
    CYCLE = "onlinestore_cycle",
    GOLF = "onlinestore_golf",

    OUTERS = "onlinestore_outers",
    SHIRTS = "onlinestore_shirts",
    PANTS = "onlinestore_pants",
    SHOES = "onlinestore_shoes",
    MEMBERSHIP = "onlinestore_membership",
}

export enum OnlineStoreDetailTabType {
    ITEM_DESC = "item_desc",
    DETAIL = "detail",
    FIT_SIZE = "fit_size",
    MATERIAL = "material",
    REVIEW = "review",
}

export interface GoogleSigninResponse {
    email: string;
    idToken: string;
}

export interface AppleSigninResponse {
    email: string;
    photoURL: string;
    idToken: string;
    uid: string;
    platform: string;
    authorizationCode: string;
}

declare global {
    interface Window {
        IMP: any;
        naver: any;
        safe: { top: number; bottom: number };
        isApp: () => boolean;
        waitNativeLoad: () => Promise<boolean>;
        addLocationWhileBackground: (geolocations: any[]) => void;
        addBackgroundEventListener: (evt: any) => void;
        setBackground: (flag: boolean) => void;
        addPermissionEventListener: (successEvt: () => void, failedEvt: () => void) => void;
        native: {
            getSafeArea: () => Promise<{ top: number; bottom: number }>; // ios only

            googleSignin: () => Promise<GoogleSigninResponse>;
            appleSignin: () => Promise<AppleSigninResponse>;
            admobRewardAd: () => Promise<{ result: string }>;
            getFCMToken: () => Promise<{ token: string }>;

            startExercise: () => Promise<void>;
            endExercise: () => Promise<void>;
            locationPerm: () => Promise<void>;

            iamportPay: (arg: any) => Promise<any>;

            backLocationPerm: () => void;
            getMaxExerciseId: () => number; // android
            getExerciseInfo: (eId: number) => string; // android

            startLocation: () => Promise<void>;
            stopLocation: () => Promise<void>;

            getMovFromGallery: () => Promise<{ png: string; mp4: string }>;

            callBackgroundData: () => Promise<void>;
            callLocationOnce: () => Promise<{ latitude: string; longitude: string; accuracy: string }>;
            shareImageData: (arg: { dataURL: string; title: string }) => void;
            sendNoti: (arg: { title: string; desc: string; type: string }) => Promise<void>;
            checkNoticePerm: () => Promise<{ granted: string }>;
            noticePerm: () => Promise<void>;
            checkPowerSaveMode: () => Promise<{ isPowerSaveMode: boolean }>;
            registEstimate: (arg: {
                code?: string;
                shoes?: string;
                party?: string;
                sEvt?: string;
                userEnergy?: string;
            }) => Promise<void>;
            instance: {
                event: any;
                buildVersion: number;
                target: "ios" | "android" | null;
                granted: boolean;
                geolocations: any[];
                isLowPowerMode : boolean;
            };
        };
    }
}
window.isApp = function (): boolean {
    return window.navigator.userAgent.indexOf("SACApp") >= 0;
};

window.waitNativeLoad = function (): Promise<boolean> {
    return new Promise(async resolve => {
        if (window.isApp() == false) return resolve(false);
        while (!window.native || !window.native.getSafeArea) {
            await new Promise(r => setTimeout(r, 200));
        }

        resolve(true);
    });
};
window.addLocationWhileBackground = window.addLocationWhileBackground || function () {};
window.addBackgroundEventListener = window.addBackgroundEventListener || function () {};
window.setBackground = window.setBackground || function () {};
window.native = window.native || {};
window.native.instance = window.native.instance || {};
window.native.instance.buildVersion = window.native.instance.buildVersion || 0;
window.native.instance.target = window.native.instance.target || null;
window.native.instance.granted = window.native.instance.granted || false;
window.native.startExercise = window.native.startExercise || function () {};
window.native.endExercise = window.native.endExercise || function () {};
window.native.startLocation = window.native.startLocation || function () {};

window.safe = {
    top: 10,
    bottom: 0,
};
window.waitNativeLoad().then(e => {
    if (e) {
        window.native.getSafeArea().then(e => {
            window.safe.top = e.top;
            window.safe.bottom = e.bottom;
        });
    }
});
