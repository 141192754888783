import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
//
import { zIndex } from "@/utils/css";
const BtnShadow = styled.div<{ zIndexNum?: number }>`
    position: absolute;
    z-index: ${p => (p.zIndexNum ? p.zIndexNum : zIndex.boxes)};
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

interface Props {
    onClick: () => void | Promise<void>;
    zIndexNum?: number;
    widthVal?: number;
    heightVal?: number;
    topVal?: number;
    leftVal?: number;
    disabled?: boolean;
    attributes?: any;
    isMapped?: boolean; // if isMapped, children refresh need.
    isColor?: string;
}

const BtnWrapper: React.FC<Props> = ({
    onClick,
    children,
    zIndexNum,
    widthVal,
    heightVal,
    topVal,
    leftVal,
    disabled,
    attributes,
    isMapped,
    isColor,
}) => {
    const childRefs = useRef(null);
    const shadowRefs = useRef(null);

    useEffect(() => {
        if (isMapped && children && shadowRefs.current) {
            let target: HTMLElement = shadowRefs.current;
            target.setAttribute("aria-width", "0");
        }
    }, [children]);

    useEffect(() => {
        setTimeout(() => {
            if (childRefs.current && shadowRefs.current) {
                let c: HTMLElement = childRefs.current;
                let target: HTMLElement = shadowRefs.current;

                let width = c.offsetWidth == 0 ? c.clientWidth : c.offsetWidth;
                const preWidth = target.getAttribute("aria-width");
                if (!preWidth || preWidth.indexOf(width.toString()) == -1) {
                    let height = c.offsetHeight == 0 ? c.clientHeight : c.offsetHeight;
                    if (c.children && c.children.length > 0) {
                        if (width == 0) {
                            width = c.children[0].clientWidth;
                        }
                        if (height == 0) {
                            height = c.children[0].clientHeight;
                        }
                    }
                    const moreWidth = widthVal ? widthVal : 10;
                    const moreHeight = heightVal ? heightVal : 10;
                    const moreTop = topVal ? topVal : 5;
                    const moreLeft = leftVal ? leftVal : 5;
                    target.setAttribute(
                        "style",
                        `width:${width + moreWidth}px;height:${height + moreHeight}px;
                            top:${c.offsetTop - moreTop}px;left:${c.offsetLeft - moreLeft}px;`
                    );
                    target.setAttribute("aria-width", width.toString());
                    // target.addEventListener("mousedown", () => {
                    //     c.style.opacity = "0.6";
                    //     c.style.transition = "0.1s all";
                    // });
                    // target.addEventListener("mouseleave", () => {
                    //     c.style.opacity = "1";
                    //     c.style.transition = "";
                    // });
                    // target.addEventListener("mouseup", () => {
                    //     c.style.opacity = "1";
                    //     c.style.transition = "";
                    // });
                    // mobile
                    target.addEventListener("touchstart", () => {
                        c.style.opacity = "0.6";
                        c.style.transition = "0.1s all";
                        if (isColor) {
                            c.style.backgroundColor = isColor;
                        }
                    });
                    target.addEventListener("touchend", () => {
                        c.style.opacity = "1";
                        c.style.transition = "";
                        if (isColor) {
                            c.style.backgroundColor = "transparent";
                        }
                    });
                    target.addEventListener("touchcancel", () => {
                        c.style.opacity = "1";
                        c.style.transition = "";
                        if (isColor) {
                            c.style.backgroundColor = "transparent";
                        }
                    });
                }
            }
        }, 500);
    }, [children]);

    if (React.Children.toArray(children).length == 0) return <></>;
    if (disabled) return <>{children}</>;
    return (
        <>
            <BtnShadow {...attributes} ref={shadowRefs} onClick={onClick} zIndexNum={zIndexNum} />
            {React.Children.toArray(children).map((c: any, index) =>
                React.cloneElement(c, { ref: childRefs, key: index })
            )}
        </>
    );
};

export default BtnWrapper;
