import React, { useLayoutEffect, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
//
import {
    requestHandshake,
    requestGetInventory,
    requestGetEnergyDrinks,
    requestGetRentalType,
    requestGetShoesMeta,
    requestGetAttendance,
    Logout,
} from "@/api";
import { getAccessToken, setUserId } from "@/utils/credential";
import { HandshakeResponse } from "@/dataset";
import { ProfileDatas } from "@/pages/Profile/data";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
);

class AppWrapperData {
    static lastMetaAt: number = 0;
}

const AppWrapper: React.FC = () => {
    const location = useLocation();
    const [handshake, setHandshake] = useState<HandshakeResponse>();

    useLayoutEffect(() => {
        // Handshake
        if (AppWrapperData.lastMetaAt < Date.now() - 1000 * 5) {
            requestHandshake(getAccessToken(), { useStore: true }).then(raw => {
                AppWrapperData.lastMetaAt = Date.now();
                setHandshake(raw);
                if (raw.status == 1) {
                    setUserId(raw.userId);
                    requestGetAttendance(getAccessToken(), { useStore: true });
                    ProfileDatas.init();
                }
            });
            // Meta data
            requestGetEnergyDrinks({ useStore: true });
            requestGetRentalType({ useStore: true });
            requestGetShoesMeta({ useStore: true });
            requestGetInventory(getAccessToken(), { useStore: true });
        }
        // for mobile
        setTimeout(() => {
            window.scrollTo(0, -5000);
        }, 500);
    }, [location.pathname]);

    useEffect(() => {
        if (getAccessToken() && handshake && handshake.status == -2 && handshake.userId == -1) {
            Logout();
        }
    }, [handshake]);

    return <></>;
};

export default AppWrapper;
