import React, { useState, useCallback } from "react";
import styled from "styled-components";
///
import { pxtovw375 } from "@/utils/css";
import { translate as t } from "@/i18n";
import PopupConfirm from "@/components/Common/PopupConfirm";
import BtnWrapper from "../Common/BtnWrapper";

const Wrap = styled.button`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #181818;
    border-radius: 8px;
    padding: ${pxtovw375(16)} 0;
    &:active {
        .price-text {
            background: #212121;
        }
    }
`;

const ImgBx = styled.div`
    object {
        width: ${pxtovw375(48)};
        height: ${pxtovw375(80)};
    }
    img {
        width: ${pxtovw375(48)};
        height: ${pxtovw375(80)};
    }
`;

const Info = styled.dl`
    display: flex;
    gap: ${pxtovw375(4)};
    margin-top: ${pxtovw375(4)};
    dt {
        ${p => p.theme.typoVW.h7};
        color: ${p => p.theme.color.textTertiary};
    }

    dd {
        ${p => p.theme.typoVW.h7};
        color: ${p => p.theme.color.textPrimary};
    }
`;

const Price = styled.div`
    ${p => p.theme.typoVW.h7};
    color: ${p => p.theme.color.textPrimary};
    background: #2d2d2d;
    border-radius: 100px;
    border: 1px solid #3a3a3a;
    padding: ${pxtovw375(10)} ${pxtovw375(30)};
    margin-top: ${pxtovw375(16)};
`;

interface Props {
    buyType: number;
    onBuy: ($buyType: number) => void;
    title: string;
    description: string;
    price: number;
    style?: React.CSSProperties;
}

const EnergyItem: React.FC<Props> = ({ buyType, onBuy, title, description, price, style }) => {
    const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);

    const onClickBuy = useCallback(() => {
        setIsOpenPopup(true);
    }, []);

    const onClickCancel = useCallback(() => {
        setIsOpenPopup(false);
    }, []);

    const onClickConfirm = async () => {
        await onBuy(buyType);
        setIsOpenPopup(false);
        return;
    }

    return (
        <>
            <BtnWrapper onClick={onClickBuy}>
                <Wrap style={style}>
                    <ImgBx>
                        {/* <object
                        data={"/img/img_energy_" + title.toLocaleLowerCase().replace(/ /gi, "") + ".png"}
                        type="image/svg+xml"
                    /> */}
                        <img src={"/img/img_energy_" + title.toLocaleLowerCase().replace(/ /gi, "") + ".png"} />
                    </ImgBx>

                    <Info>
                        <dt>{title}</dt>
                        <dd>{description}</dd>
                    </Info>

                    <Price className="price-text">{price} SAT</Price>
                </Wrap>
            </BtnWrapper>
            <PopupConfirm
                title={t("confirm:buyenergy:title")}
                subText={t("confirm:buyenergy:desc", title)}
                descText={t("confirm:buyenergy:desc2")}
                cancelText={t("confirm:buyenergy:cancel")}
                confirmText={t("confirm:buyenergy:confirm")}
                isOpen={isOpenPopup}
                onCancel={onClickCancel}
                onConfirm={onClickConfirm}
            />
        </>
    );
};

export default React.memo(EnergyItem);
