import styled from "styled-components";

import { pxtovw375, BottomTabHeight, zIndex } from "@/utils/css";

export const Section = styled.div`
    padding-bottom: 50px;
`;

export const Wrapper = styled.div`
    padding: 116px ${pxtovw375(16)} ${BottomTabHeight};
    box-sizing: border-box;
`;

export const Inner = styled.div`
    position: relative;
`;

export const Title = styled.h2`
    margin-top: 12px;
    margin-bottom: 8px;
    ${p => p.theme.typo.h4};
    color: ${p => p.theme.color.textPrimary};
    width: 100%;
    padding: 0 ${pxtovw375(16)};
`;

export const TopSticky = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-color: #000;
    z-index: 10000;
    position: sticky; 
    top: 116px;
    overflow: hidden;
    background-color: transparent;
`;

export const TabBx = styled.div`
    padding : 14px 0;
    background-color: #000000;
    width : 100%;
    transition: transform 0.25s;
`;

export const SubTab = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    padding-bottom: 10px;
`;

export const SubCounter = styled.div`
    font-size: ${pxtovw375(12)};
    font-weight: 400;
    line-height: 20px;
    color: ${p => p.theme.color.textSecondary};
    display: flex;
    gap: 6px;
`;

export const InvenContainer = styled.div`
    margin-top: ${pxtovw375(6)};
`;

export const NoNFTScreen = styled.div`
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const NoNFTTxt = styled.div`
    color: ${p => p.theme.color.textTertiary};
    ${p => p.theme.typoVW.body7};
    padding-bottom: ${pxtovw375(24)};
`;

export const NoNFTBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${pxtovw375(14)} ${pxtovw375(17)};
    ${p => p.theme.typoVW.h6};
    color: #000;
    border-radius: 8px;
    background: #fff;
    &:active {
        background: ${p => p.theme.color.textTertiary};
    }
`;

export const TokenContainer = styled.div`
    margin-top: ${pxtovw375(24)};
`;

export const ItemListContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: ${pxtovw375(11)};
    row-gap: ${pxtovw375(40)};
`;

export const LoginBx = styled.div`
    position: fixed;
    bottom: 72px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LoginBxMessage = styled.button`
    ${p => p.theme.typo.body2};
    color: ${p => p.theme.color.textPrimary};
    background: #2d2d2d;
    border: 1px solid #3a3a3a;
    border-radius: 100px;
    padding: 8px 24px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
`;

export const Message = styled.dl`
    text-align: center;
    margin-bottom: ${pxtovw375(40)};
    dt {
        ${p => p.theme.typoVW.h5};
        color: ${p => p.theme.color.textPrimary};
        margin-bottom: ${pxtovw375(16)};
    }

    dd {
        ${p => p.theme.typoVW.body1};
        color: ${p => p.theme.color.textPrimary};
    }
`;
