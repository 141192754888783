import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
//
import { useSocketHandshake } from "@/api";
import { getAccessToken } from "@/utils/credential";
import { pxtovw375 } from "@/utils/css";

import BtnWrapper from "@/components/Common/BtnWrapper";

const Wrapper = styled.div<{isOpen:boolean}>`
    position: fixed;
    top: 0;
    width: 100%;
    /* background-color: white; */
    z-index: 5000;
    transform: translateY(${p=>p.isOpen ? "0px":"-200px"});
`;

const Inner = styled.div`
    padding: 9px 22px 9px 20px;
    box-sizing: border-box;
    /* background-color: #000000; */
    padding-top: ${p=>window.safe.top}px;
    backdrop-filter: blur(4px);
    background: rgba(255,255,255,0.6);
    /* height: 68px; */
    /* border-bottom: 1px solid black; */
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const UserBx = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const CoinBx = styled.div<{ isShow: boolean }>`
    display: ${p => (p.isShow ? "block" : "none")};
    padding: 7px 10px;
    background-color: ${p => p.theme.color.iconsBg};
    border-radius: 32px;
    font-size: 0;
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        li {
            a {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                img {
                    background-color: ${p => p.theme.color.bgDarken};
                    border-radius: 100%;
                    width: 18px;
                    height: 18px;
                    margin-right: 6px;
                }

                span {
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 600;
                    color: ${p => p.theme.color.textSecondary};
                }
            }
        }
    }
`;

const Profile = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 0;
    margin-right: 16px;
    a {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        border: 1px solid #fff;
        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
        }
    }
`;

const QR = styled.div`
    width: 32px;
    height: 32px;
    a {
        img {
            width: 32px;
            height: 32px;
        }
    }
`;

const SAT = styled.div`
    height: 32px;
    display: flex;
    align-items: center;
        background-color:#2D2D2D;
    padding: 0 8px;
    border-radius: 7px;
    img {
        width: 22px;
        height: 22px;
    }
    div{
        margin-left: 8px;
        color: white;
        font-weight: bold;
    }
`;

const EtcBx = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
`;

const Quest = styled.div<{ isShow: boolean }>`
    display: ${p => (p.isShow ? "flex" : "none")};
    width: 32px;
    height: 32px;
    justify-content: flex-end;
    align-items: center;
    a {
        position: relative;
        width: 100%;
        height: 100%;
        background-color:#2D2D2D;
        border-radius: 5px;
        padding: 3px;
        img {
            width: 100%;
            height: 90%;
            /* filter: invert(); */
        }
        .noti {
            position: absolute;
            right: -4px;
            top: -1px;
            width: 10px;
            height: 10px;
            background-color: #f93f3d;
            border-radius: 50%;
        }
    }
`;

const Wallet = styled.div<{ isShow: boolean }>`
    display: ${p => (p.isShow ? "flex" : "none")};
    width: 30px;
    height: 30px;
    justify-content: flex-end;
    align-items: center;
    a {
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            /* height: 100%; */
        }
    }
`;

interface HeaderProps {
    showQuest?: boolean;
    canClaimQuest?: boolean;
    showCoin?: boolean;
    rootStyle?: React.CSSProperties;
    sat?:string;
    isOpen?:boolean;
}

const Header: React.FC<HeaderProps> = ({ sat="0", isOpen=true, showQuest, canClaimQuest, showCoin, rootStyle, children }) => {
    const [user, _] = useSocketHandshake(getAccessToken(), { useStore: true });
    const [workingWallet, setWorkingWallet] = useState<boolean>(true);
    const history = useHistory();

    useEffect(() => {
        if(window.native.instance && window.native.instance.target == "ios"){
            setWorkingWallet(false);
        }
    }, []);

    const onClickCoinBx = useCallback(() => {
        if (workingWallet) {
            history.push("/wallet");
        }
    }, [workingWallet]);
    const onClickQuest = useCallback(() => {
        history.push("/quest");
    }, []);
    const onClickOnlineStore = useCallback(() => {
        history.push("/onlinestore", { from: "/home" });
    }, []);
    return (
        <Wrapper style={rootStyle} isOpen={isOpen}>
            <Inner>
                <Container>
                    <UserBx>
                        <Profile>
                            <BtnWrapper onClick={() => history.push("/profile")}>
                                <a>
                                    <img
                                        src={
                                            user && user.profileImg
                                                ? user.profileImg
                                                : "/img/common/ic_header_profile.svg"
                                        }
                                        alt=""
                                    />
                                </a>
                            </BtnWrapper>
                        </Profile>
                    </UserBx>
                    <EtcBx>
                        <SAT>
                            <img src="/img/common/ic_sat.svg" alt="" />
                            <div>{sat}</div>
                        </SAT>
                        {/* <QR>
                            <BtnWrapper onClick={() => history.push("/homeqr")}>
                                <a>
                                    <img src="/img/common/ic_header_qr.svg" alt="" />
                                </a>
                            </BtnWrapper>
                        </QR> */}
                        <Quest isShow={showQuest == true}>
                            <BtnWrapper onClick={() => onClickQuest()}>
                                <a>
                                    <img src="/img/common/ic_quest.svg" />
                                    {showQuest == true && canClaimQuest == true && <div className="noti" />}
                                </a>
                            </BtnWrapper>
                        </Quest>
                        <CoinBx isShow={showCoin == true} onClick={() => onClickOnlineStore()}>
                            <ul>
                                <li>
                                    <a>
                                        <img src="/img/common/ic_sat_low.png" alt="" />
                                        <span>{user ? user.satTokenStr : 0}</span>
                                    </a>
                                </li>
                            </ul>
                        </CoinBx>
                    </EtcBx>
                </Container>
            </Inner>
            {children}
        </Wrapper>
    );
};

export default Header;
