import styled from "styled-components";

import { pxtovw375, zIndex } from "@/utils/css";
import { InvenShoesType } from "@/types";

export const Wrap = styled.div<{ shown: boolean }>`
    position: relative;
    width: ${pxtovw375(165)};
    transition: all 0.4s ease-out;
    height: ${p => (p.shown ? "initial" : pxtovw375(166))};
    transform: ${p => (p.shown ? "translateY(0) scale(1)" : "translateY(10px) scale(1.02)")};
    opacity: ${p => (p.shown ? 1 : 0)};
`;

export const BoxImage = styled.div<{ state?: string }>`
    position: relative;
    width: ${pxtovw375(166)};
    height: ${pxtovw375(166)};
    display: flex;
    justify-content: center;
    align-items: center;
    background: #212121;
    border-radius: 8px;
    margin-bottom: ${pxtovw375(16)};
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        background-color: #000;
        border: 1px solid #212121;
        border-radius: 8px;
    }
`;

export const BoxInfo = styled.div<{ state?: string }>`
    opacity: ${p => (p.state === InvenShoesType.RENTAL_REGISTRATION ? 0.5 : 1)};
`;
export const InfoBx = styled.ul`
    width: 100%;
    li {
        display: flex;
        gap: ${pxtovw375(4)};
        ${p => p.theme.typoVW.body2}
        color: ${p => p.theme.color.gray9};
    }
    .price {
        ${p => p.theme.typoVW.body2}
        color : #fff;
    }
`;

export const ShoesState = styled.div<{ state: string }>`
    position: absolute;
    left: ${pxtovw375(6)};
    top: ${pxtovw375(6)};
    ${p => p.theme.typo.body4};
    color: ${p => p.theme.color.textPrimary};
    padding: ${pxtovw375(3)} ${pxtovw375(6)};
    border-radius: ${pxtovw375(100)};

    font-weight: 600;
    font-size: ${pxtovw375(12)};
    display: flex;
    align-items: center;
`;

export const LinkButton = styled.div``;

export const CheckIconWrapper = styled.div`
    display: none;
    position: absolute;
    z-index: ${zIndex.boxes};
    right: ${pxtovw375(10)};
    top: ${pxtovw375(10)};
`;

export const WrapBlack = styled.div`
    display: none;
    position: absolute;
    z-index: ${zIndex.normal};
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    border-radius: 8px;
`;
