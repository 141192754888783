import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore } from "redux-persist";
//

import rootReducer from "./reducer";

const enhancer =
    process.env.NODE_ENV === "development" ? composeWithDevTools(applyMiddleware()) : compose(applyMiddleware());

// 위에서 만든 reducer를 스토어 만들때 넣어줍니다
const store = createStore(rootReducer, enhancer);

export default store;

export const persistor = persistStore(store);