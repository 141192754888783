import { css } from "styled-components";

export const BottomTabHeight = "88px";

export const fitToMaxWidth = css`
    width: 100%;
`;

export const centerFixed = css`
    ${fitToMaxWidth};
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
`;

export const statusBarHeight = (add: string = "") => {
    const h = "var(--status-bar-height, 0px)";
    return add ? `calc(${h} + ${add})` : h;
};

export const bottomBarHeight = (add: string = "") => {
    const h = "var(--bottom-bar-height, 0px)";
    return add ? `calc(${h} + ${add})` : h;
};

export const pxtovw375 = ($value: number, $w: number = 375): string => {
    return ($value / $w) * 100 + "vw";
};
export const px375 = ($value: number): string => {
    return $value + "px";
};

export const pxtopercent375 = ($value: number, $w: number = 375): string => {
    return ($value / $w) * 100 + "%";
};

export const zIndex = {
    normal: 1001,
    boxes: 1002,
    badge: 1003,
    sticky: 1004,
    layout: 5001,
};
