import { ModalNS as NS } from "CommonNamespace";
import { ExerciseAction, SET_EXERCISE_STATUS } from "../action";

const initalState: NS.ExerciseState = {
    exercise:false
};

const exercise = (state = initalState, action: ExerciseAction) => {
    switch (action.type) {
        case SET_EXERCISE_STATUS:
            return {
                ...state,
                exercise: action.exercise,
            };
        default:
            return state;
    }
};

export default exercise;
