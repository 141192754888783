import { DefaultResponse } from "./index";
import { DefaultInvenType } from "./inventory";

export enum RENTAL_TYPE {
    SUPER_HARD = 1,
    HARD = 2,
    MEDIUM = 3,
    EASY = 4,
}

export interface ShoesType extends DefaultInvenType {
    discriminator: "shoestype";
    // id: number; //1
    type: string; // ALLROUNDER
    grade: number; // 1
    rarity: string; // Mystic
    rarityId: number; // 1
    // name: string; // H3-QW
    hash: string; //#123465890
    battery: number; // 40000 ( 50% )
    batteryStr: string; // 40,000
    fullBattery: number; // 80000
    price: number; // 3.23
    url: string; // /marketdetail/${id}
    imgURL: string;
    marketImgURL: string;
    equipIdx: number;
    userId: number;
    isRental?: number; // 1 rental 0 no rental
    rentalType: RENTAL_TYPE; // RentalType
    meta_rentalName: string; // Super Hard
    meta_lockupDays: number; //90
    meta_profitMiner: number; // 40
    meta_profitHolder: number;
    meta_burningRate: number;
    lockupDate: string; // 0000-00-00 00:00
    isLockup: boolean; //
    batteryPrice: number;
    replaceDateCount: number;
}

export function instanceOfShoesType(object: any): object is ShoesType {
    return object.discriminator === "shoestype";
}

export interface RentalListResponse extends DefaultResponse {
    list: ShoesType[];
    count: number;
}

export interface SelectShoesResponse extends DefaultResponse {}

export interface GetSelectedShoesResponse extends DefaultResponse {
    shoes: ShoesType[];
}

export interface ReplaceBatteryResponse extends DefaultResponse {}

export interface RentShoesReponse extends DefaultResponse {}

export interface RentalHistory {
    Date: string; // 221105 14:20-17:12
    satToken: number; // 20
    Miner: string; // K1293 username
}

export interface RentalHistoryResponse extends DefaultResponse {
    data: RentalHistory[];
}

export interface UpgradeInfo {
    id: number;
    upgradeSuccessRate: number;
    degradeProbability: number;
    gradeId: number;
    price: number;
    rarity: string;
    nowBattery: number;
    upgradeBattery: number;
    donwgradeBattery: number;
}

export interface GetUpgradeInfoResponse extends DefaultResponse {
    data: UpgradeInfo;
    protector?: number;
    booster?: number;
    boosterPer ?: number;
}

export interface UpgradeShoesResponse extends DefaultResponse {
    historyId: number;
}

export interface UpgradeHistory {
    shoesId: number;
    result: number;
    prevGrade: number;
    nowGrade: number;
    rarity: string;
    addedAt: string;
}
export interface GetUpgradeHistoryResponse extends DefaultResponse {
    data: UpgradeHistory;
}

export interface SynthesisHistory {
    shoesA: number;
    shoesB: number;
    rarity: number;
    addedAt: string;
    grade: number;
    name: string;
    hash: string;
    rarityStr: string;
    marketImgURL: string;
}

export interface GetSynthesisResponse extends DefaultResponse {
    price: number;
}

export interface SynthesisResponse extends DefaultResponse {
    historyId: number;
}

export interface GetSynthesisHistoryResponse extends DefaultResponse {
    data: SynthesisHistory;
}

export interface GetRentalShoesByIdResponse extends DefaultResponse {
    data: ShoesType;
}
