import styled, { keyframes } from "styled-components";
import { pxtovw375 } from "@/utils/css";

export const Wrap = styled.div`
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    position: fixed;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    transform: translateZ(0px);
`;

export const Inner = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 100px;
    padding: 0 0 100px 0;
`;

export const Title = styled.div`
    ${p => p.theme.typo.h4};
    transition: all 0.5s;
    opacity: 0;
    margin-bottom: 20px;
    &.open {
        opacity: 1;
    }
`;

export const Board = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: 10px;
    row-gap: 0px;
    column-gap: 0px;
    transition: all 0.5s;
    transition-delay: 0.5s;
    opacity: 0;
    &.open {
        opacity: 1;
    }
`;

const blink = keyframes` /* 2. css코드를 씀. */
0% {
opacity: 0;
}
50% {
opacity: 1;
}
100% {
opacity: 0;
}
`;
export const Desc = styled.div`
    ${p => p.theme.typo.h5};
    margin-top: 30px;
    animation: ${blink} 2s linear infinite;
    opacity: 0;
`;
