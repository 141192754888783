import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useInView } from "react-intersection-observer";
//
import { RevealBoxType } from "@/dataset/revealbox";
import { numCommas } from "@/utils";

import BadgeText from "@/components/Common/BadgeText";
import BtnWrapper from "../BtnWrapper";

import * as S from "./styled";

interface Props {
    info: RevealBoxType;
    selected?: boolean;
    onClick?: (id: number) => void;
    soldOut?: boolean;
}

const RevealItem = ({ info, onClick, selected, soldOut }: Props) => {
    const history = useHistory();
    const { ref, inView } = useInView({ threshold: 0 });

    const onClickButton = useCallback(() => {
        if (onClick) {
            onClick(info.id);
        } else {
            history.push("/reveal?id=" + info.id);
        }
    }, []);

    return (
        <S.Wrap ref={ref} shown={inView}>
            <S.CheckIconWrapper style={selected ? { display: "block" } : {}}>
                <BadgeText content={"check"} />
            </S.CheckIconWrapper>
            <BtnWrapper onClick={onClickButton}>
                <S.LinkButton>
                    <S.BoxImage>
                        <S.WrapBlack style={selected || soldOut ? { display: "block" } : {}} />
                        <LazyLoadImage src={info.marketImgURL} alt="" effect="opacity" />
                    </S.BoxImage>

                    <S.BoxInfo style={soldOut ? { opacity: 0.6 } : {}}>
                        <S.InfoBx>
                            <li>
                                {info.name} #{info.hash}
                            </li>
                            {info.price && <div className="price">{numCommas(info.price)} SAT</div>}
                        </S.InfoBx>
                    </S.BoxInfo>
                </S.LinkButton>
            </BtnWrapper>
        </S.Wrap>
    );
};

export default RevealItem;
