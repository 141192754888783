import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
//
import { requestDebugLogging } from "@/api";
import { getUserId } from "@/utils/credential";
//
const Section = styled.div``;
const Logging = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 999999999;
    div {
        white-space: pre;
    }
`;

const OpenCloseBtn = styled.div`
    position: fixed;
    right: 0;
    top: ${p=>window.safe.top}px;
    width: 30px;
    height: 30px;
    z-index: 999999999999;
    background-color: rgba(255, 255, 255, 0.1);
`;

const debugData: {
    logging: string[];
    subEvent: Array<(f: string[]) => void>;
    postIndex: number;
} = {
    logging: [],
    subEvent: [],
    postIndex: 0,
};

const postDebugLog = async () => {
    try {
        const userId = getUserId();
        if (userId == 0) throw "user id is null";
        const res = await requestDebugLogging(userId, debugData.logging.slice(debugData.postIndex).join("\n"));
        if (res.status == 1) {
            debugData.postIndex = debugData.logging.length;
        } else {
            throw res.msg;
        }
    } catch (err) {
        console.log("POST DEBUG LOG ERRROR : ", err);
    }
};

function subscribe(func: (f: string[]) => void) {
    debugData.subEvent.push(func);
}
function unsubscribe(func: (f: string[]) => void) {
    let idx = debugData.subEvent.findIndex(e => e == func);
    if (idx >= 0) debugData.subEvent.splice(idx, 1);
}
const useDebugLogging = () => {
    let [data, setData] = useState<string[]>(debugData.logging);

    useEffect(() => {
        const handleChange = (f: string[]) => setData(f);

        subscribe(handleChange);
        return () => unsubscribe(handleChange);
    }, []);

    return [data];
};

export const debugLog = async function (str: string) {
    debugData.logging.push(`[${moment().format("HH:mm:ss")}]` + str);
    if (process.env.NODE_ENV == "development") {
        console.log(str);
    }
    if (debugData.logging.length % 10 == 0) {
        postDebugLog();
    }
    for (let evt of debugData.subEvent) {
        evt([...debugData.logging]);
    }
};

const DebugLogging = () => {
    let [logging] = useDebugLogging();
    let [toggle, setToggle] = useState(false);

    let openclose = useCallback(
        function () {
            setToggle(!toggle);
        },
        [toggle]
    );

    return (
        <Section>
            {toggle ? (
                <Logging>
                    {logging.map((e, i) => {
                        return <div key={i}>{e}</div>;
                    })}
                </Logging>
            ) : null}
            <OpenCloseBtn onClick={openclose} />
        </Section>
    );
};

export default DebugLogging;
