/* eslint-disable */
//auto gen
import {
    FeedInfo,
    FeedUserInfo,
    FeedProductInfo,
    GetUserFeedsResponse,
    FollowUser,
    LikeFeedResponse,
    JoinFeedResponse,
    GetShortsResponse,
    GetFeedByIdResponse,
    GetRecruitsResponse,
    RemoveFeedResponse,
} from "../dataset/"
import { client, SockClientOpt, OnRecvData } from "./index";
import { useEffect, useState, useLayoutEffect } from "react";

const hash = require('object-hash');
type StoreType = {
  [_: string]: LikeFeedResponse
}
type StoreFunc = (data: LikeFeedResponse) => void

let store: StoreType = {};
let subscribeEvent: { [_: string]: StoreFunc[] } = {}
export const request = function(token:string ,feedId:number ,opt?:SockClientOpt) : Promise<LikeFeedResponse>{
  return new Promise((resolve, reject) => {
    client.emit("feed:LikeFeed", token,feedId, async function ack(data:any){
      resolve(await OnRecvData(data));

      let key = opt?.useKey ? hash([opt.useKey]) : hash([token,feedId]);
      if(opt?.useStore){
        store[key] = data;
      }

      if (subscribeEvent[key]) {
        for (let evt of subscribeEvent[key]) {
          evt(data);
        }
      }
    });
  })
}


function subscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  subscribeEvent[key].push(func)
}
function unsubscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  let idx = subscribeEvent[key].findIndex(e => e == func);
  if (idx >= 0) subscribeEvent[key].splice(idx, 1)
}
setTimeout(()=>{
  client.on("feed:LikeFeed", (data)=>{
    for(let key of Object.keys(subscribeEvent)){
      for(let evt of subscribeEvent[key]){
        evt(data);
      }
    }
  });
}, 100);

export default function (token:string ,feedId:number ,opt?:SockClientOpt) : [LikeFeedResponse | null, ()=>Promise<LikeFeedResponse>] {
  
  let key = opt?.useKey ? hash([opt.useKey]) : hash([token,feedId]);
  const [Data, setData] = useState<LikeFeedResponse | null>(opt?.useStore ? (store[key]) : null);

  useLayoutEffect(() => {
    const handleChange = (data: LikeFeedResponse)=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[token]);

  useEffect(() => {
    const handleChange = (data: LikeFeedResponse)=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[token]);

  return [Data, () => request(token,feedId,opt)]

}
