import styled from "styled-components";
import { useEffect, useState } from "react";
import { pxtovw375 } from "@/utils/css";

const Wrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${pxtovw375(32)};
    height: ${pxtovw375(32)};
    border-radius: 50%;
    background-color: white;
`;

const Inner = styled.div`
    width: 90%;
    height: 90%;
    background-color: #2d2d2d;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 66%;
        height: 66%;
    }
    div {
        ${p => p.theme.typoVW.h9};
    }
`;

interface Props {
    content: "check" | number | string;
}

const BadgeIcon = ({ content }: Props) => {
    const getContent = () => {
        switch (content) {
            case "check":
                return <img src="/img/common/ic_check.svg" alt="check_icon" />;
            default:
                return <div>{content}</div>;
        }
    };
    return (
        <Wrap>
            <Inner>{getContent()}</Inner>
        </Wrap>
    );
};

export default BadgeIcon;
