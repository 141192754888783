import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
//
import useQueryString from "@/hooks/useQueryString";
import {
    requestGetRentalShoesList,
    useSocketHandshake,
    useSocketGetEnergyDrinks,
    requestBuyEnergyDrink,
    requestGetInventory,
    useSocketGetShopInfo,
    requestBuyShopItem,
} from "@/api";
import { SetModal } from "@/common/action";
import { MarketType, FilterType } from "@/types";
import { translate as t } from "@/i18n";
import { MARKET_DROPDOWN } from "@/utils/constant";
import { getAccessToken } from "@/utils/credential";
import sleep from "@/utils/sleep";

import { ShoesType } from "@/dataset/shoes";
//
import Loading from "@/components/Common/Loading";
import Header from "@/components/Common/Header";
import EnergyItem from "@/components/Market/EnergyItem";
import TabMenu from "@/components/Common/TabMenu";
import ShoesList from "@/components/Common/ShoesList";
import EnergyBar from "@/components/Market/EnergyBar";
import Dropdown from "@/components/Common/Dropdown";
import RevealItem from "@/components/Common/RevealItem";
import PopupConfirm from "@/components/Common/PopupConfirm";
import ShopItem from "@/components/Common/ShopItem";
//
import * as S from "./styled";
import TopBtn from "@/components/Common/TopBtn";
import { MarketDatas } from "./data";

const Market = () => {
    const dispatch = useDispatch();
    const qs = useQueryString();
    const history = useHistory();
    const [selectedSort, setSelectedSort] = useState<FilterType>(FilterType.NEW);
    const [marketType, setMarketType] = useState<string>(MarketType.RENTAL);
    const [rentalShoesDatas, setRentalShoesDatas] = useState<ShoesType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isOpenShopItemPopup, setIsOpenShopItemPopup] = useState<boolean>(false);
    const [shopItemId, setShopItemId] = useState<number>(-1);
    // api
    const [user, requestHandshake] = useSocketHandshake(getAccessToken(), { useStore: true });
    const [energyDrinks, _requestGetEnergyDrinks] = useSocketGetEnergyDrinks({ useStore: true });
    const [shopData, requestGetShopInfo] = useSocketGetShopInfo(getAccessToken(), { useStore: true });

    const [scrollDir, setScrollDir] = useState<number>(1);

    useEffect(() => {
        MarketDatas.init();
        setIsLoading(true);
        requestGetShopInfo();
        onRentalShoes();
        if (qs("tab") === "energy") {
            setMarketType(MarketType.ENERGYDRINK);
        }

        MarketDatas.lastScrollY = window.pageYOffset;
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    useEffect(() => {
        if (MarketDatas.RentalSort != selectedSort) {
            MarketDatas.RentalShoesList = [];
            MarketDatas.RentalIdx = 0;
            MarketDatas.RentalSort = selectedSort;
            onRentalShoes();
        }
    }, [selectedSort]);

    useEffect(() => {
        if (shopData && shopData.status == 1) {
            MarketDatas.setShopInfo(shopData.items);
        }
    }, [shopData]);

    const updateScrollDir = () => {
        const scrollY = window.pageYOffset;

        if (Math.abs(scrollY - MarketDatas.lastScrollY) < MarketDatas.threshold) {
            MarketDatas.ticking = false;
            return;
        }
        setScrollDir(scrollY > MarketDatas.lastScrollY ? -1 : 1);
        MarketDatas.lastScrollY = scrollY > 0 ? scrollY : 0;
        MarketDatas.ticking = false;
    };

    const onScroll = (e: any) => {
        if (!MarketDatas.ticking) {
            window.requestAnimationFrame(updateScrollDir);
            MarketDatas.ticking = true;
        }
        checkScrollBtm(e);
    };

    const checkScrollBtm = (e: any) => {
        const bottom =
            e.target.scrollingElement.scrollHeight - e.target.scrollingElement.scrollTop <
            e.target.scrollingElement.clientHeight + 20;

        if (bottom && MarketDatas.RentalShoesList.length == MarketDatas.RentalIdx) {
            if (MarketDatas.RentalCount != 0 && MarketDatas.RentalCount == MarketDatas.RentalShoesList.length) {
                // is Max bottom
                return;
            }
            MarketDatas.RentalIdx += 10;
            setIsLoading(true);
            onRentalShoes();
        }
    };

    const onRentalShoes = useCallback(() => {
        requestGetRentalShoesList(getAccessToken(), MarketDatas.RentalIdx, MarketDatas.RentalSort).then(res => {
            if (res.status == 1) {
                MarketDatas.setRentalShoesList(res.list);
                MarketDatas.RentalCount = res.count;
                setRentalShoesDatas([...MarketDatas.RentalShoesList]);
            }
            setIsLoading(false);
        });
    }, []);

    const onTab = useCallback(
        ($tabType: string) => {
            setMarketType($tabType);
            if ($tabType == MarketType.RENTAL) {
                window.addEventListener("scroll", onScroll);
            } else {
                window.removeEventListener("scroll", onScroll);
            }
        },
        [marketType]
    );

    const onBuy = useCallback(
        async ($buyType: number) => {
            let msg = "";
            let isAlert = false;
            if (energyDrinks) {
                const eDrink = energyDrinks.data.find(r => r.id == $buyType);
                if (eDrink) {
                    let response = await requestBuyEnergyDrink(getAccessToken(), $buyType);
                    if (response.status === 1) {
                        await requestHandshake();
                        msg = t("alert:buyenergycomplete", eDrink.energy);
                    } else {
                        if (response.status == -2) {
                            msg = t("alert:buyenergyfailure:energy");
                            isAlert = false;
                        } else {
                            msg = t("alert:buyenergyfailure:token");
                            isAlert = true;
                        }
                    }
                } else {
                    msg = t("alert:buyenergyfailure:token");
                    isAlert = true;
                }
            } else {
                msg = t("alert:buyenergyfailure:token");
                isAlert = true;
            }

            if (msg.length > 0) dispatch(SetModal(msg, "top", 5, isAlert));
        },
        [energyDrinks]
    );

    const selectShopItem = useCallback((shopId: number) => {
        setIsOpenShopItemPopup(true);
        setShopItemId(shopId);
    }, []);

    const onBuyShopItem = useCallback(async () => {
        try {
            if (shopItemId == -1) throw "shopItemId is none";

            setIsLoading(true);
            const res = await requestBuyShopItem(getAccessToken(), shopItemId, 1);
            await sleep(1);
            setIsLoading(false);
            if (res.status == 1) {
                if (shopItemId == 0) {
                    await history.push("/reveal?id=" + res.id);
                } else {
                    await dispatch(SetModal(t("alert:shopitem:buysuccess"), "top", 5, true));
                }
                setIsOpenShopItemPopup(false);
            } else {
                let msg = t("alert:unknowerror");
                if (res.status == -2) {
                    msg = t("alert:insufficient");
                }
                throw { status: res.status, msg: msg };
            }
        } catch (err: any) {
            let msg = t("alert:unknowerror");
            if (err.msg) {
                msg = err.msg;
            }
            await dispatch(SetModal(msg, "top", 5, true));
        }
    }, [shopItemId]);

    const getTabIdx = useCallback(() => {
        if (marketType == MarketType.ENERGYDRINK) {
            return 1;
        } else if (marketType == MarketType.RENTAL) {
            return 0;
        } else {
            return 2;
        }
    }, [marketType]);

    const data = useMemo(() => {
        const nowItem = MarketDatas.ShopInfo ? MarketDatas.ShopInfo.find(r => r.shopId == shopItemId) : undefined;
        return {
            confirmDesc: nowItem ? nowItem.title : "Shop Item",
        };
    }, [shopItemId]);

    return (
        <S.Section>
            <Header showCoin>
                <S.Title>{t("common:market")}</S.Title>
            </Header>
            <S.TopSticky>
                <S.TabBx style={scrollDir == 1 ? { transform: "translateY(0%)" } : { transform: "translateY(-100%)" }}>
                    <TabMenu
                        list={[
                            { title: t("common:onlyrental"), tabType: MarketType.RENTAL },
                            { title: t("common:energydrink"), tabType: MarketType.ENERGYDRINK },
                            { title: t("common:shop"), tabType: MarketType.SHOP },
                        ]}
                        onTab={onTab}
                        defaultTab={getTabIdx()}
                        rootStyle={{ width: "100%" }}
                    />
                </S.TabBx>
            </S.TopSticky>
            <S.Wrapper>
                <S.Inner>
                    {isLoading && <Loading time={100} />}
                    {marketType === MarketType.RENTAL && (
                        <S.SubTab>
                            <S.SubCounter>
                                All <p>{MarketDatas.RentalCount}</p>
                            </S.SubCounter>
                            <Dropdown
                                selected={selectedSort}
                                onClickOption={(option: any) => setSelectedSort(option)}
                                options={MARKET_DROPDOWN}
                            />
                        </S.SubTab>
                    )}

                    {marketType === MarketType.RENTAL ? (
                        <S.ShoesContainer>
                            {rentalShoesDatas.length === 0 ? (
                                <S.BlaknContainer>{t("market:noshoes")}</S.BlaknContainer>
                            ) : (
                                <ShoesList selectMaxCount={0} isRentalPage list={rentalShoesDatas} />
                            )}
                        </S.ShoesContainer>
                    ) : marketType === MarketType.ENERGYDRINK ? (
                        <S.BatteryItemContainer>
                            <EnergyBar energy={user ? user.energy : 0} max={user ? user.maxEnergy : 0} min={0} />
                            {energyDrinks &&
                                energyDrinks.data &&
                                energyDrinks.data.map(($drink, idx) => (
                                    <EnergyItem
                                        buyType={$drink.id}
                                        onBuy={onBuy}
                                        title={$drink.text}
                                        description={`E${$drink.energy}`}
                                        price={$drink.price}
                                        style={{ width: "48%" }}
                                    />
                                ))}
                        </S.BatteryItemContainer>
                    ) : (
                        <S.ShopContainer>
                            {MarketDatas.ShopInfo &&
                                MarketDatas.ShopInfo.map((info, idx) => (
                                    <ShopItem
                                        key={"ShopInfo" + idx}
                                        info={info}
                                        onClick={selectShopItem}
                                        soldOut={!info.enable}
                                    />
                                ))}
                            <PopupConfirm
                                title={t("confirm:buyshopitem:title")}
                                descText={t("shopitem:" + data.confirmDesc)}
                                subText=""
                                cancelText={t("confirm:buyshopitem:cancel")}
                                confirmText={t("confirm:buyshopitem:confirm")}
                                isOpen={isOpenShopItemPopup}
                                onCancel={() => setIsOpenShopItemPopup(false)}
                                onConfirm={onBuyShopItem}
                            />
                        </S.ShopContainer>
                    )}
                    <TopBtn />
                </S.Inner>
            </S.Wrapper>
        </S.Section>
    );
};

export default Market;
