import { FilterType, StoreFilterType } from "@/types";
import { translate as t } from "@/i18n";

export const ACTIVITY_DATE_FORMAT = "YYYY-MM-DD";
export const RESEND_SMS_WAIT_TIME = 60;
export const SELECT_MY_SHOES_COUNT = 3;
export const REFRESH_QRCODE_TIME = 1000 * 60 * 3;

export const MARKET_DROPDOWN = [
    { title: "sort:newest", filterType: FilterType.NEW },
    { title: "sort:percentage", filterType: FilterType.SHAREPERCENTAGE },
    { title: "sort:battery", filterType: FilterType.BATTERY },
    { title: "sort:rarity", filterType: FilterType.RARITY },
    { title: "sort:grade", filterType: FilterType.GRADE },
];

export const INVENTORY_DROPDOWN = [
    { title: "sort:newest", filterType: FilterType.NEW },
    { title: "sort:battery", filterType: FilterType.BATTERY },
    { title: "sort:rarity", filterType: FilterType.RARITY },
    { title: "sort:grade", filterType: FilterType.GRADE },
];

export const REVEAL_DROPDOWN = [
    { title: "sort:newest", filterType: FilterType.NAME },
    { title: "sort:newest", filterType: FilterType.NEW },
];

export const DATE_DROPDOWN = [
    { title: "sort:Week", filterType: FilterType.WEEK },
    { title: "sort:Month", filterType: FilterType.MONTH },
    { title: "sort:Total", filterType: FilterType.TOTAL },
];

export const ONLINE_STORE_DROPDOWN = [
    { title: "sort:store:recommend", filterType: StoreFilterType.RECOMMEND },
    { title: "sort:store:new", filterType: StoreFilterType.NEW },
    { title: "sort:store:salesvolume", filterType: StoreFilterType.SALES_VOLUME },
    { title: "sort:store:low", filterType: StoreFilterType.LOW },
    { title: "sort:store:high", filterType: StoreFilterType.HIGH },
];

export const PRELOAD_IMAGES = [
    "/img/common/ic_sat.svg",
    "/img/bg_mystic.png",
    "/img/common/ic_sat.png",
    "/img/common/ic_stop.svg",
    "/img/common/ic_pause.svg",
    "/img/common/ic_play_arrow.svg",
    "/img/common/ic_close_big.svg",
    "/img/img_energy_extralarge.png",
    "/img/img_energy_small.png",
    "/img/img_energy_large.png",
    "/img/img_energy_extralarge.png",
    "/img/common/ic_header_profile.svg",
    "/img/common/ic_header_qr.svg",
    "/img/common/ic_account_balance_wallet.svg",
    "/img/common/ic_sat_low.svg",
    "/img/bg_start_btn.png",
    "/img/common/ic_electric.svg",
    "/img/img_badge_bg_rare.svg",
    "/img/img_badge_bg_epic.svg",
    "/img/img_badge_bg_legendary.svg",
    "/img/img_badge_bg_mystic.svg",
    "/img/img_badge_bg_normal.svg",
];

export const PRELOAD_VIDEO = [
    "https://cdn.sac-nft.com/video/Reveal_Idle.mp4",
    "https://cdn.sac-nft.com/video/Reveal.mp4",
];

export const AWAIT_PRELOAD_VIDEO = ["https://cdn.sac-nft.com/video/Onboarding_s.mp4"];

export const IS_SHOW_FINISH_TOKEN_KEY = "IS_SHOW_FINISH_TOKEN_KEY_";

export const GOOGLE_PLAY_STORE_LINK = "https://bit.ly/3NcvO3Q";
export const APP_STORE_LINK = "https://bit.ly/3HenTiI";

export const UPDATE_HTML = (link: string) => `
<style>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
        "Malgun Gothic", sans-serif;
    -webkit-tap-highlight-color: transparent;
    color: white;
    letter-spacing: -0.02em;
    line-height: normal;
    user-select: none;
    -webkit-user-drag: none;
}

.main {
    width: 100vw;
    height: 100vh;
    background: url("/img/bg.png")
        no-repeat center center black;
    background-size: 80%;
    z-index: -1;
}
.back {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    gap: 10px;
    font-size: 16px;
}
.btn-connect {
    display: inline-flex;
    width: 160px;
    height: 48px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    font-size : 18px;
    font-weight: 700;
    color : #000;
    overflow: hidden;
    position: relative;
    transition: transform 0.2s ease-out;
    transform: translate3d(0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top : 12px;
    text-decoration: none;
}
.btn-connect:active{
    transform: scale(0.95) translate3d(0, 0, 0);
}
@keyframes BtnStartBgAni{
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(320px);
    }
}
.btn-connect>img {
    width: 480px;
    height: 48px;
    position: absolute;
    left: -320px;
    top: 0;
    animation-name: BtnStartBgAni;
    animation-duration: 3s;
    animation-direction: inherit;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.btn-connect>span {
    position: relative;
    z-index: 10;
    color: #000000;
}
a:active {
    transition: all .5s;
    opacity: 0.5;
}
.title { 
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 10px;
}
</style>

<div class="main">
<div class="back">
    <span class="title">${t("appupdate:title")}</span>
    <span></span>
    <a class="btn-connect" target="_blank" href="${link}">
        <span>${t("appupdate:btn:update")}</span>
        <img src="/img/bg_start_btn.png" alt="" />    
    </a>
</div>
</div>
`;

export const ENCRYPT_KEY = "sac-private-key";
