import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useInView } from "react-intersection-observer";
//
import { RENTAL_TYPE, ShoesType } from "@/dataset/shoes";
import { translate as t } from "@/i18n";
import { getLeftTime, getTwoDigits } from "@/utils";
import BadgeText from "@/components/Common/BadgeText";
import BtnWrapper from "../BtnWrapper";
import { getUTCDate } from "@/utils/dateutil";

import * as S from "./styled";

interface Props {
    isRentalPage?: boolean;
    onClick?: (id: number) => void;
    url: string;
    info: ShoesType;
    selected?: boolean;
    selectedIndex?: number;
}

class ShoesItemData {
    shoesState: string = "";
    setState = (s: string) => {
        this.shoesState = s;
    };
    shoesIcon: string = "";
    setShoesIcon = (icon: string) => {
        this.shoesIcon = icon;
    };
    int: NodeJS.Timeout | null = null;
    setInt = (timeout: NodeJS.Timeout) => {
        this.int = timeout;
    };
    id: number = 0;
    setId = (id: number) => {
        this.id = id;
    };
}

const ShoesItem: React.FC<Props> = ({ url, info, onClick, selected, selectedIndex, isRentalPage }) => {
    const history = useHistory();
    const { ref, inView } = useInView({ threshold: 0 });

    const [showItem, setShowItem] = useState<boolean>(false);
    const [data, setData] = useState<ShoesItemData>(new ShoesItemData());

    useEffect(() => {
        if (inView && !showItem) {
            setShowItem(true);
        }
    }, [inView]);

    useEffect(() => {
        if (info && info.isLockup) {
            data.setId(info.id);
            if (isRentalPage !== true) {
                const lockupDate = getUTCDate(info.lockupDate).toDate();
                //set icon
                data.setShoesIcon(getStateIcon());
                //

                let left = getLeftTime(new Date(), lockupDate);
                if (left.days > 0) {
                    // days
                    data.setState(`-${left.days} days`);
                } else {
                    let int = setInterval(() => {
                        let left = getLeftTime(new Date(), lockupDate);
                        data.setState(
                            `-${getTwoDigits(left.hour)}:${getTwoDigits(left.minutes)}:${getTwoDigits(left.seconds)}`
                        );
                    }, 1000);
                    data.setInt(int);
                }
            } else {
                // share to miner percentage
                data.setState(info.meta_profitMiner + "%");
            }
            setData(data);
        }
        return () => {
            if (data && data.int) {
                clearInterval(data.int);
            }
        };
    }, [info]);

    const onClickButton = () => {
        if (onClick !== undefined) {
            onClick(info.id);
        } else {
            history.push(url);
        }
    };

    const getStateIcon = useCallback(() => {
        switch (info.rentalType) {
            case RENTAL_TYPE.SUPER_HARD:
                return "S";
            case RENTAL_TYPE.HARD:
                return "H";
            case RENTAL_TYPE.MEDIUM:
                return "M";
            case RENTAL_TYPE.EASY:
                return "E";
            default:
                return "";
        }
    }, [info]);

    const getShoesState = () => {
        if (isRentalPage) {
            if (data.shoesState.length === 0) {
                return <></>;
            }
            return (
                <S.ShoesState state={data.shoesState}>
                    <S.ShoesStateText>{data.shoesState}</S.ShoesStateText>
                </S.ShoesState>
            );
        } else if (info.isLockup) {
            return (
                <S.ShoesState state={data.shoesState}>
                    <S.ShoesStateText>
                        <S.ShoesStateIcon>{data.shoesIcon}</S.ShoesStateIcon> {data.shoesState}
                    </S.ShoesStateText>
                </S.ShoesState>
            );
        }
    };
    const isLow = info.battery == 0;
    return (
        <S.Wrap ref={ref} shown={showItem}>
            {showItem ? (
                <BtnWrapper onClick={onClickButton}>
                    <S.LinkButton>
                        <S.CheckIconWrapper style={selected ? { display: "block" } : {}}>
                            <BadgeText content={selectedIndex !== undefined ? selectedIndex + 1 : "check"} />
                        </S.CheckIconWrapper>
                        <S.ShoesImage state={info.type}>
                            <S.WrapBlack style={selected ? { display: "block" } : {}} />
                            <S.ShoesImageBack isLow={isLow} />
                            {getShoesState()}
                            <LazyLoadImage src={info.marketImgURL} alt="" effect="opacity" />
                            {/* <img src={info.marketImgURL} alt="" /> */}
                        </S.ShoesImage>

                        <S.ShoesInfo state={info.type}>
                            <S.Badge>
                                {/* <BadgeTier tier={info.rarity.toLowerCase()} type={BadgeType.SMALL}></BadgeTier>
                        <BadgeLevel level={info.grade} type={BadgeType.SMALL}></BadgeLevel> */}
                                <span>{info.rarity}</span>
                                <div />
                                <span>G{info.grade}</span>
                                {/*<BadgeKind kind={kind} type={ BadgeType.SMALL }></BadgeKind>*/}
                            </S.Badge>
                            <S.InfoBx isLow={isLow}>
                                <li>
                                    {info.name} #{info.hash}
                                </li>
                                <li>
                                    {t("common:battery")} <p>{info.battery}</p>
                                </li>
                            </S.InfoBx>
                        </S.ShoesInfo>
                    </S.LinkButton>
                </BtnWrapper>
            ) : (
                <></>
            )}
        </S.Wrap>
    );
};

export default ShoesItem;
