//auto gen
import { io } from "socket.io-client";
import { useEffect, useState } from "react";

// export all server api for preventing duplicate api name, etc
export { default as useSocketGetActivityChartData } from "./activity_GetActivityChartData"
export { default as useSocketGetRecentActivity } from "./activity_GetRecentActivity"
export { default as useSocketSetActivityImage } from "./activity_SetActivityImage"
export { default as useSocketGetAttendance } from "./event_GetAttendance"
export { default as useSocketTodayAttendance } from "./event_TodayAttendance"
export { default as useSocketFindProduct } from "./feed_FindProduct"
export { default as useSocketWriteFeeds } from "./feed_WriteFeeds"
export { default as useSocketGetUserFeeds } from "./feed_GetUserFeeds"
export { default as useSocketGetFeedById } from "./feed_GetFeedById"
export { default as useSocketFollowUser } from "./feed_FollowUser"
export { default as useSocketLikeFeed } from "./feed_LikeFeed"
export { default as useSocketJoinFeed } from "./feed_JoinFeed"
export { default as useSocketGetShorts } from "./feed_GetShorts"
export { default as useSocketGetRecruits } from "./feed_GetRecruits"
export { default as useSocketRemoveFeed } from "./feed_RemoveFeed"
export { default as useSocketGetInventory } from "./inventory_GetInventory"
export { default as useSocketOpenRevealBox } from "./inventory_OpenRevealBox"
export { default as useSocketBuyEnergyDrink } from "./item_BuyEnergyDrink"
export { default as useSocketBuyEnergyDrinkRunning } from "./item_BuyEnergyDrinkRunning"
export { default as useSocketGetShopInfo } from "./item_GetShopInfo"
export { default as useSocketBuyShopItem } from "./item_BuyShopItem"
export { default as useSocketBuyRevealBox } from "./item_BuyRevealBox"
export { default as useSocketGetItemMeta } from "./item_GetItemMeta"
export { default as useSocketOpenItemBox } from "./item_OpenItemBox"
export { default as useSocketGetLeaderboard } from "./leaderboard_GetLeaderboard"
export { default as useSocketPolicyHtml } from "./main_PolicyHtml"
export { default as useSocketServerTime } from "./main_ServerTime"
export { default as useSocketVersion } from "./main_Version"
export { default as useSocketDispatchModal } from "./main_DispatchModal"
export { default as useSocketDebugLogging } from "./main_DebugLogging"
export { default as useSocketTap } from "./main_Tap"
export { default as useSocketGetMember } from "./member_GetMember"
export { default as useSocketAddMember } from "./member_AddMember"
export { default as useSocketRemoveMember } from "./member_RemoveMember"
export { default as useSocketGetProductAll } from "./merchant_getProductAll"
export { default as useSocketGetProductDetail } from "./merchant_getProductDetail"
export { default as useSocketGetMerchantUid } from "./merchant_GetMerchantUid"
export { default as useSocketPreparePurchase } from "./merchant_PreparePurchase"
export { default as useSocketValidImpId } from "./merchant_validImpId"
export { default as useSocketPastUsedPurchaseInfo } from "./merchant_pastUsedPurchaseInfo"
export { default as useSocketLookupShipping } from "./merchant_lookupShipping"
export { default as useSocketRefundRequest } from "./merchant_refundRequest"
export { default as useSocketPaymentDetail } from "./merchant_paymentDetail"
export { default as useSocketGetToPNGList } from "./merchant_getToPNGList"
export { default as useSocketReqToPNGList } from "./merchant_reqToPNGList"
export { default as useSocketGetEnergyDrinks } from "./metadata_GetEnergyDrinks"
export { default as useSocketGetRentalType } from "./metadata_GetRentalType"
export { default as useSocketGetShoesMeta } from "./metadata_GetShoesMeta"
export { default as useSocketGetBanner } from "./metadata_GetBanner"
export { default as useSocketGetNotifications } from "./notification_GetNotifications"
export { default as useSocketNoticeCounter } from "./notification_NoticeCounter"
export { default as useSocketSetRentalNotify } from "./notification_SetRentalNotify"
export { default as useSocketWithDB } from "./ping_withDB"
export { default as useSocketWithoutDB } from "./ping_withoutDB"
export { default as useSocketGetQuest } from "./quest_GetQuest"
export { default as useSocketClaimQuest } from "./quest_ClaimQuest"
export { default as useSocketClaimQuestAll } from "./quest_ClaimQuestAll"
export { default as useSocketGetQuestLog } from "./quest_GetQuestLog"
export { default as useSocketQuestCanclaim } from "./quest_QuestCanclaim"
export { default as useSocketGetQuestInventory } from "./quest_GetQuestInventory"
export { default as useSocketInitRunning } from "./running_InitRunning"
export { default as useSocketCheckRunning } from "./running_CheckRunning"
export { default as useSocketFinishRunning } from "./running_FinishRunning"
export { default as useSocketConnectParty } from "./running_ConnectParty"
export { default as useSocketSynchronizeUserLocation } from "./running_SynchronizeUserLocation"
export { default as useSocketGetPartyUsers } from "./running_GetPartyUsers"
export { default as useSocketGetRandomSpot } from "./running_GetRandomSpot"
export { default as useSocketUpdateRandomSpot } from "./running_UpdateRandomSpot"
export { default as useSocketGetFinishedRunning } from "./running_GetFinishedRunning"
export { default as useSocketGetRentalShoesList } from "./shoes_GetRentalShoesList"
export { default as useSocketSelectShoes } from "./shoes_SelectShoes"
export { default as useSocketReplaceBattery } from "./shoes_ReplaceBattery"
export { default as useSocketRentShoes } from "./shoes_RentShoes"
export { default as useSocketGetRentalHistory } from "./shoes_GetRentalHistory"
export { default as useSocketGetUpgradeInfo } from "./shoes_GetUpgradeInfo"
export { default as useSocketUpgradeShoes } from "./shoes_UpgradeShoes"
export { default as useSocketGetUpgradeHistory } from "./shoes_GetUpgradeHistory"
export { default as useSocketGetSynthesisInfo } from "./shoes_GetSynthesisInfo"
export { default as useSocketSynthesisShoes } from "./shoes_SynthesisShoes"
export { default as useSocketGetSynthesisHistory } from "./shoes_GetSynthesisHistory"
export { default as useSocketGetRentalShoesById } from "./shoes_GetRentalShoesById"
export { default as useSocketTestGetRunningCoords } from "./test_TestGetRunningCoords"
export { default as useSocketUpdateUserData } from "./user_UpdateUserData"
export { default as useSocketRemoveProfileImg } from "./user_RemoveProfileImg"
export { default as useSocketDuplicateNickname } from "./user_DuplicateNickname"
export { default as useSocketRemoveAccount } from "./user_RemoveAccount"
export { default as useSocketGetProfileImges } from "./user_GetProfileImges"
export { default as useSocketLoginRequest } from "./user_LoginRequest"
export { default as useSocketVerifyCode } from "./user_VerifyCode"
export { default as useSocketLoginResponse } from "./user_LoginResponse"
export { default as useSocketHandshake } from "./user_Handshake"
export { default as useSocketFirstBeginHandshake } from "./user_FirstBeginHandshake"
export { default as useSocketHandshakeFCM } from "./user_HandshakeFCM"
export { default as useSocketWitdhraw } from "./wallet_Witdhraw"
export { default as useSocketGetWalletHistory } from "./wallet_GetWalletHistory"
export { request as requestGetActivityChartData } from "./activity_GetActivityChartData"
export { request as requestGetRecentActivity } from "./activity_GetRecentActivity"
export { request as requestSetActivityImage } from "./activity_SetActivityImage"
export { request as requestGetAttendance } from "./event_GetAttendance"
export { request as requestTodayAttendance } from "./event_TodayAttendance"
export { request as requestFindProduct } from "./feed_FindProduct"
export { request as requestWriteFeeds } from "./feed_WriteFeeds"
export { request as requestGetUserFeeds } from "./feed_GetUserFeeds"
export { request as requestGetFeedById } from "./feed_GetFeedById"
export { request as requestFollowUser } from "./feed_FollowUser"
export { request as requestLikeFeed } from "./feed_LikeFeed"
export { request as requestJoinFeed } from "./feed_JoinFeed"
export { request as requestGetShorts } from "./feed_GetShorts"
export { request as requestGetRecruits } from "./feed_GetRecruits"
export { request as requestRemoveFeed } from "./feed_RemoveFeed"
export { request as requestGetInventory } from "./inventory_GetInventory"
export { request as requestOpenRevealBox } from "./inventory_OpenRevealBox"
export { request as requestBuyEnergyDrink } from "./item_BuyEnergyDrink"
export { request as requestBuyEnergyDrinkRunning } from "./item_BuyEnergyDrinkRunning"
export { request as requestGetShopInfo } from "./item_GetShopInfo"
export { request as requestBuyShopItem } from "./item_BuyShopItem"
export { request as requestBuyRevealBox } from "./item_BuyRevealBox"
export { request as requestGetItemMeta } from "./item_GetItemMeta"
export { request as requestOpenItemBox } from "./item_OpenItemBox"
export { request as requestGetLeaderboard } from "./leaderboard_GetLeaderboard"
export { request as requestPolicyHtml } from "./main_PolicyHtml"
export { request as requestServerTime } from "./main_ServerTime"
export { request as requestVersion } from "./main_Version"
export { request as requestDispatchModal } from "./main_DispatchModal"
export { request as requestDebugLogging } from "./main_DebugLogging"
export { request as requestTap } from "./main_Tap"
export { request as requestGetMember } from "./member_GetMember"
export { request as requestAddMember } from "./member_AddMember"
export { request as requestRemoveMember } from "./member_RemoveMember"
export { request as requestGetProductAll } from "./merchant_getProductAll"
export { request as requestGetProductDetail } from "./merchant_getProductDetail"
export { request as requestGetMerchantUid } from "./merchant_GetMerchantUid"
export { request as requestPreparePurchase } from "./merchant_PreparePurchase"
export { request as requestValidImpId } from "./merchant_validImpId"
export { request as requestPastUsedPurchaseInfo } from "./merchant_pastUsedPurchaseInfo"
export { request as requestLookupShipping } from "./merchant_lookupShipping"
export { request as requestRefundRequest } from "./merchant_refundRequest"
export { request as requestPaymentDetail } from "./merchant_paymentDetail"
export { request as requestGetToPNGList } from "./merchant_getToPNGList"
export { request as requestReqToPNGList } from "./merchant_reqToPNGList"
export { request as requestGetEnergyDrinks } from "./metadata_GetEnergyDrinks"
export { request as requestGetRentalType } from "./metadata_GetRentalType"
export { request as requestGetShoesMeta } from "./metadata_GetShoesMeta"
export { request as requestGetBanner } from "./metadata_GetBanner"
export { request as requestGetNotifications } from "./notification_GetNotifications"
export { request as requestNoticeCounter } from "./notification_NoticeCounter"
export { request as requestSetRentalNotify } from "./notification_SetRentalNotify"
export { request as requestWithDB } from "./ping_withDB"
export { request as requestWithoutDB } from "./ping_withoutDB"
export { request as requestGetQuest } from "./quest_GetQuest"
export { request as requestClaimQuest } from "./quest_ClaimQuest"
export { request as requestClaimQuestAll } from "./quest_ClaimQuestAll"
export { request as requestGetQuestLog } from "./quest_GetQuestLog"
export { request as requestQuestCanclaim } from "./quest_QuestCanclaim"
export { request as requestGetQuestInventory } from "./quest_GetQuestInventory"
export { request as requestInitRunning } from "./running_InitRunning"
export { request as requestCheckRunning } from "./running_CheckRunning"
export { request as requestFinishRunning } from "./running_FinishRunning"
export { request as requestConnectParty } from "./running_ConnectParty"
export { request as requestSynchronizeUserLocation } from "./running_SynchronizeUserLocation"
export { request as requestGetPartyUsers } from "./running_GetPartyUsers"
export { request as requestGetRandomSpot } from "./running_GetRandomSpot"
export { request as requestUpdateRandomSpot } from "./running_UpdateRandomSpot"
export { request as requestGetFinishedRunning } from "./running_GetFinishedRunning"
export { request as requestGetRentalShoesList } from "./shoes_GetRentalShoesList"
export { request as requestSelectShoes } from "./shoes_SelectShoes"
export { request as requestReplaceBattery } from "./shoes_ReplaceBattery"
export { request as requestRentShoes } from "./shoes_RentShoes"
export { request as requestGetRentalHistory } from "./shoes_GetRentalHistory"
export { request as requestGetUpgradeInfo } from "./shoes_GetUpgradeInfo"
export { request as requestUpgradeShoes } from "./shoes_UpgradeShoes"
export { request as requestGetUpgradeHistory } from "./shoes_GetUpgradeHistory"
export { request as requestGetSynthesisInfo } from "./shoes_GetSynthesisInfo"
export { request as requestSynthesisShoes } from "./shoes_SynthesisShoes"
export { request as requestGetSynthesisHistory } from "./shoes_GetSynthesisHistory"
export { request as requestGetRentalShoesById } from "./shoes_GetRentalShoesById"
export { request as requestTestGetRunningCoords } from "./test_TestGetRunningCoords"
export { request as requestUpdateUserData } from "./user_UpdateUserData"
export { request as requestRemoveProfileImg } from "./user_RemoveProfileImg"
export { request as requestDuplicateNickname } from "./user_DuplicateNickname"
export { request as requestRemoveAccount } from "./user_RemoveAccount"
export { request as requestGetProfileImges } from "./user_GetProfileImges"
export { request as requestLoginRequest } from "./user_LoginRequest"
export { request as requestVerifyCode } from "./user_VerifyCode"
export { request as requestLoginResponse } from "./user_LoginResponse"
export { request as requestHandshake } from "./user_Handshake"
export { request as requestFirstBeginHandshake } from "./user_FirstBeginHandshake"
export { request as requestHandshakeFCM } from "./user_HandshakeFCM"
export { request as requestWitdhraw } from "./wallet_Witdhraw"
export { request as requestGetWalletHistory } from "./wallet_GetWalletHistory"

const API_HOST = process.env.REACT_APP_API_HOST as string;
export const USER_TOKEN = "user_token";
export const USER_ID = "user_id";
export const IS_EXERCISE = "isexercise"
export const IS_EXERCISE_STOP = "isexercisestop";
export const GID_TOKEN = "gid_token";

export const IS_EXERCISE_RID = "IS_EXERCISE_RID";

export const IS_EXERCISE_ENERGY = "IS_EXERCISE_ENERGY";

export const IS_EXERCISE_MAX_ENERGY = "IS_EXERCISE_MAX_ENERGY";

export const IS_EXERCISE_USER_ENERGY = "IS_EXERCISE_USER_ENERGY";

export const IS_EXERCISE_DISTANCE = "IS_EXERCISE_DISTANCE";

export const IS_EXERCISE_NOW_DISTANCE = "IS_EXERCISE_NOW_DISTANCE";

export const IS_EXERCISE_EARN_TOKEN = "IS_EXERCISE_EARN_TOKEN";

export const IS_EXERCISE_RANDOMSPOT = "IS_EXERCISE_RANDOMSPOT";

export const IS_EXERCISE_RANDOMSPOT_ENTERED = "IS_EXERCISE_RANDOMSPOT_ENTERED";

export const IS_EXERCISE_RANDOMSPOT_ENTER_COUNT = "IS_EXERCISE_RANDOMSPOT_ENTER_COUNT";

export let client = io(API_HOST, { transports: ["websocket"] });

export interface SockClientOpt{
  useStore: boolean;
  useKey?: string;
}

client.on("connect",()=>{
  console.log("connected server")
  setConnect(true, null);

  let token = localStorage.getItem(USER_TOKEN) as string;
  if(token){
      setLogin(token)
  }else{
      setConnect(true, false);
  }
})
client.on("disconnect", (reason) => {
  setConnect(false, null);
})

const setLogin = async function(token:string){
  client.emit("set-token", token, async function(resp:any){
      if(resp.success){
          setConnect(true, true);
      }else{
          setConnect(true, false);
      }
  })
}

const subscribeEvent:Array<(f:SockConnect)=>void> = []
function subscribe(func: (f:SockConnect)=>void) {
  subscribeEvent.push(func)
}
function unsubscribe(func: (f:SockConnect)=>void) {
  let idx = subscribeEvent.findIndex(e => e == func);
  if (idx >= 0) subscribeEvent.splice(idx, 1)
}

let _connect = false;
let _logined : boolean | null = null;
const setConnect = (c:boolean, l:boolean|null)=>{
  _connect = c;
  _logined = l;
  for (let evt of subscribeEvent) {
      evt({ isConnected:_connect, isLogin:_logined });
  }
}

interface SockConnect {
  isConnected:boolean, 
  isLogin:boolean|null
}

export const OnRecvData = async( data:any )=>{
if(data == null) return null;

if(data.code !== 0){
  console.error("error occurred")
}

if(!!data.login_token && _logined == false){
  setLogin(data.login_token); 
  localStorage.setItem(USER_TOKEN, data.login_token) 
}
return data;
}

export const useSocketIsConnect = ()=>{
  let [data,setData] = useState<SockConnect>({
      isConnected:_connect,
      isLogin:_logined,
  });

  useEffect(() => {
      const handleChange = (f:SockConnect)=>setData(f)

      subscribe(handleChange);
      return () => unsubscribe(handleChange);
  }, []);

  return [data]
}

export const Logout = ()=>{
localStorage.clear();
client.disconnect();
setTimeout(function(){
    client.connect();
},1000 * 1)
}
