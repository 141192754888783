import React, { useRef, useEffect, CSSProperties } from "react";

function useOutsideAlerter(ref: React.RefObject<HTMLElement>, outsideClick: () => void) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.children[0].contains(event.target) && !ref.current.contains(event.target)) {
                outsideClick();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

interface OutsideClickAlerterProps {
    outsideClick: () => void;
    enabled: boolean;
    style?: CSSProperties;
}
const OutsideAlerter: React.FC<OutsideClickAlerterProps> = props => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props.outsideClick);

    return (
        <div id="outside-alerter" style={props.style} ref={props.enabled ? wrapperRef : null}>
            {props.children}
        </div>
    );
};

export default OutsideAlerter;
