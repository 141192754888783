import { useEffect, useState, useLayoutEffect, useRef, useCallback } from "react";
import styled from "styled-components";
//
import { gsap, Cubic } from "gsap";

const Section = styled.div<{ isHide: boolean }>`
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    display: ${p => (p.isHide ? "none" : "block")};
    touch-action: none;
    overflow: hidden;
`;

const Wrap = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    padding-bottom: 24px;
    flex-direction: column;
`;

const SplashText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    position: relative;
    z-index: 10;
    img {
        width: 30px;
        height: 67px;
        opacity: 0;
        &:nth-child(2) {
            top: 33px;
            position: relative;
            z-index: 11;
        }
    }
`;

const Description = styled.div`
    margin-top: 62px;
    span {
        ${p => p.theme.typo.h3};
        color: ${p => p.theme.color.textPrimary};
        display: inline-block;
        opacity: 0;
    }
`;

interface SplashProps {
    firstLoading?: boolean;
    time?: number;
    endSplash?: () => void;
}
const Splash = ({ time, firstLoading, endSplash }: SplashProps) => {
    const [isHide, setHide] = useState<boolean>(false);

    //preload
    useLayoutEffect(() => {
        if (firstLoading) {
            setTimeout(async () => {
                setTimeout(
                    () => {
                        setHide(true);
                        if (endSplash) endSplash();
                    },
                    time ? time * 1000 : 2000
                );
            }, 0);
        }
    }, []);

    useEffect(() => {
        if (firstLoading === undefined) {
            setTimeout(
                () => {
                    setHide(true);
                    if (endSplash) endSplash();
                },
                time ? time * 1000 : 2000
            );
        }
    }, [isHide]);

    return (
        <Section isHide={isHide}>
            <Wrap>
            </Wrap>
        </Section>
    );
};

export default Splash;
