import { OnlineStoreNS as NS } from "CommonNamespace";

export interface OSAction {
    type: string;
    item: NS.ItemInfoServerType;
    count: number;
    itemId: number;
    items: NS.PaymentItemType[];
    options: any[];
    idx: number;
}

export const SET_WISHLIST = "ONLINESTORE/WISHLIST/SET";
export const DEL_WISHLIST = "ONLINESTORE/WISHLIST/DEL";
export const SET_PAYMENTLIST = "ONLINESTORE/PAYMENT/SET";
export const MODIFY_WISHLIST = "ONLINESTORE/WISHLIST/MODIFY";

export const SetWishlist = (item: NS.ItemInfoServerType, count: number, options: any[]) => {
    return {
        type: SET_WISHLIST,
        item,
        count,
        options,
    };
};

export const SetCountWishlist = (idx: number, count: number) => {
    return {
        type: MODIFY_WISHLIST,
        idx,
        count,
    };
};

export const DelWishlist = (itemId: number) => {
    return {
        type: DEL_WISHLIST,
        itemId,
    };
};

export const SetPaymentList = (items: NS.PaymentItemType[]) => {
    return {
        type: SET_PAYMENTLIST,
        items,
    };
};
