import React, { useMemo, lazy } from "react";
import { matchPath, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { hasCredential } from "@/utils/credential";
import useEnterBackground from "./hooks/useEnterBackground";
import Market from "@/pages/Market/Market.page";
import Inventory from "@/pages/Inventory/Inventory.page";
// import Activity from "@/pages/Activity/Activity.page";

import RouterMove from "@/components/Common/RouterMove";

// const Market = lazy(() => import("@/pages/Market/Market.page"));
// const Inventory = lazy(() => import("@/pages/Inventory/Inventory.page"));
const Activity = lazy(() => import("@/pages/Activity/Activity.page"));
const FeedDetail = lazy(() => import("@/pages/MyContents/FeedDetail"));;

const Begin = lazy(() => import("@/pages/Begin"));
const TitlePage = lazy(() => import("@/pages/Title"));
const SignUp = lazy(() => import("@/pages/Signup/Signup.page"));
const Home = lazy(() => import("@/pages/Home/Home.page"));
const SelectShoes = lazy(() => import("@/pages/SelectShoes/SelectShoes.page"));
const NotFound = lazy(() => import("@/pages/NotFound"));
const BottomTab = lazy(() => import("./components/Common/BottomTab"));
const Running = lazy(() => import("@/pages/Running/Running.page"));
const RunningQr = lazy(() => import("@/pages/RunningQr/RunningQr.page"));
const ShoesDetail = lazy(() => import("@/pages/ShoesDetail/ShoesDetail.page"));
const RetnalRegist = lazy(() => import("@/pages/RentalRegist/RentalRegist.page"));
const UpgradePage = lazy(() => import("@/pages/Upgrade/Upgrade.page"));
const UpgradePageResult = lazy(() => import("@/pages/Upgrade/UpgradeResult.page"));
const SynthesisPage = lazy(() => import("@/pages/Synthesis/Synthesis.page"));
const SynthesisResult = lazy(() => import("@/pages/SynthesisResult/SynthesisResult"));
const Finish = lazy(() => import("@/pages/RunningFinish/Finish.page"));
const Reveal = lazy(() => import("@/pages/Reveal/Reveal.page"));
const Profile = lazy(() => import("@/pages/Profile/Profile.page"));
const ProfileEditor = lazy(() => import("@/pages/ProfileEditor/ProfileEditor.page"));
const Notifications = lazy(() => import("@/pages/Notifications/Notifications.page"));
const HandlingPolicy = lazy(() => import("@/pages/HandlingPolicy"));
const Stipulation = lazy(() => import("@/pages/Stipulation"));
const AccountRemove = lazy(() => import("@/pages/AccountRemove"));
const ActivityDetail = lazy(() => import("@/pages/ActivityDetail/ActivityDetail.page"));
const Wallet = lazy(() => import("@/pages/Wallet/Wallet.page"));
const WalletHistory = lazy(() => import("./pages/WalletHistory/WalletHistory.page"));
const HomeQr = lazy(() => import("./pages/HomeQr/HomeQr.page"));
const Quest = lazy(() => import("./pages/Quest/Quest.page"));
const Shorts = lazy(() => import("./pages/Shorts/Shorts.page"));
const UserFeeds = lazy(() => import("./pages/MyContents/MyFeeds.page"));
const MyFeed = lazy(() => import("./pages/MyContents/MyFeeds.page"));
const QuestLog = lazy(() => import("./pages/QuestLog/Questlog.page"));
/* Online Store */
const OnlineStore = lazy(() => import("./pages/OnlineStore/OnlineStore.page"));
const OnlineStoreDetail = lazy(() => import("./pages/OnlineStoreDetail/OnlineStoreDetail.page"));
const OnlineStoreDetailToPNG = lazy(() => import("./pages/OnlineStoreDetail/OnlineStoreDetailToPNG.page"));
const OnlineStoreWishlist = lazy(() => import("./pages/OnlineStoreWishlist/OnlineStoreWishlist.page"));
const OnlineStorePayment = lazy(() => import("./pages/OnlineStorePayment/OnlineStorePayment.page"));
const OnlineStorePaymentDetail = lazy(() => import("./pages/OnlineStorePaymentDetail/OnlineStorePaymentDetail.page"));
const OnlineStoreOrderList = lazy(() => import("./pages/OnlineStoreOrderList/OnlineStoreOrderList.page"));
const ToPNGList = lazy(() => import("./pages/OnlineStoreDetail/ToPNGList.page"));

const OnlineStoreBuild = process.env.REACT_APP_ONLINESTORE_BUILD;

String.prototype.withParams = function (params) {
    let result = this;

    for (let key in params) {
        if (!params.hasOwnProperty(key)) continue;

        result = result.replace(":" + key, params[key]);
    }

    return result.toString();
};

const newRouteObject = (path, component, options = {}) => ({
    path,
    component,
    exact: true,
    ...options,
});

const routePublic = (path, component, options) => newRouteObject(path, component, options); // 인증 없이 모든 사람이 접근할 수 있는 페이지 Route 생성
const routePublicOnly = (path, component, options) => newRouteObject(path, component, { publicOnly: true, ...options }); // 인증정보가 없는 사람만 접근 가능한 Route 생성
const routePrivate = (path, component, options) => newRouteObject(path, component, { authRequired: true, ...options }); // 인증이 필요한 페이지 Route 생성

const routes = {};
if (OnlineStoreBuild) {
    routes.Begin = routePublic("/begin", OnlineStore);
    routes.App = routePublic("/app", TitlePage);
    routes.SignIn = routePublic("/signin", OnlineStore);

    routes.OnlineStore = routePublic("/onlinestore", OnlineStore);
    routes.OnlineStoreDetail = routePublic("/onlinestore/detail/:id", OnlineStoreDetail);
    routes.OnlineStoreWishlist = routePublic("/onlinestore/wishlist", OnlineStoreWishlist);
    routes.OnlineStorePayment = routePrivate("/onlinestore/payment", OnlineStorePayment);
    routes.OnlineStoreOrderList = routePrivate("/onlinestore/orderlist", OnlineStoreOrderList);
    routes.OnlineStorePaymentDetail = routePrivate("/onlinestore/payment/detail", OnlineStorePaymentDetail);

    routes.OnlineStoreDetailToPNG = routePublic("/onlinestore/topng/:id", OnlineStoreDetailToPNG);
    routes.ToPNGList = routePublic("/onlinestore/toPNGList", ToPNGList);
} else {
    routes.Begin = routePublic("/begin", Begin);
    routes.App = routePublic("/app", TitlePage);
    routes.SignIn = routePublic("/signin", Begin);
    routes.SignUp = routePublic("/signup", SignUp);
    routes.Home = routePrivate("/home", Home);
    routes.SelectShoes = routePrivate("/selectshoes", SelectShoes);
    routes.Market = routePrivate("/market", Market);
    routes.MarketDetail = routePrivate("/marketdetail/:id", ShoesDetail, { exact: false });
    routes.UpgradePage = routePrivate("/upgrade/:id", UpgradePage);
    routes.UpgradePageResult = routePrivate("/upgraderesult/:id", UpgradePageResult);
    routes.SynthesisPage = routePrivate("/synthesis/:id", SynthesisPage);
    routes.SynthesisResult = routePrivate("/synthesisresult/:id", SynthesisResult);
    routes.Shorts = routePrivate("/shorts", Shorts);
    routes.Running = routePrivate("/running", Running);
    routes.RunningQr = routePrivate("/runningqr", RunningQr);
    routes.MyFeeds = routePrivate("/myFeeds/:id", UserFeeds);
    routes.MyFeed = routePrivate("/myfeed", UserFeeds);
    routes.FeedDetail = routePrivate("/feed/:id", FeedDetail);
    // routes.RunningEnergy = routePrivate("/runningenergy", RunningEnergy);
    routes.RunningShoesDetail = routePrivate("/runningshoesdetail/:id", ShoesDetail);
    routes.RetnalRegist = routePrivate("/rentalregist/:id", RetnalRegist);
    routes.Finish = routePrivate("/finish", Finish);
    routes.Inventory = routePrivate("/inventory", Inventory);
    routes.Reveal = routePrivate("/reveal", Reveal);
    routes.Profile = routePrivate("/profile", Profile);
    routes.ProfileEditor = routePrivate("/profileeditor", ProfileEditor);
    routes.Notifications = routePrivate("/notifications", Notifications);
    routes.HandlingPolicy = routePrivate("/handlingpolicy", HandlingPolicy);
    routes.Stipulation = routePrivate("/stipulation", Stipulation);
    routes.AccountRemove = routePrivate("/accountremove", AccountRemove);
    routes.Activity = routePrivate("/activity", Activity);
    routes.ActivityDetail = routePrivate("/activitydetail", ActivityDetail);
    routes.Wallet = routePrivate("/wallet", Wallet);
    routes.WalletHistory = routePrivate("/wallethistory/:curr", WalletHistory);
    routes.HomeQr = routePrivate("/homeqr", HomeQr);
    routes.Quest = routePrivate("/quest", Quest);
    routes.QuestLog = routePrivate("/questlog", QuestLog);
    routes.OnlineStore = routePrivate("/onlinestore", OnlineStore);
    routes.OnlineStoreDetail = routePrivate("/onlinestore/detail/:id", OnlineStoreDetail);
    routes.OnlineStoreWishlist = routePrivate("/onlinestore/wishlist", OnlineStoreWishlist);
    routes.OnlineStorePayment = routePrivate("/onlinestore/payment", OnlineStorePayment);
    routes.OnlineStorePaymentDetail = routePrivate("/onlinestore/payment/detail", OnlineStorePaymentDetail);
    routes.OnlineStoreOrderList = routePrivate("/onlinestore/orderlist", OnlineStoreOrderList);
}
routes.NotFound = routePublic("/404", NotFound);

export const paths = Object.assign(...Object.keys(routes).map(k => ({ [k]: routes[k].path })));
export const bottomPaths = OnlineStoreBuild
    ? []
    : [
        paths.Home, 
        paths.Market, 
        paths.Inventory, 
        paths.Activity, 
        paths.OnlineStore, 
        paths.Quest, 
        paths.QuestLog,
        paths.Shorts,
        paths.MyFeed
    ];

export const TransitionRouter = ({ authRequired, publicOnly, component: Component, ...p }) => {
    const renderFunc = props => {
        const noSession = !hasCredential();
        // 인증 필요 라우터인데 세션이 저장되어 있지 않으면 로그인 페이지로 리다이렉트
        if (authRequired && noSession) {
            return (
                <Redirect
                    to={{
                        pathname: paths.SignIn,
                        search: "?redirect=" + encodeURIComponent(props.location.pathname),
                        state: { from: props.location },
                    }}
                />
            );
        }

        // 세션이 있을 때 보여지면 안되는 페이지면 인덱스 페이지로 리다이렉트
        if (publicOnly && !noSession)
            return (
                <Redirect
                    to={{
                        pathname: paths.Home,
                        state: { from: props.location },
                    }}
                />
            );
        return (
            <React.Fragment>
                {p.path == paths.Home ||
                p.path == paths.OnlineStore ||
                p.path == paths.Quest ||
                p.path == paths.Activity ? (
                    <></>
                ) : (
                    <Component {...props} />
                )}
                <RouterMove />
            </React.Fragment>
        );
    };

    return <Route {...p} render={renderFunc} />;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [isBackground] = useEnterBackground();
    const isRoute = useMemo(() => {
        const isRun = window.location.pathname.indexOf("running") != -1;
        const isSelectShoes = window.location.pathname.indexOf("selectshoes") != -1;
        const isLogin = window.location.pathname.indexOf("signup") != -1;
        const isWallet = window.location.pathname.indexOf("wallet") != -1;
        const isBegin = window.location.pathname.indexOf("begin") != -1;
        const isPageProcessing = isLogin || isWallet || isSelectShoes || isBegin;
        if (isBackground) {
            if (isRun) {
                return true;
            } else if (isPageProcessing) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }, [isBackground]);

    const location = useLocation();

    const currentRoute = useMemo(
        () =>
            Object.values(routes).find(x => {
                const m = matchPath(location.pathname, { path: x.path });
                return m && m.url === location.pathname;
            }),
        [location.pathname]
    );

    const transitionKey = useMemo(() => {
        return currentRoute && currentRoute.parent ? currentRoute.parent.path : location.pathname;
    }, [currentRoute, location]);

    return (
        <>
            {!OnlineStoreBuild && location.pathname != paths.Begin && (
                <>
                    <Home />
                    <OnlineStore />
                    <Quest />
                    <Activity />
                </>
            )}
            {bottomPaths.indexOf(location.pathname) != -1 ? <BottomTab /> : <></>}
            {isRoute && (
                <TransitionGroup component={null}>
                    <CSSTransition key={transitionKey} in exit timeout={300} classNames="pageSlider">
                        <Switch location={location}>
                            <Route path="/" exact>
                                <Redirect to={paths.Begin} />
                            </Route>
                            {Object.values(routes)
                                .filter(x => x.component)
                                .map((x, i) => (
                                    <TransitionRouter
                                        key={i}
                                        path={x.path}
                                        component={x.component}
                                        exact={x.exact}
                                        authRequired={x.authRequired}
                                        publicOnly={x.publicOnly}
                                    />
                                ))}
                            <Redirect to={{ pathname: paths.NotFound }} />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            )}
        </>
    );
};
