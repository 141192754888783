import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useSocketIsConnect } from "../api";

const Section = styled.div``;
const Wrap = styled.div``;
const NoConnected = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99999;
`;

const Wifi = styled.div`
    width: 200px;
    height: 200px;
    position: relative;
    .preloader-2 {
        margin: 120px auto 0;
        height: 40px;
    }

    div {
        color: #fff;
        margin: 5px 0;
        text-transform: uppercase;
        text-align: center;
        font-family: 'Arial', sans-serif;
        font-size: 10px;
        letter-spacing: 2px;
    }

    .preloader-2 .line {
        width: 1px;
        height: 15px;
        background: #fff;
        margin: 0 2px;
        display: inline-block;
        animation: opacity-2 1000ms infinite ease-in-out;
    }

    .preloader-2 .line-1 { animation-delay: 800ms; }
    .preloader-2 .line-2 { animation-delay: 600ms; }
    .preloader-2 .line-3 { animation-delay: 400ms; }
    .preloader-2 .line-4 { animation-delay: 200ms; }
    .preloader-2 .line-6 { animation-delay: 200ms; }
    .preloader-2 .line-7 { animation-delay: 400ms; }
    .preloader-2 .line-8 { animation-delay: 600ms; }
    .preloader-2 .line-9 { animation-delay: 800ms; }

    @keyframes opacity-2 { 
        0% { 
            opacity: 1;
            height: 15px;
        }
        50% { 
            opacity: 0;
            height: 9px;
        }
        100% { 
            opacity: 1;
            height: 15px;
        }  
    }
`;

const NetworkChecker = () => {
    let [conn] = useSocketIsConnect();
    let [disconnectedTime, setDisconnectedTime] = useState(0);

    useEffect(() => {
        if (conn.isConnected == false) {
            setDisconnectedTime(Date.now());
        }
    }, [conn]);

    return (
        <Section>
            {conn.isConnected == false ? (
                <NoConnected>
                    <Wifi>
                        <div className="preloader-2">
                            <span className="line line-1"></span>
                            <span className="line line-2"></span>
                            <span className="line line-3"></span>
                            <span className="line line-4"></span>
                            <span className="line line-5"></span>
                            <span className="line line-6"></span>
                            <span className="line line-7"></span>
                            <span className="line line-8"></span>
                            <span className="line line-9"></span>
                            <div>Connecting...</div>
                        </div>
                    </Wifi>
                </NoConnected>
            ) : null} 
        </Section>
    );
};

export default NetworkChecker;
