import React from "react";
import crypto, { BinaryToTextEncoding } from "crypto";
//
import { checkPhoneNumber } from "./regexp";
import web2app from "./web2app";

export const CONFIG_LANG = "lang";

export const preventEvent = (event: UIEvent) => {
    if (!event) {
        return;
    }

    event.preventDefault && event.preventDefault();
    event.stopImmediatePropagation && event.stopImmediatePropagation();
    event.stopPropagation && event.stopPropagation();
};

const parseFormOptions = {
    asBoolean: false,
    fileMultiple: false,
};

// TODO: form type
export const parseForm = (form: any, options = parseFormOptions) => {
    if (!form) {
        return;
    }

    const params: any = {};
    for (let target of form) {
        if (!target.name) {
            continue;
        }

        if (target.type === "checkbox") {
            params[target.name] = options.asBoolean ? target.checked : target.checked ? 1 : 0;
        } else if (target.type === "radio") {
            params[target.name] = target.checked ? target.value : params[target.name];
        } else if (target.type === "file") {
            params[target.name] = options.fileMultiple ? target.files : target.files[0];
        } else {
            params[target.name] = target.value;
        }
    }

    return params;
};

export const md5 = (str: string, enc: BinaryToTextEncoding = "base64") => {
    return crypto.createHash("md5").update(str).digest(enc);
};

export const sha256 = (str: string, enc: BinaryToTextEncoding = "base64") => {
    return crypto.createHash("sha256").update(str).digest(enc);
};

export const convertFileSize = (bytes: number) => {
    if (isNull(bytes)) {
        return "-";
    }

    const sizes = ["Byte", "KB", "MB", "GB", "TB"];
    if (bytes === 0) {
        return "0 KB";
    }

    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + sizes[+i];
};

export const isPromise = (promise: Promise<any>) => {
    return Promise.resolve(promise) === promise;
};

export const promise = (promise: Promise<any>) => {
    if (isPromise(promise)) {
        return promise;
    }

    return new Promise(() => {});
};

export const timestamp = (ms: number) => {
    if (!ms && ms !== 0) {
        ms = Date.now();
    }

    return Math.floor(ms) / 1000;
};

export const getDefaultLanguage = () => {
    const savedLang = localStorage.getItem(CONFIG_LANG);

    if (savedLang) {
        return savedLang;
    }

    const lang = navigator.language;

    if (!lang) {
        return;
    }

    return lang.split("-")[0];
};

export const arrayFlat = (arr: [], d = 1): any => {
    return d > 0
        ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? arrayFlat(val, d - 1) : val), [])
        : arr.slice();
};

export function arrayMove(arr: [], from: number, to: number) {
    if (to >= arr.length) {
        var k = to - arr.length + 1;
        while (k--) {
            arr.push();
        }
    }
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
}

export function isNull(val: any) {
    return typeof val === "undefined" || val === null;
}

export function isBoolean(val: any) {
    return !isNull(val) && (val.toString() === "true" || val.toString() === "false");
}

export const randomUniqueThree = (min: number, max: number) => {
    let nums: number[] = [];
    while (nums.length < 3) {
        var n = Math.floor(Math.random() * (max - min) + min);
        if (nums.find(raw => raw === n)) {
            continue;
        }
        nums.push(n);
    }
    return nums;
};

export const randomId = (s: string) => {
    return md5(Math.random() + Date.now() + s, "hex");
};

export const isIterable = (obj: any) => {
    if (obj === null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === "function";
};

export const toDropdownItem = (value: string | number, label: string) => {
    if (!label) {
        label = value as string;
    }

    return { value, label };
};

export const removeSpaces = (text: string) => {
    if (!text) {
        return "";
    }

    return text.replace(/\s/g, "");
};

export const filterPhoneNumber = (str: string) => {
    if (!str) {
        return;
    }

    str = str.replace(/[\s+-]/g, "");

    if (checkPhoneNumber(str)) {
        return str;
    }
};

export const file2bin = (file: File) => {
    return new Promise<Uint8Array>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => resolve(new Uint8Array(reader.result as ArrayBuffer));
        reader.onerror = e => reject(e);
    });
};

export const file2url = (file: File) => {
    return new Promise<any>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = e => reject(e);
    });
};

export const url2file = (dataURL: string, fileName: string) => {
    const arr = dataURL.split(",");
    const bstr = atob(arr[1]);
    const match = arr[0].match(/:(.*?);/);
    const mime = match ? match[1] : "";

    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
};

export const breakLine = (str?: string | null) => {
    if (!str) {
        return null;
    }

    return str.split(/\n|\\n|<br\/>/g).map((line, i) => (
        <React.Fragment key={i}>
            {line}
            <br />
        </React.Fragment>
    ));
};

export const allowTouchMove = (el: HTMLElement | Element): boolean => {
    while (el && el !== document.body) {
        if (el.getAttribute("data-ignore-lock") !== null) {
            return true;
        }

        if (!el.parentElement) {
            break;
        }

        el = el.parentElement;
    }

    return false;
};

export const openKlip = (requestUrl: string, isIos?: boolean) => {
    return web2app({
        urlScheme: "kakaotalk://klipwallet/open?url=" + requestUrl, // iOS
        intentURI: "intent://klipwallet/open?url=" + requestUrl + "#Intent;scheme=kakaotalk;package=com.kakao.talk;end", // Android
        appName: "Klip Wallet",
        storeURL: isIos ? "itms-apps://itunes.apple.com/app/id362057947" : "market://details?id=com.kakao.talk",
    });
};

export const isEqualString = (a: any | undefined, b: any | undefined): boolean => {
    return typeof a === "undefined" || typeof b === "undefined" || a.toString() === b.toString();
};

export const brightnessByHex6 = (hex: string): number => {
    if (!hex || !hex.startsWith("#") || hex.replace("#", "").length !== 6) {
        return 0;
    }

    const m = hex.substr(1).match(/(\S{2})/g);

    if (!m) {
        return 0;
    }

    const r = parseInt(m[0], 16);
    const g = parseInt(m[1], 16);
    const b = parseInt(m[2], 16);

    return (r * 299 + g * 587 + b * 114) / 1000;
};

export const lightenColor = (hex: string, percent: number = 10): string => {
    const num = parseInt(hex.replace("#", ""), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        B = ((num >> 8) & 0x00ff) + amt,
        G = (num & 0x0000ff) + amt;

    return (
        "#" +
        (
            0x1000000 +
            (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
            (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
            (G < 255 ? (G < 1 ? 0 : G) : 255)
        )
            .toString(16)
            .slice(1)
    );
};

export const getLeftTime = (from: Date, to: Date) => {
    var dateFuture = to;
    var dateNow = from;

    var seconds = Math.floor((dateFuture.getTime() - dateNow.getTime()) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    var totalSeconds = seconds;

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

    return {
        days: days,
        hour: hours,
        minutes: minutes,
        seconds: seconds,
        totalSeconds: totalSeconds,
    };
};

export const getTwoDigits = (num: number) => ("0" + num).slice(-2);

export const numCommas = (num: number | string) => {
    if (typeof num == "number") {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
};

export const sliceTextByCommas = (text: string, start: number, end: number) => {
    if (text.length > end) {
        return text.slice(0, start) + "..." + text.slice(text.length - end, text.length);
    }
    return text;
};
