import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
//
import { ShoesType, RevealBoxType, instanceOfShoesType, instanceOfReveal } from "@/dataset";
import { pxtovw375, zIndex } from "@/utils/css";
import ShoesItem from "@/components/Common/ShoesItem";
import RevealItem from "@/components/Common/RevealItem";

export const CloseBtn = styled.div`
    position: absolute;
    top: 0;
    right: -${pxtovw375(10)};
    padding: ${pxtovw375(10)};
    img {
        width: ${pxtovw375(24)};
        height: ${pxtovw375(24)};
    }
`;

export const TitleBx = styled.div`
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    padding: ${pxtovw375(30)} 0;
    z-index: ${zIndex.sticky};
    background: #181818;
`;

export const Title = styled.div`
    font-weight: 600;
    font-size: 22px;
    color: #fff;
`;

export const TitleBxToolWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: ${pxtovw375(20)} 0 0 0;
`;

export const TitleBxCounter = styled.div`
    font-size: ${pxtovw375(12)};
    font-weight: 400;
    line-height: 20px;
`;

export const ItemListWrap = styled.div`
    min-height: auto;
`;

export const ItemListContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: ${pxtovw375(12)};
    row-gap: ${pxtovw375(20)};

    padding-bottom: 0;
`;

interface InvenListProps {
    list: (ShoesType | RevealBoxType)[];
    selectedIds: number[];
    onChangeIds?: (ids: number[]) => void;
}

const InvenList: React.FC<InvenListProps> = ({ list, selectedIds, onChangeIds }) => {
    const [selected, setSelected] = useState<number[]>([]);

    useEffect(() => {
        if (selectedIds) {
            setSelected(selectedIds);
        }
    }, [selectedIds]);

    const onSelectId = useCallback(
        (id: number) => {
            if (onChangeIds) {
                let idx = selected.indexOf(id);
                if (idx != -1) {
                    selected.splice(idx, 1);
                } else {
                    selected.push(id);
                }
                setSelected([...selected]);
                onChangeIds(selected);
            }
        },
        [selected, onChangeIds]
    );

    return (
        <ItemListWrap>
            <ItemListContainer>
                {list.map(($item, $idx) => {
                    const fIdx = selected.indexOf($item.id);
                    if (instanceOfShoesType($item)) {
                        return (
                            <ShoesItem
                                selected={fIdx != -1}
                                key={"shoes" + $item.id}
                                url={$item.url}
                                info={$item}
                                onClick={onChangeIds ? id => onSelectId(id) : undefined}
                            />
                        );
                    } else if (instanceOfReveal($item)) {
                        return (
                            <RevealItem
                                key={"reveal" + $item.id}
                                info={$item}
                                selected={fIdx != -1}
                                onClick={onChangeIds ? id => onSelectId(id) : undefined}
                            />
                        );
                    } else {
                        return <></>;
                    }
                })}
            </ItemListContainer>
        </ItemListWrap>
    );
};

export default InvenList;
